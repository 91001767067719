import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "person__create form_person " }
const _hoisted_2 = { class: "person__wrapper" }
const _hoisted_3 = { class: "person__select" }
const _hoisted_4 = { class: "person__select" }
const _hoisted_5 = { class: "person__select" }
const _hoisted_6 = { class: "person__select" }
const _hoisted_7 = { class: "person__select" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_common_selector = _resolveComponent("common-selector")
  const _component_base_input = _resolveComponent("base-input")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_datepicker = _resolveComponent("datepicker")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_common_selector, {
          type: _ctx.cityType,
          placeholder: "Город",
          onItemSet: _cache[1] || (_cache[1] = $event => (_ctx.setData($event)))
        }, null, 8, ["type"])
      ]),
      _createVNode("button", {
        onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.deleteRoute(...args))),
        class: "btn-close mt-8 ml-8"
      })
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode(_component_base_input, {
        type: "text",
        name: "address",
        value: _ctx.mainData.address,
        onInput: _cache[3] || (_cache[3] = $event => (_ctx.simpleSetData($event, 'address'))),
        placeholder: "Адрес"
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_base_input, {
        type: "text",
        name: "transportNum",
        value: _ctx.mainData.transportNum,
        onInput: _cache[4] || (_cache[4] = $event => (_ctx.simpleSetData($event, 'transportNum'))),
        placeholder: "Номер рейса / поезда"
      }, null, 8, ["value"])
    ]),
    _createVNode("div", _hoisted_6, [
      _createVNode(_component_Calendar, {
        modelValue: _ctx.mainData.time,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (_ctx.mainData.time = $event)),
        showTime: true,
        timeOnly: true,
        placeholder: 'Время'
      }, null, 8, ["modelValue"])
    ]),
    _createVNode("div", _hoisted_7, [
      _createVNode(_component_datepicker, {
        placeholder: "Дата",
        "monday-first": true,
        modelValue: _ctx.mainData.date,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (_ctx.mainData.date = $event)),
        "wrapper-class": "vuejs3-datepicker__wrapper"
      }, null, 8, ["modelValue"])
    ])
  ]))
}