
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import SubHeader from "../components/shared/SubHeader.vue";
import { useStore } from "../store/modules";

import { ActionTypes as TripActionTypes } from "../store/modules/trips/contract";
import { ActionTypes as OrderActionTypes } from "../store/modules/order/contract";

import { MutationTypes } from "../store/modules/trips/MutationsType";
import {
  OfferTicketType,
  OrderDTOType,
  TripWithOrders
} from "../interfaceAndType/typesTrips";
import LeftFilterTypeOffers from "../components/trips/openTripPage/LeftFilterTypeOffers.vue";

export default defineComponent({
  components: { SubHeader, LeftFilterTypeOffers },
  setup() {
    const store = useStore();
    const isLoadData = ref(false)
    store.commit(MutationTypes.CLEAR_ACTIVE_ORDER);
    const activeOrderIsLoad = computed(
      () => store.state.trips.activeOrderIsLoad
    );

    const route = useRoute();
    const id = parseInt(
      route.params && route.params.id ? (route.params.id as string) : "0"
    );

    // TODO вынести это в сторе  !!!!! перепиши код !!!!!!!!
    const listOrders = ref<TripWithOrders>();
    const orderFull = ref<Array<OrderDTOType | any>>([]);
    const getFullOrder = async () => {
      const orders: Array<OrderDTOType> = [];
      if (listOrders.value) {
        for (const order of listOrders.value.OrderList!) {
          const orderId = order.orderId;
          const cachOrder = store.getters.getOrderDataFromCach(orderId);
          let res: OrderDTOType | null = null;
          res = await store.dispatch(OrderActionTypes.GET_ORDER_OFFERS, {
            orderId: orderId
          });
          if (res) {
            orders.push(res);
          }
        }
      }
      return orders;
    };
    const initData = async () => {
      isLoadData.value = false
      const res = await store.dispatch(TripActionTypes.GET_TRIP_ORDERS, {
        tripId: id
      });
      listOrders.value = res;
      orderFull.value = await getFullOrder();
      isLoadData.value = true
      store.commit(MutationTypes.SET_ACTIVE_ORDER, { data: orderFull.value });
    };
    initData();
    const title = computed(() => {
      return `Командировка № ${
        listOrders.value ? listOrders.value.businessTripId : ""
      }`;
    });
    const subText = computed(() => {
      return `Название: ${listOrders.value ? listOrders.value.name : ""}`;
    });
    return {
      listOrders,
      orderFull,
      activeOrderIsLoad,
      title,
      subText,
      initData,
      isLoadData
    };
  }
});
