import { Module } from "vuex";
import { State as RootState } from "../index";
import { State } from "./contract";

import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

const loadData2 = localStorage.getItem("tempdatatrains");
const initDemoData2 = loadData2 ? JSON.parse(loadData2) : null;
const searhcTemp = localStorage.getItem("tempdatasearchrail");
const searhcQTemp = searhcTemp ? JSON.parse(searhcTemp) : null;

const state: State = {
  searchReq: searhcQTemp,
  trains: initDemoData2 ? initDemoData2 : [],
  pricingCache: {},
  trainWithSeats: {},
  passengersCount: 1,
};

export const RailModule: Module<State, RootState> = {
  state,
  mutations,
  actions,
  getters
};
