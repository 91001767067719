import { ServicesListElement } from "./interfaceAndType/typesServices";

export const MAIN_HOST = process.env.VUE_APP_MAIN_HOST;

export const NAME_TOKEN = "main-token-arm";

export const API_METHODS = {
  ACCOUNT_LOGIN: "ACCOUNT_LOGIN",
  ACCOUNT_LOGOUT: "ACCOUNT_LOGOUT",
  ACCOUNT_PASSWORD_RESET: "ACCOUNT_PASSWORD_RESET",
  ACCOUNT_PASSWORD_CONFIRM: "ACCOUNT_PASSWORD_CONFIRM",
  ACCOUNT_ME: "ACCOUNT_ME",

  // AVIA ----------------------------------------
  SEARHC_AVIA: "SEARHC_AVIA",
  SEARCH_AVIA_PRICING: "SEARCH_AVIA_PRICING",
  // AVIA END

  GET_DICTIONARI: "GET_DICTIONARI",
};

export const TYPE_ERRORS = Object.freeze({
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "danger",
  DEF_TYPE: "",
});
export const TYPE_DIALOG_TITLE = Object.freeze({
  INFO: "Информация",
  DANGER: "Ошибка",
  SUCCESS: "Успешно!",
  WARNING: "Внимание",
  DOC_SAVE: "Документы сохранены.",
  WARNING_SMALL: "ВАЖНО",
});

export const DICTIONARY_LIST = Object.freeze({
  AIRPORT: "Airport",
  FARE: "Fare",
});

export const STATUS_AUTH = Object.freeze({
  wait: "wait",
  success: "success",
  erorr: "error",
  noLogin: "noLogin",
});

export const FLIGHT_HOURS_ARRAY = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
// не менять очень важный обьект !!
export const ORDER_TYPES = Object.freeze({
  AVIA: "Avia",
  ALL: "All",
  RAILWAY: "Railway",
  HOTEL: "Hotel",
  FREE: "Free",
  TRANSFER: "Transfer",
});

export const DEFAULT_PERPAGE = 10;

export const TRIP_SORTING_FIELDS = Object.freeze({
  ID: "businessTripId",
  CLIENT: "clientName",
  AGENT: "agentName",
  DATE: "dateCreated",
  STATUS: "statusCode",
  NAME: "name",
});

export const TRANSFER_CODE = "TRANSFER_CODE"; // трансфер услуга
export const FREE_CODE = "FREE_CODE"; // свободная услуга
export const SERVICES_TYPES: Array<ServicesListElement> = [
  { id: 1, name: "Трансфер", code: TRANSFER_CODE },
  { id: 2, name: "Свободная услуга", code: FREE_CODE },
];

/**
 * Возможные типы поиска вып списков
 */
export enum TYPE_DROPLIST {
  PERSON = "person",
  DOCUMENT = "document",
  BONUS_CARD = "bonuscard",
  CONTAC_EMAIL = "contactemail",
  CONTACT_MOBILE = "contactmobile",
  PAYMENT_TYPE = "paymenttype",
  CURRENCY = "currency",
  SUPPLIER = "supplier",
  CARCLASS = "carclass",
  CONTRACT = "contract",
  CITY = "city",
  CLIENT = "client",
}

export const MAIN_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss"; //не изменить !!!!!!!!!!!!!!

export const STATUS_ORDER = Object.freeze({
  Denied: "Denied",
  Booking: "Booking",
  Created: "Created",
  Canceled: "Canceled",
  TicketReady: "TicketReady",
  InWork: "InWork",
  Applied: "Applied"
});
/**
 * Доступные кнопки
 */
export const CONTROLL_BTN = {
  BTN_CANSEL: "BTN_CANSEL",
  BTN_BOOKING: "BTN_BOOKING",
  BTN_TIKENTING: "BTN_TIKENTING",
  BTN_REFAUND: "BTN_REFAUND",
};
/**
 * отображение кнопок
 */
export const STATUS_CONTROL_MODEL = Object.freeze({
  [ORDER_TYPES.AVIA]: {
    [STATUS_ORDER.Created]: [CONTROLL_BTN.BTN_BOOKING],
    [STATUS_ORDER.InWork]: [CONTROLL_BTN.BTN_BOOKING],
    [STATUS_ORDER.Booking]: [
      CONTROLL_BTN.BTN_CANSEL,
      CONTROLL_BTN.BTN_TIKENTING,
    ],
    [STATUS_ORDER.Denied]: [],
    [STATUS_ORDER.Canceled]: [],
    [STATUS_ORDER.TicketReady]: [],
  },

  [ORDER_TYPES.HOTEL]: {
    [STATUS_ORDER.Created]: [CONTROLL_BTN.BTN_BOOKING],
    [STATUS_ORDER.Booking]: [
      CONTROLL_BTN.BTN_CANSEL,
      CONTROLL_BTN.BTN_TIKENTING,
    ],
    [STATUS_ORDER.InWork]: [CONTROLL_BTN.BTN_BOOKING],
    [STATUS_ORDER.Denied]: [],
    [STATUS_ORDER.Canceled]: [],
    [STATUS_ORDER.TicketReady]: [],
  },
  [ORDER_TYPES.TRANSFER]: {
    [STATUS_ORDER.Created]: [CONTROLL_BTN.BTN_BOOKING],
    [STATUS_ORDER.InWork]: [CONTROLL_BTN.BTN_BOOKING],
  },
  [ORDER_TYPES.FREE]: {
    [STATUS_ORDER.Created]: [CONTROLL_BTN.BTN_BOOKING],
    [STATUS_ORDER.InWork]: [CONTROLL_BTN.BTN_BOOKING],
  },
  [ORDER_TYPES.RAILWAY]: {
    [STATUS_ORDER.Created]: [CONTROLL_BTN.BTN_BOOKING],
    [STATUS_ORDER.Booking]: [
      CONTROLL_BTN.BTN_CANSEL,
      CONTROLL_BTN.BTN_TIKENTING,
    ],
    [STATUS_ORDER.InWork]: [CONTROLL_BTN.BTN_BOOKING],
    [STATUS_ORDER.Denied]: [],
    [STATUS_ORDER.Canceled]: [],
    [STATUS_ORDER.TicketReady]: [CONTROLL_BTN.BTN_REFAUND],
  },
});
/**
 * упавление кнопками контроля
 * @param orderTypeCode
 * @param statusCode // статус всего заказа
 * @param btnCode
 * @returns
 */
export const canShowBnt = (orderTypeCode, statusCode, btnCode) => {
  if (
    STATUS_CONTROL_MODEL[orderTypeCode] &&
    STATUS_CONTROL_MODEL[orderTypeCode][statusCode]
  ) {
    if (
      STATUS_CONTROL_MODEL[orderTypeCode][statusCode].find(
        (code) => code == btnCode
      )
    ) {
      return true;
    }
  }
  return false;
};

export const LAST_TIME_CHECK = "LAST_TIME_CHECK";
export const EXP_TIME_TOKEN = "EXP_TIME_TOKEN";
export const MAX_TIME_LIVE_TOKEN = 60; //60 минут
