
import { computed, defineComponent, PropType } from "@vue/runtime-core";
import { useStore } from "vuex";
import { MultiItinerary } from "../../../interfaceAndType/typesSearh";

export default defineComponent({
  name: "AviaFareInfo",
  props: {
    item: {
      type: Object as PropType<MultiItinerary>,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup(props) {
    const store = useStore();
    const selectedItem = computed(() => {
      const selected = store.state.avia.selectedAviaTicket.find(
        ticket => ticket == props.item.key
      );
      const updatedItem = store.state.avia.searhcData.variants.find(
        variant => variant.key == selected
      );
      return updatedItem;
    });
    return { selectedItem };
  }
});
