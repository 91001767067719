<template>
  <footer class="commonFooter">
    <ul class="listPartrners">
      <li><img src="/ico/partnersIcon/masterCard.svg" alt="Master Card" /></li>
      <li><img src="/ico/partnersIcon/visa.svg" alt="Master Card" /></li>
      <li><img src="/ico/partnersIcon/mir.svg" alt="Master Card" /></li>
      <li><img src="/ico/partnersIcon/iata.svg" alt="Master Card" /></li>
      <li><img src="/ico/partnersIcon/jd.svg" alt="Master Card" /></li>
      <li><img src="/ico/partnersIcon/trip.svg" alt="Master Card" /></li>
      <li><img src="/ico/partnersIcon/dss.svg" alt="Master Card" /></li>
      <li><img src="/ico/partnersIcon/https.svg" alt="Master Card" /></li>
    </ul>

    <div class="socialBlock">
      <ul class="socialLink">
        <li>
          <a href="#"> <img src="/ico/socialIcon/f.svg" alt="Master Card"/></a>
        </li>
        <li>
          <a href="#"> <img src="/ico/socialIcon/tw.svg" alt="Master Card"/></a>
        </li>
        <li>
          <a href="#">
            <img src="/ico/socialIcon/rss.svg" alt="Master Card"
          /></a>
        </li>
        <li>
          <a href="#"> <img src="/ico/socialIcon/g.svg" alt="Master Card"/></a>
        </li>
        <li>
          <a href="#">
            <img src="/ico/socialIcon/inst.svg" alt="Master Card"
          /></a>
        </li>
      </ul>
      <div class="langblock">
        RU
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
