import { StringTypedResponse } from "../typeSystem/api/response";
export class ApiAuth {
  _http: any;
  constructor(_http) {
    this._http = _http;
  }
  errRes: StringTypedResponse = {
    errors: ["Ошибка сервера или нет соединения!"],
    isWarning: true,
    isValid: false,
    warnings: ["Ошибка сервера или нет соединения!"],
    countPage: 0,
    object: {}
  };
  /**
   * Тестовый метод для проверки отклика от сервера.
   * @returns
   */
  async pingServer(): Promise<boolean> {
    const urlReq = `/api/auth/health`;
    try {
      const res = await this._http.get(urlReq);
      return !!res;
    } catch (err) {
      return false;
    }
  }
  /**
   * Получить новый токен
   * @returns 
   */
  async refreshToken(): Promise<StringTypedResponse> {
    const urlReq = `/api/auth/refresh`;
    try {
      const res = await this._http.get(urlReq);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }


}
