import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "railRoute__info railRoute__info--small" }
const _hoisted_2 = { class: "railRoute__info__type railRoute__info__type--small" }
const _hoisted_3 = { class: "railRoute__info__type railRoute__info__type--small" }
const _hoisted_4 = { class: "railRoute__info__type railRoute__info__type--small" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("ul", null, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.item.ckTrainListReferences, (carriage) => {
        return (_openBlock(), _createBlock("li", {
          key: carriage.categoryCarriage
        }, [
          _createVNode("div", _hoisted_2, " Тип вагона: " + _toDisplayString(carriage.categoryCarriagePrinted
              ? carriage.categoryCarriagePrinted
              : carriage.categoryCarriage), 1),
          _createVNode("div", _hoisted_3, " Номер вагона: " + _toDisplayString(carriage.cvTrainListReference.number
              ? carriage.cvTrainListReference.number
              : 'N/A'), 1),
          _createVNode("div", _hoisted_4, " Номера мест: " + _toDisplayString(carriage.cvTrainListReference.selected
              ? carriage.cvTrainListReference.selected
              : 'N/A'), 1)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}