
import { computed, defineComponent, reactive, ref } from "@vue/runtime-core";
import Datepicker from "vuejs3-datepicker";
import { useStore } from "../../../store/modules";
import RailSearchInput from "./searchForm/Rail/RailSearchInput.vue";
import { RailwaySearchRequest } from "../../../interfaceAndType/typesRail";
import { DropDownEntity } from "../../../interfaceAndType/typesShared";
import moment from "moment";
import clearReactive from "../../../helpers/clearReactive";
import { ActionTypes } from "../../../store/modules/rail/contract";
import { useRouter } from "vue-router";
import { path } from "../../../router/path";
import { MutationTypes } from "../../../store/modules/rail/MutationsType";
export default defineComponent({
  name: "RailSearchForm",
  components: {
    Datepicker,
    RailSearchInput
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const searchRequest = reactive<RailwaySearchRequest>({
      isTrainWithSeat: true,
      isJoinTrainComplex: false,
      grouppingType: 0,
      isJoinTrains: false,
      searchOption: 0,
      migrationList: [
        {
          origin: null,
          destination: null,
          startHour: null,
          endHour: null,
          timeSw: 0,
          date: ""
        }
      ],
      clientId: null,
      passengers: null,
      contractId: null
    });
    const setData = (name: string, val: string | null) => {
      if (searchRequest.migrationList) {
        searchRequest.migrationList[0][name] = val;
      }
    };
    const selectFrom = (val: null | DropDownEntity) => {
      setData("origin", val ? val.entityCode : null);
    };
    const selectTo = (val: null | DropDownEntity) => {
      setData("destination", val ? val.entityCode : null);
    };
    const setDateFrom = (val: string) => {
      setData("date", val ? val : "");
    };
    const disabledDatesFrom = {
      to: new Date(),
      preventDisableDateSelection: true
    };
    const searchData = async () => {
      const preventData: RailwaySearchRequest = clearReactive(searchRequest);
      if (preventData.migrationList) {
        preventData.migrationList = preventData.migrationList.map(list => {
          return {
            ...list,
            date: moment(list.date).format("YYYY-MM-DD")
          };
        });
        const res = await store.dispatch(ActionTypes.SEARCH_RAIL, {
          searchData: preventData
        });
        if (res) {
          router.push(path.railSearchResult);
        }
      }
    };

    const disabledSearch = computed(() => {
      if(searchRequest.migrationList) {
        return searchRequest.migrationList.every(el => {
          return (!!el.origin && !!el.destination && !!el.date);
        });
      }
    });

    const passengersCount = computed(() => {
      return store.getters.getPassengersCount.adults;
    });

    const increaseCount = () => {
      store.commit(MutationTypes.INCREASE_COUNT);
    };

    const decreaseCount = () => {
      store.commit(MutationTypes.DECREASE_COUNT);
    };
    return {
      store,
      searchRequest,
      setData,
      selectFrom,
      selectTo,
      setDateFrom,
      disabledDatesFrom,
      searchData,
      disabledSearch,
      passengersCount,
      increaseCount,
      decreaseCount
    };
  }
});
