<template>
  <div>
    <datepicker></datepicker>
    <avia-tarif></avia-tarif>
    <rail-seats-modal></rail-seats-modal>
  </div>
</template>

<script>
import Datepicker from "vuejs3-datepicker";
import AviaTarif from "../components/modals/AviaTarif";
import RailSeatsModal from "../components/modals/RailSeatsModal";
export default {
  components: {
    Datepicker,
    AviaTarif,
    RailSeatsModal
  }
};
</script>
