
import { defineComponent, onMounted } from "@vue/runtime-core";
import RailSearchForm from "../components/components/search/RailSearchForm.vue";
import { useStore } from "../store/modules";
import { MutationTypes } from "../store/modules/rail/MutationsType";
export default defineComponent({
  name: "TrainSearch",
  components: {
    RailSearchForm
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.commit(MutationTypes.CLEAR_CACHE);
    })
    return {}
  }
});
