
import { defineComponent, PropType } from "@vue/runtime-core";
import { FreeOffer } from '../../../../interfaceAndType/typesServices';
import FreeServiceDate from "./FreeServiceDate.vue";
import FreeServiceInfoblock from "./FreeServiceInfoblock.vue";

export default defineComponent({
  components: {FreeServiceDate, FreeServiceInfoblock},
  name: "FreeServiceMain",
  component: {},
  props: {
    item: {
      type: Object as PropType<FreeOffer>
    },
  },
  setup(props) {
    return {}
  }
});
