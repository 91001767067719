import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "onetrip-page__content" }
const _hoisted_2 = { class: "onetrip-page__list" }
const _hoisted_3 = { class: "orderOpen" }
const _hoisted_4 = { class: "listOrder" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("ul", _hoisted_4, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.convertListOffer, (item) => {
            return (_openBlock(), _createBlock("li", {
              class: { isActiveList: _ctx.isActiveOffer(item.orderId) },
              key: item.orderId,
              onClick: $event => (_ctx.setActiveOffer(item.orderId))
            }, _toDisplayString(item.isOffer ? "Предложение" : "Заказ") + " №" + _toDisplayString(item.orderId), 11, ["onClick"]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _createVNode(_component_router_view, {
        key: _ctx.$route.path,
        listOrders: _ctx.listOrders,
        initData: _ctx.initData
      }, null, 8, ["listOrders", "initData"])
    ])
  ]))
}