import { GetterTree } from "vuex";

import { State as RootState } from "../index";
import { State, Getters } from "./contract";
import store from "../index";

// getters
export const getters: GetterTree<State, RootState> & Getters = {
  /**
   * получить id типа по коду
   * @param state
   */
  getOrderTypeId: state => code => {
    // debugger
    const index = state.orderTypes.findIndex(item => item.entityCode === code);
    if (index == -1) {
      return -1;
    }
    const id = state.orderTypes[index].entityId;
    return id;
  }
};
