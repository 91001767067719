import { StringTypedResponse } from "../typeSystem/api/response";
import { CreateBooking } from "@/interfaceAndType/typesTrips";
export class ApiHotel {
  _http: any;
  constructor(_http) {
    this._http = _http;
  }
  errRes: StringTypedResponse = {
    errors: ["Ошибка сервера или нет соединения!"],
    isWarning: true,
    isValid: false,
    warnings: ["Ошибка сервера или нет соединения!"],
    countPage: 0,
    object: {},
  };
  /**
   * Бронирование отеля
   * @param PersonList
   * @param OfferId
   */
  async booking(
    sendData: CreateBooking,
    OfferId: string | number
  ): Promise<StringTypedResponse> {
    const urlReq = `/api/Hotel/Booking/${OfferId}`;
    try {
      const res = await this._http.post(urlReq, sendData);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * Выписка билета / Ваучер
   * @param PersonList
   * @param OfferId
   */
  async ticketing(OfferId: string | number): Promise<StringTypedResponse> {
    const urlReq = `/api/Hotel/Ticketing/${OfferId}`;
    try {
      const res = await this._http.post(urlReq);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * Отмена бронирования
   * @param PersonList
   * @param OfferId
   */
  async cancel(OfferId: string | number): Promise<StringTypedResponse> {
    const urlReq = `/api/Hotel/Cancel/${OfferId}`;
    try {
      const res = await this._http.post(urlReq);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }
}
