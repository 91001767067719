import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "aviaCard__head"
}
const _hoisted_2 = { class: "aviaCard__head__left" }
const _hoisted_3 = { class: "airBlock" }
const _hoisted_4 = { class: "aviaCard__head__right" }
const _hoisted_5 = { class: "infoBlock" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_ctx.dataHeader)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.dataHeader.airplaneCodes, (aitem, index) => {
              return (_openBlock(), _createBlock("div", {
                class: "air",
                key: index
              }, _toDisplayString(aitem), 1))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createVNode("div", {
            class: "route",
            title: _ctx.dataHeader.routeList
          }, _toDisplayString(_ctx.dataHeader.routeList), 9, ["title"])
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.dataHeader.transfers, (tr, index) => {
              return (_openBlock(), _createBlock("div", {
                class: "info",
                key: index
              }, " Пересадок: " + _toDisplayString(tr), 1))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.item.itineraries, (itinerary) => {
            return (_openBlock(), _createBlock("div", {
              class: "type",
              key: itinerary.key
            }, _toDisplayString((itinerary.airline && itinerary.airline.name) ? itinerary.airline.name : "Н/Д"), 1))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]))
    : _createCommentVNode("", true)
}