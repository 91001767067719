
import { computed, defineComponent, PropType, toRefs } from "vue";
import {
  ORDER_TYPES,
  STATUS_ORDER,
  canShowBnt,
  CONTROLL_BTN
} from "../../constant";
import { MultiItinerary } from "../../interfaceAndType/typesSearh";
import { PersonType } from "../../interfaceAndType/typesShared";
import {
  OrderDTOType,
  TripWithOrders
} from "../../interfaceAndType/typesTrips";
import { useStore } from "../../store/modules";
import { ActionTypes } from "../../store/modules/trips/contract";

export default defineComponent({
  name: "ControlBlock",
  props: {
    PersonList: {
      type: Array as PropType<Array<PersonType>>,
      required: true
    },
    selectTikets: { required: true },
    OfferId: {
      type: [String, Number],
      required: true
    },
    activeOffer: { type: Object as PropType<OrderDTOType>, required: true },
    listOrders: { type: Object as PropType<TripWithOrders>, required: true },
    initData: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const { PersonList, selectTikets, activeOffer, OfferId, initData } = toRefs(
      props
    );
    const reinitData = () => {
      initData.value();
    };
    const store = useStore();
    const type = computed(() => store.state.trips.activeTypeCode);
    const showControlBlock = computed(() => {
      const { statusCode } = activeOffer.value;
      if (
        statusCode == STATUS_ORDER.Canceled ||
        statusCode == STATUS_ORDER.Denied ||
        !selectTikets.value ||
        !PersonList.value
      ) {
        return false;
      }
      return true;
    });

    const setBoking = async () => {
      if (type.value == ORDER_TYPES.AVIA) {
        const paramsIn = {
          PersonList: PersonList.value,
          OfferId: OfferId.value
        };
        await store.dispatch(ActionTypes.BOOKING_AVIA, paramsIn);
      }
      if (type.value == ORDER_TYPES.HOTEL) {
        const paramsIn = {
          sendData: {
            offerId: OfferId.value as number,
            // checkinTime?: string | null; //Время заезда.
            // checkoutTime?: string | null; //Время выезда.
            // includeAdditionalMeal?: boolean | null; //Дополнительное питание.
            personList: PersonList.value
          },
          OfferId: OfferId.value
        };
        await store.dispatch(ActionTypes.BOOKING_HOTEL, paramsIn);
      }
      if (
        type.value == ORDER_TYPES.TRANSFER ||
        type.value == ORDER_TYPES.FREE
      ) {
        const paramsIn = {
          PersonList: PersonList.value,
          OfferId: OfferId.value
        };
        await store.dispatch(ActionTypes.BOOKING_TRANS_FREE, paramsIn);
      }
      if (type.value == ORDER_TYPES.RAILWAY) {
        const paramsIn = {
          PersonList: PersonList.value,
          OfferId: OfferId.value
        };
        await store.dispatch(ActionTypes.BOOKING_RAILWAY, paramsIn);
      }
      reinitData();
    };

    const cancelBoking = async () => {
      const paramsIn = {
        OfferId: OfferId.value
      };
      if (type.value == ORDER_TYPES.AVIA) {
        await store.dispatch(ActionTypes.CANCEL_AVIA, paramsIn);
      }
      if (type.value == ORDER_TYPES.HOTEL) {
        await store.dispatch(ActionTypes.CANCEL_HOTEL, paramsIn);
      }
      if (type.value == ORDER_TYPES.RAILWAY) {
        await store.dispatch(ActionTypes.CANCEL_RAILWAY, paramsIn);
      }
      reinitData();
    };

    const ticketingBoking = async () => {
      const paramsIn = {
        OfferId: OfferId.value
      };
      let res = true;
      if (type.value == ORDER_TYPES.AVIA) {
        res = await store.dispatch(ActionTypes.TICKETTING_AVIA, paramsIn);
      }
      if (type.value == ORDER_TYPES.HOTEL) {
        res = await store.dispatch(ActionTypes.TICKETTING_HOTEL, paramsIn);
      }
      if (type.value == ORDER_TYPES.RAILWAY) {
        res = await store.dispatch(ActionTypes.TICKETTING_RAILWAY, paramsIn);
      }
      if (res) {
        reinitData();
      }
    };
    const rejectBoking = async () => {
      const paramsIn = {
        OfferId: OfferId.value
      };
      if (type.value == ORDER_TYPES.RAILWAY) {
        await store.dispatch(ActionTypes.REJECT_RAILWAY, paramsIn);
      }
      reinitData();
    };
    const controlbtn = CONTROLL_BTN;
    const showbntContol = computed(() => {
      const statusCode = activeOffer.value.statusCode;
      const res = {};
      Object.values(controlbtn).forEach(codeBtn => {
        res[codeBtn] = canShowBnt(type.value, statusCode, codeBtn);
      });
      return res;
    });

    return {
      setBoking,
      cancelBoking,
      showControlBlock,
      ticketingBoking,
      controlbtn,
      showbntContol,
      rejectBoking
    };
  }
});
