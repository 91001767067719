<template>
  <div class="search-page search-page--hotels">
    <div class="container">
      <app-section
        :title="'Поиск отелей'"
        :descr="'Введите нужные парметры для поиска билетов'"
      >
        <template v-slot:content>
          <hotels-search-form />
          <search-footer />
        </template>
      </app-section>
    </div>
  </div>
</template>
<script>
import HotelsSearchForm from "../components/components/search/HotelSearchFrom";
export default {
  name: "HotelSearch",
  components: {
    HotelsSearchForm
  }
};
</script>
<style lang="scss" scoped></style>
