import { ActionTree } from "vuex";
import { State as RootState } from "../index";
import { State, Actions, ActionTypes } from "./contract";
import { ApiService } from "../../../utils/ApiService";
import {
  MutationTypes as HotelMutationTypes,
  MutationTypes
} from "./MutationsType";
import { MutationTypes as SystemMutationTypes } from "../system/MutationsType";

import { EVENT_LOADER } from "../system/contract";
import { DropDownEntity } from "../../../interfaceAndType/typesShared";
import {
  BaseHotelAccesor,
  HotelDetailResponce,
  HotelSearchRequest
} from "@/interfaceAndType/typesHotel";

let totalRequests = 0;
const getData = async (apiService, res, commit) => {
  commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
  const data = await apiService.getHotelTree(res.object.hotelTree);
  const statuses = data.object.hotelTree.states;

  let completed = true;
  for (const key in statuses) {
    if (!statuses[key].isFinished) {
      completed = false;
      break;
    }
  }
  statuses.completed = completed;

  if (!completed && totalRequests < 200) {
    setTimeout(() => {
      totalRequests++;
      getData(apiService, res, commit);
    }, 500);
  } else {
    if (data.errors && (data.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: data.errors,
        warnings: data.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return false;
    } else {
      commit(MutationTypes.SET_HOTEL_DATA, { data: data.object.hotelTree });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
    }
  }
};

// Define actions
export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SEARCH_CITY]({ commit }, payload: { city: string }) {
    const { city } = payload;
    const apiService = new ApiService();
    const res = await apiService.getCity(city);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      return [];
    } else {
      return res.object as Array<DropDownEntity>;
    }
  },
  async [ActionTypes.SEARCH_HOTELS](
    { commit },
    payload: { searchData: HotelSearchRequest }
  ) {
    const { searchData } = payload;

    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    commit(HotelMutationTypes.SAVE_HOTEL_DATA, { data: searchData });
    const apiService = new ApiService();
    const res = await apiService.searchHotels(searchData);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return false;
    } else {
      totalRequests = 0;
      getData(apiService, res, commit);
      return true;
    }
  },
  async [ActionTypes.GET_HOTEL_PRICE](
    { commit },
    payload: { accessors: BaseHotelAccesor[]; cityId: number }
  ) {
    const { accessors, cityId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.getHotelPricing(accessors, cityId);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return null;
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object as HotelDetailResponce;
    }
  }
};
