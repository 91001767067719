
import { defineComponent, PropType } from "vue";
import { NTrainListReference } from "../../../interfaceAndType/typesRail";

export default defineComponent({
  name: "railTicketHeader",
  props: {
    item: {
      type: Object as PropType<NTrainListReference>
    }
  },
  setup(props) {
    return {};
  }
});
