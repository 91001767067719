
import { defineComponent, PropType, toRefs } from "vue";

import RailTicketHeader from "./RailHeader.vue";
import { NTrainListReference } from "../../../interfaceAndType/typesRail";
import RailRouteSmall from "./RailRouteSmall.vue";
import RailBottomSmall from "./RailBottomSmall.vue";
import { useStore } from "../../../store/modules";
import { MutationTypes } from "../../../store/modules/rail/MutationsType";

export default defineComponent({
  name: "RailTicketSmall",
  props: {
    item: {
      type: Object as PropType<NTrainListReference>
    },
    isModeOffer: {
      type: Boolean,
      default: false
    },
     isCansel: {
      type: Boolean,
      default: false
    },
    hasCansel: {
      type: Boolean,
      default: false
    },
    hasControl: {
      type: Boolean,
      default: true
    }
  },
  emits: ["select-offer", "unselect-offer"],
  setup(props, { emit }) {
    const { item } = toRefs(props);
    const store = useStore();
    const unselect = () => {
      if (item && item.value && item.value.numberTrain) {
        store.commit(MutationTypes.UNSELECT_TRAIN, {
          key: item.value.numberTrain
        });
      }
    };
    const selectOffer = val => {
      emit("select-offer", val);
    };
    return { unselect, selectOffer };
  },
  components: {
    RailTicketHeader,
    RailRouteSmall,
    RailBottomSmall
  }
});
