
import { defineComponent, PropType, toRefs } from "vue";

import RailTicketHeader from "./RailHeader.vue";
import RailRoute from "./RailRoute.vue";
import RailBottom from "./RailBottom.vue";
import { NTrainListReference } from "../../../interfaceAndType/typesRail";
import { useStore } from "../../../store/modules";
import { MutationTypes } from "../../../store/modules/rail/MutationsType";

export default defineComponent({
  name: "RailTicket",
  props: {
    item: {
      type: Object as PropType<NTrainListReference>
    },
    openPricingModal: {
      type: Function
    },
    isSelected: {
      type: Boolean
    }
  },
  setup(props) {
    const { item } = toRefs(props);
    const store = useStore();
    const unselect = () => {
      if (item && item.value && item.value.numberTrain) {
        store.commit(MutationTypes.UNSELECT_TRAIN, {
          key: item.value.numberTrain
        });
      }
    };
    return {unselect};
  },
  components: {
    RailTicketHeader,
    RailRoute,
    RailBottom
  }
});
