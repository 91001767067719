
import { defineComponent, PropType, toRefs } from "vue";
import { FreeOffer } from "../../../interfaceAndType/typesServices";
import SeparateLine from "../common/SeparateLine.vue";
import FreeServiceFooter from "./freeservice/FreeServiceFooter.vue";
import FreeServiceHeader from "./freeservice/FreeServiceHeader.vue";
import FreeServiceMain from "./freeservice/FreeServiceMain.vue";

export default defineComponent({
  name: "FreeCard",
  components: {
    FreeServiceHeader,
    FreeServiceMain,
    FreeServiceFooter,
    SeparateLine
  },
  emits: ["select-offer", "unselect-offer"],
  props: {
    item: {
      type: Object as PropType<FreeOffer>
    },
    isModeOffer: {
      type: Boolean,
      default: false
    },
    isCansel: {
      type: Boolean,
      default: false
    },
    hasCansel: {
      type: Boolean,
      default: false
    },
    hasControl: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { item } = toRefs(props);
    const selectOffer = () => {
      emit("select-offer", item?.value);
    };
    return { selectOffer };
  }
});
