
import { defineComponent, PropType } from "vue";
import { NTrainListReference } from "../../../interfaceAndType/typesRail";
import SeparateLine from "../common/SeparateLine.vue";
import RailDate from "./RailDate.vue";
import RailInfo from "./RailInfo.vue";
import RailRouteLine from "./RailRouteLine.vue";
export default defineComponent({
  components: {
    SeparateLine,
    RailDate,
    RailRouteLine,
    RailInfo
  },
  props: {
    item: {
      type: Object as PropType<NTrainListReference>
    }
  },
  setup(props) {
    return {};
  }
});
