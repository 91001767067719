import { ActionTree } from "vuex";
import { State as RootState } from "../index";
import { State, Actions, ActionTypes } from "./contract";
import { ApiService } from "../../../utils/ApiService";
import { MutationTypes as RailMutationTypes } from "./MutationsType";
import { MutationTypes as SystemMutationTypes } from "../system/MutationsType";

import { EVENT_LOADER } from "../system/contract";
import { DropDownEntity } from "../../../interfaceAndType/typesShared";
import {
  RailwaySearchRequest,
  RailwayPricingRequest,
  TrainListResponse
} from "../../../interfaceAndType/typesRail";

// Define actions
export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SEARCH_STATION]({ commit }, payload: { station: string }) {
    const { station } = payload;
    const apiService = new ApiService();
    const res = await apiService.getRailStation(station);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object as Array<DropDownEntity>;
    }
  },
  async [ActionTypes.SEARCH_RAIL](
    { commit },
    payload: { searchData: RailwaySearchRequest }
  ) {
    const { searchData } = payload;

    commit(RailMutationTypes.SAVE_RAIL_SEARCHREQUEST, { data: searchData });
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.searchRail(searchData);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return false;
    } else {
      commit(RailMutationTypes.SET_TRAIN_DATA, {
        data: res.object[0].bodyTrainListReference.nTrainListReferences
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      if (res.object.length < 1) {
        commit(SystemMutationTypes.ADD_ERROR, {
          errors: ["Поиск выдал пустой ответ!"],
          warnings: []
        });
        return false;
      }
      return res.object;
    }
  },
  async [ActionTypes.GET_PRICING](
    { commit },
    payload: { reqObject: RailwayPricingRequest }
  ) {
    const { reqObject } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.getRailPricing(reqObject);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return null;
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object as TrainListResponse;
    }
  }
};
