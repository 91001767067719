
import { defineComponent, ref, toRefs } from "vue";
import { DropDownEntity } from "../../../interfaceAndType/typesShared";
import { useStore } from "../../../store/modules";
import { ActionTypes } from "../../../store/modules/shared/contract";
import _ from "lodash";

export default defineComponent({
  name: "CommonSelector",
  emits: ["item-set", "end-search", "start-search"],
  props: {
    placeholder: {
      type: String,
      default: "Выберите"
    },
    minLength: {
      type: Number,
      default: 3
    },
    field: {
      type: String,
      default: "entityName"
    },
    alternativeField: {
      type: String,
      default: "entityCode"
    },
    subListId: {
      type: Number
    },
    type: {
      type: String,
      required: true
    },
    isImportant: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { subListId, type } = toRefs(props);
    const store = useStore();
    const options = ref<Array<DropDownEntity>>([]);
    const selected = ref<DropDownEntity | null>();
    const onSearch = _.debounce(async event => {
      const paramsIn = {
        type: type.value,
        search: event.query,
        subListId: subListId && subListId.value ? subListId.value : null
      };
      emit("start-search");
      options.value = await store.dispatch(
        ActionTypes.SEARH_DROPLIST,
        paramsIn
      );
      emit("end-search", !(options.value.length > 0));
    });

    const setData = event => {
      emit("item-set", event.value);
    };

    return {
      setData,
      selected,
      options,
      onSearch
    };
  }
});
