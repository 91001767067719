
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "hotelServices",
  props: {
    filterByServices: {
      type: Function
    }
  },
  setup(props) {
    const services = ref(null);
    return { services };
  }
});
