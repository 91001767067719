
import { ExtendedHotelInfo, ExtendedRate } from "@/interfaceAndType/typesHotel";
import { computed, defineComponent, PropType, toRefs } from "vue";

export default defineComponent({
  name: "HotelMainInfo",
  props: {
    item: {
      type: Object as PropType<ExtendedHotelInfo>,
      required: true
    },
    parseDate: {
      required: true
    }
  },
  setup(props) {
    const { item } = toRefs(props);
    const actualRoom = computed<ExtendedRate | boolean>(() => {
      if (item.value.rooms && item.value.rooms.length > 0) {
        return item.value.rooms[0];
      }
      return false;
    });

    const dateIn = computed(() => {
      if (actualRoom.value) {
        const room = actualRoom.value as ExtendedRate;
        return room.baseHotelRateAccessor.accessor.checkin;
      }
      return false;
    });
    const dateOut = computed(() => {
      if (actualRoom.value) {
        const room = actualRoom.value as ExtendedRate;
        return room.baseHotelRateAccessor.accessor.checkout;
      }
      return false;
    });
    const timeIn = computed(() => {
      if (actualRoom.value) {
        const room = actualRoom.value as ExtendedRate;
        return room.baseHotelRateAccessor.checkInTime;
      }
      return "12:00";
    });
    const timeOut = computed(() => {
      if (actualRoom.value) {
        const room = actualRoom.value as ExtendedRate;
        return room.baseHotelRateAccessor.checkOutTime;
      }
      return "14:00";
    });
    const addres = computed(() => {
      return item.value.hotelAddress.bs.rus
        ? item.value.hotelAddress.bs.rus
        : null;
    });
    const phone = computed(() => {
      return item.value.phone;
    });
    return { dateIn, dateOut, timeIn, timeOut, addres, phone };
  }
});
