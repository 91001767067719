
import { computed, defineComponent, PropType, toRefs } from "vue";
import {
  Flight,
  RefEntityShortDTO,
} from "../../../interfaceAndType/typesSearh";
import moment from "moment";
import { useStore } from "../../../store/modules/index";
moment.locale("ru");
export default defineComponent({
  props: {
    isleft: { type: Boolean, default: true },
    point: {
      type: Object as PropType<Flight>,
      required: true,
    },
  },

  setup(props) {
    const { point, isleft } = toRefs(props);
    const store = useStore();
    const info = computed(() => {
      const nowPoint = point.value;
      if (nowPoint) {
        const nowDataF = isleft.value
          ? nowPoint.departureDateStr
          : nowPoint.arrivalDateStr;
        const formatData = moment(nowDataF, "DD.MM.YYYY HH.mm.ss");
        const time = formatData.format("HH:mm");
        const date = formatData.format("DD MMM");
        const day = formatData.format("dddd");
        const codeAirPort = isleft.value
          ? nowPoint.airportDeparture
          : nowPoint.airportArrival;
        const terminal = isleft.value
          ? nowPoint.originTerminal
          : nowPoint.destinationTerminal;
        // console.log(point);

        const shornameAirport = store.getters.getNameAirport(
          codeAirPort && codeAirPort.code ? codeAirPort.code : ""
        );
        // if (shornameAirport == codeAirPort.code) {
        //   let airport: null | RefEntityShortDTO = null;
        //   if (isleft.value) {
        //     airport = point.value.airportDeparture;
        //   } else {
        //     airport = point.value.airportArrival;
        //   }
        //   shornameAirport =
        //     airport && airport.name ? airport.name : shornameAirport;
        // }

        return {
          time,
          date,
          day,
          codeAirPort,
          terminal,
          shornameAirport
        };
      }
      return null;
    });

    return {
      info,
    };
  },
});
