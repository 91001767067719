import { MutationTree } from "vuex";
import { Mutations, MutationTypes } from "./MutationsType";
import { State } from "./contract";
import { NAME_TOKEN } from "../../../constant";
// Define mutations
export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_USER_AUTHENTICATED](state: State, { token, result }) {
    state.isAuthenticated = result;
    state.token = token;
    localStorage.setItem(NAME_TOKEN, token);
  },
  [MutationTypes.SET_USER_PROFILE](state: State, { userProfile }) {
    state.userProfile = userProfile;
  },
  [MutationTypes.SET_NEW_TOKEN](state: State, { token }) {
    state.token = token;
    localStorage.setItem(NAME_TOKEN, token);
  }
};
