
import { BaseHotelAccesor } from "@/interfaceAndType/typesHotel";
import { computed, defineComponent, PropType, ref, toRefs } from "vue";

export default defineComponent({
  name: "AccesorsFilter",
  props: {
    accessors: {
      type: Array as PropType<Array<BaseHotelAccesor>>,
      required: true
    },
    filterByAccesor: {
      type: Function
    },
    selectData: {
      type: Array as PropType<Array<number>>,
      required: true
    }
  },
  setup(props) {
    const { accessors, selectData } = toRefs(props);
    const selectAcc = ref(selectData.value ? selectData.value : []);
    const shortAccessor = computed(() => {
      const res: { name: string; id: number }[] = [];
      if (accessors.value && accessors?.value.length > 0) {
        accessors.value.forEach(acc => {
          res.push({
            id: acc.idSystem,
            name:
              acc.gds && acc.gds?.name
                ? acc.gds?.name
                : "Код поставщика" + acc.idSystem
          });
        });
        return res;
      }
      return [];
    });

    return { selectAcc, shortAccessor };
  }
});
