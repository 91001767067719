
import { useToast } from "primevue/usetoast";
import { defineComponent, onMounted, watchEffect } from "vue";
import { useStore } from "./store/modules";
import { EVENT_LOADER } from "./store/modules/system/contract";
import { MutationTypes } from "./store/modules/system/MutationsType";

export default defineComponent({
  setup() {
    const toast = useToast();
    const store = useStore();
    const names = [
      EVENT_LOADER.DEF_LOUDER,
      EVENT_LOADER.LOGIN_USER,
      EVENT_LOADER.LOAD_USER_DATA
    ];
    watchEffect(() => {
      store.state.system.errors.forEach(error => {
        console.warn(error);
        toast.add({
          severity: "error",
          summary: "Ошибка",
          detail: error,
          life: 5000
        });
      });
      if (store.state.system.errors.length > 0) {
        store.commit(MutationTypes.CLEAR_ERRORS);
      }
    });
    onMounted(() => {
      const head = document.getElementsByTagName("head");
      const links = document.getElementsByTagName("link");
      const lastLink = links[links.length - 1];
      if (lastLink && lastLink.parentNode && lastLink.rel == "stylesheet") {
        lastLink.parentNode.removeChild(lastLink);
        head[0].appendChild(lastLink);
      }
      /**
       * Если вы это видите, то знайте мне жаль, что вы это видите.
       * я не смог настроить билд так, что бы мои стили были в конце, пришлось сделать такой костыль.
       * это реально жутко что вы это видите.
       * сори(
       */
    });
    return { names };
  }
});
