
import { computed, defineComponent, ref, toRefs } from "vue";
import { useStore } from "../../../store/modules";

export default defineComponent({
  name: "FilterAirportFrom",
  props: {
    filterByAirportFrom: {
      type: Function,
      required: true
    },
    acceptFilterAirportFrom: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const { filterByAirportFrom, acceptFilterAirportFrom } = toRefs(props);
    const allAirports = computed(() => {
      const searchData = store.state.avia.searhcData;
      return searchData ? searchData.referenceContainer.airports : [];
    });
    const selectedAirport = computed({
      get() {
        return acceptFilterAirportFrom.value;
      },
      set(item) {
        filterByAirportFrom.value(item);
      }
    });
    return { selectedAirport, allAirports };
  }
});
