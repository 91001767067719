import { Module } from "vuex";
import { State as RootState } from "../index";
import { State } from "./contract";

import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

// Create initial state
const state: State = {
  trips: [],
  pageCount: null,
  tripTicketsCach: {},
  orderTicketsCach: {},
  activeOrder: [],
  activeOrderIsLoad: false,
  activeTypeCode: null,
  activeOfferKey: null,
  acceptTrip: null
};

export const TripsModule: Module<State, RootState> = {
  state,
  mutations,
  actions,
  getters
};
