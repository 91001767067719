
import { defineComponent, PropType } from "vue";
import { NTrainListReference } from "../../../interfaceAndType/typesRail";

import RailServices from "./RailServices.vue";
import moment from "moment";

export default defineComponent({
  name: "RailRouteLine",
  props: {
    item: {
      type: Object as PropType<NTrainListReference>
    }
  },
  setup(props) {
    const calculateTravelTime = (start, end) => {
      const departure = moment(start);
      const arrival = moment(end);
      const difference = moment.duration(arrival.diff(departure));
      const hours = difference.hours();
      const minutes = difference.minutes();
      const modified = `${hours}ч:${minutes}м`;
      return modified;
    };
    return { calculateTravelTime };
  },
  components: {
    RailServices
  }
});
