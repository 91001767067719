
import { computed, defineComponent, ref, toRefs } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "FilterAirportTo",
  props: {
    filterByAirportTo: {
      type: Function,
      required: true
    },
    acceptFilterAirportTo: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const { filterByAirportTo, acceptFilterAirportTo } = toRefs(props);
    const allAirports = computed(() => {
      const searchData = store.state.avia.searhcData;
      return searchData ? searchData.referenceContainer.airports : [];
    });
    const selectedAirport = computed({
      get() {
        return acceptFilterAirportTo.value;
      },
      set(item) {
        filterByAirportTo.value(item);
      }
    });
    return { selectedAirport, allAirports };
  }
});
