import { Module } from "vuex";
import { State as RootState } from "../index";
import { State } from "./contract";

import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

// Create initial state
const state: State = {};

export const ServiceModule: Module<State, RootState> = {
  state,
  mutations,
  actions,
  getters
};
