/**
 * @depricate
 */

// AUTH
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_ME = "AUTH_ME";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

// SYSTEM

export const CLEAR_SYSTEM = "CLEAR_SYSTEM";
export const ADD_ERROR = "ADD_ERROR";

// SHARED
export const LOAD_DICTIONARIES = "LOAD_DICTIONARIES";

// AVIA
export const CLEAR_AVIA = "CLEAR_AVIA";
export const SEARHC_AVIA = "SEARHC_AVIA";
export const REFERENCE_AVIA = "REFERENCE_AVIA";
export const SET_AVIA_PAGINATOR = "SET_AVIA_PAGINATOR";
export const LOAD_PRICING = "LOAD_PRICING";
export const SET_SEARCH_AVIA = "SET_SEARCH_AVIA";
export const SET_PRICING = "SET_PRICING";
export const SET_FILTER_AIRLANE = "SET_FILTER_AIRLANE";
