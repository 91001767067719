import { State } from "./contract";
import {
  AviaSearchReqType,
  FareDrivenFlightSearchResponse,
  MultiItinerary,
  PricingResponse
} from "../../../interfaceAndType/typesSearh";
// mutations enums
export enum MutationTypes {
  SET_SEARH_DATA = "SET_SEARH_DATA",
  SET_LOAD_PRICING = "SET_LOAD_PRICING",
  SAVE_SEARH_DATA = "SAVE_SEARH_DATA",
  CHANGE_VARIANT = "CHANGE_VARIANT",
  SELECT_AVIA_TICKET = "SELECT_AVIA_TICKET",
  DELETE_SELECTED_AVIA_TICKET = "DELETE_SELECTED_AVIA_TICKET",
  CLEAR_STORE = "CLEAR_STORE"
}
// Mutation contracts
export type Mutations<S = State> = {
  [MutationTypes.SET_SEARH_DATA](
    state: S,
    payload: { data: FareDrivenFlightSearchResponse; result: boolean }
  ): void;
  [MutationTypes.SET_LOAD_PRICING](
    state: S,
    payload: { data: PricingResponse; result: boolean; keytiket: string }
  ): void;
  [MutationTypes.SAVE_SEARH_DATA](
    state: S,
    payload: { data: AviaSearchReqType }
  ): void;
  [MutationTypes.CHANGE_VARIANT](
    state: S,
    payload: { newVariant: MultiItinerary }
  ): void;
  [MutationTypes.SELECT_AVIA_TICKET](
    state: S,
    payload: { ticketKey: string }
  ): void;
  [MutationTypes.DELETE_SELECTED_AVIA_TICKET](
    state: S,
    payload: { ticketKey: string }
  );
  [MutationTypes.CLEAR_STORE](state: S, payload: {}): void;
};
