import { State, UserProfile } from "./contract";
// mutations enums
export enum MutationTypes {
  SET_USER_AUTHENTICATED = "SET_USER_AUTHENTICATED",
  SET_USER_PROFILE = "SET_USER_PROFILE",
  SET_NEW_TOKEN = "SET_NEW_TOKEN"
}
// Mutation contracts
export type Mutations<S = State> = {
  [MutationTypes.SET_USER_AUTHENTICATED](
    state: S,
    payload: { token: string; result: boolean }
  ): void;
  [MutationTypes.SET_USER_PROFILE](
    state: S,
    payload: { userProfile: UserProfile }
  ): void;
  [MutationTypes.SET_NEW_TOKEN](state: S, payload: { token: string }): void;
};
