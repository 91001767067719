import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderSlot as _renderSlot, renderList as _renderList, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { colspan: "7" }
const _hoisted_2 = { class: "detailOrder" }
const _hoisted_3 = { class: "deailOrderFilter " }
const _hoisted_4 = { class: "detailOrderList" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_avia_tiket = _resolveComponent("avia-tiket")
  const _component_hotels_ticket_small = _resolveComponent("hotels-ticket-small")
  const _component_transfer_card = _resolveComponent("transfer-card")
  const _component_free_card = _resolveComponent("free-card")
  const _component_rail_tiket_small = _resolveComponent("rail-tiket-small")

  return (_openBlock(), _createBlock("tr", null, [
    _createVNode("td", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("ul", _hoisted_3, [
          (_ctx.hasNew)
            ? (_openBlock(), _createBlock("li", {
                key: 0,
                class: { isActive: _ctx.selectedType == 'new' },
                onClick: _cache[1] || (_cache[1] = $event => (_ctx.setSelectedType('new')))
              }, " Выбранные варианты ", 2))
            : _createCommentVNode("", true),
          (!_ctx.onlyNew)
            ? (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                _createVNode("li", {
                  class: { isActive: _ctx.selectedType == null },
                  onClick: _cache[2] || (_cache[2] = $event => (_ctx.setSelectedType(null)))
                }, " Все сервисы ", 2),
                _createVNode("li", {
                  class: { isActive: _ctx.selectedType == 'Avia' },
                  onClick: _cache[3] || (_cache[3] = $event => (_ctx.setSelectedType('Avia')))
                }, " Авиабилеты ", 2),
                _createVNode("li", {
                  class: { isActive: _ctx.selectedType == 'Railway' },
                  onClick: _cache[4] || (_cache[4] = $event => (_ctx.setSelectedType('Railway')))
                }, " Ж/Д ", 2),
                _createVNode("li", {
                  class: { isActive: _ctx.selectedType == 'Hotel' },
                  onClick: _cache[5] || (_cache[5] = $event => (_ctx.setSelectedType('Hotel')))
                }, " Отели ", 2),
                _createVNode("li", {
                  class: { isActive: _ctx.selectedType == 'Transfer' },
                  onClick: _cache[6] || (_cache[6] = $event => (_ctx.setSelectedType('Transfer')))
                }, " Трансфер ", 2),
                _createVNode("li", {
                  class: { isActive: _ctx.selectedType == 'Free' },
                  onClick: _cache[7] || (_cache[7] = $event => (_ctx.setSelectedType('Free')))
                }, " Свободная услуга ", 2)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("", true)
        ]),
        _createVNode("div", _hoisted_4, [
          (_ctx.hasNew && _ctx.isShow('new') && _ctx.selectedType !== null)
            ? _renderSlot(_ctx.$slots, "new", { key: 0 })
            : _createCommentVNode("", true),
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.parsed, (item) => {
            return (_openBlock(), _createBlock(_Fragment, null, [
              (_ctx.isAvia(item) && _ctx.isShow('Avia'))
                ? (_openBlock(), _createBlock(_component_avia_tiket, {
                    key: item.key,
                    item: item,
                    small: true,
                    btnTarrif: false,
                    tripId: _ctx.tripId
                  }, null, 8, ["item", "tripId"]))
                : _createCommentVNode("", true),
              (_ctx.isHotel(item) && _ctx.isShow('Hotel'))
                ? (_openBlock(), _createBlock(_component_hotels_ticket_small, {
                    key: item.hotelName.bs.en,
                    item: item
                  }, null, 8, ["item"]))
                : _createCommentVNode("", true),
              (_ctx.isService(item) && _ctx.isShow('Transfer'))
                ? (_openBlock(), _createBlock(_component_transfer_card, {
                    key: item.SupplierContractId,
                    item: item
                  }, null, 8, ["item"]))
                : _createCommentVNode("", true),
              (_ctx.isFree(item) && _ctx.isShow('Free'))
                ? (_openBlock(), _createBlock(_component_free_card, {
                    key: item.SupplierContractId,
                    item: item
                  }, null, 8, ["item"]))
                : _createCommentVNode("", true),
              (_ctx.isRail(item) && _ctx.isShow('Railway'))
                ? (_openBlock(), _createBlock(_component_rail_tiket_small, {
                    key: item.numberTrain,
                    item: item
                  }, null, 8, ["item"]))
                : _createCommentVNode("", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 256 /* UNKEYED_FRAGMENT */))
        ])
      ])
    ])
  ]))
}