<template>
  <div class="socials-wrapper">
    <ul class="socials-list">
      <li class="socials-list__item socials-list__item--fb"></li>
      <li class="socials-list__item socials-list__item--tw"></li>
      <li class="socials-list__item socials-list__item--rss"></li>
      <li class="socials-list__item socials-list__item--google"></li>
      <li class="socials-list__item socials-list__item--inst"></li>
    </ul>
    <lang-list class="lang-list--footer" />
  </div>
</template>
<script>
import LangList from "./LangList.vue";
export default {
  components: { LangList },
  name: "socialsList"
};
</script>
