import { Module } from "vuex";
import { State as RootState } from "../index";
import { State } from "./contract";

import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";
import { HotelTreeWithItem } from "../../../interfaceAndType/typesHotel";

const loadData2 = localStorage.getItem("tempdatasearh");
const initDemoData2 = loadData2 ? JSON.parse(loadData2) : null;
const searhcTemp = localStorage.getItem("tempdatasearhqhotel");
const searhcQTemp = searhcTemp ? JSON.parse(searhcTemp) : null;
//todo удалить потом !!!
// Create initial state
const state: State = {
  searchReq: searhcQTemp,
  hotels: initDemoData2 ? initDemoData2 : [],
  hotelsCache: initDemoData2 ? initDemoData2 : false,
  pricingCache: {},
  selectedRooms: {}
};

export const HotelsModule: Module<State, RootState> = {
  state,
  mutations,
  actions,
  getters
};
