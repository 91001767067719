
import { defineComponent, ref } from "@vue/runtime-core";

export default defineComponent({
  name: "DepartureTimeFilter",
  components: {},
  props: {
    filterByDeparture: {
      type: Function
    }
  },
  setup(props) {
    const time = ref([0, 23]);
    const tooltipFormatter = (val) => {
      if (val <= 9) return `0${val}`
      return `${val}`
    };
    return {time, tooltipFormatter}
  }

})
