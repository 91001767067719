<template>
	<div style="width: 100%; background-color: white; padding: 10px">
		<div>
			<div>Cвободных мест {{ freeSeatsNums.length }}</div>

			<!-- <b-form-radio-group id="searchType_1" v-model="type_select" buttons size="sm" button-variant="outline-secondary" :options="type_select_option">
			</b-form-radio-group> -->
				<div class="wrap_seed">
					<div v-for="plase in freeSeatsNums" :key="plase" :class="isSelected(plase) ? 'active_seed' : 'seed'" @click="takeSeat(plase)">
						{{ plase }}
					</div>
				</div>
			<!-- <template v-else>
				<div>{{ freeSeatsNums }}</div>
				<div>Выбрано {{ value }}</div>
				<div>
					Выбрать
					<input v-model="selectPlase" type="number" :max="freeSeatsNums.length" min="0" />
				</div>
			</template> -->
		</div>
	</div>
</template>

<script>

export default {
	name: "SittingCarriage",
	components: {},
  props: {
    ck: { default: null, type: Object, required: true },
    cv: { default: null, type: Object, required: true },
    value: { 
      default: null, 
      type: Array, 
      required: true 
    },
    selectSeat: {
      type: Function
    }
  },
	created() {
		this.selectPlase = this.value.length;
	},
	data() {
		return {
			typeSelect: 0,
			typeSelectOption: [
				{ value: 0, text: "Режим 1" },
				{ value: 1, text: "Режим 2" }
			],
			selectPlase: 0,
			vHeight: 300,
			vWidth: 2400
		};
	},
	computed: {
		freeSeats() {
			return this.cv.numberOfFreePlace.split(", ");
		},
		freeSeatsNums() {
			return this.freeSeats.map((n) => parseInt(n));
		},
		free() {
			const seats = this.cv.numberOfFreePlace.split(", ");
			return seats.map((seat) => {
				let number = seat;
				let sign = "";

				const last = seat[seat.length - 1];
				if (["Ж", "М", "С", "Ц"].includes(last)) {
					sign = last;
					number = number.substring(0, number.indexOf(sign));
				}

				return {
					number: parseInt(number),
					sign
				};
			});
		}
	},

	watch: {
		value(newVal, oldVal) {
			this.selectPlase = newVal.length;
		},
		selectPlase(newVal, oldVal) {
			if (this.type_select === 1) {
				try {
					const selected = [];
					if (newVal !== 0 && newVal !== oldVal) {
						let splitFreeSeats = [...[], ...this.freeSeatsNums];
						splitFreeSeats = splitFreeSeats.splice(0, newVal);
						splitFreeSeats.forEach((el) => {
							selected.push(el);
						});
						this.selectSeat(selected);
					}
					if (newVal === 0) {
						this.selectSeat(selected);
					}
				} catch (e) {
					this.$console(e);
				}
			}
		}
	},

	methods: {
		seatsOffset(i) {
			// 4 это сдвиг относительно по порядку купе
			return 4 * (i - 1);
		},
		sideSeatsOffset(i) {
			// 36 это сдвиг от не боковых мест в купе
			// 9 - i  это изза обратного счета
			// 2 это сдвиг на количество боковых мест в купе
			return (9 - i) * 2 + 36;
		},
		isHighlight(number) {
			return this.isSelected(number) ? "highlight" : "";
		},
		busyClass(number) {
			return this.isBusy(number) ? "busy" : "";
		},
		isBusy(number) {
			return !this.freeSeatsNums.includes(number);
		},
		isSelected(number) {
			return this.value && this.value.includes(number);
		},
		price() {
			return this.ck.costTicket;
		},

		seatNumber(coupeNumber, seat) {
			return coupeNumber * 2 - (2 - seat);
		},
		seatInfo(number) {
			return this.seatTooltip(number);
		},
		seatClass(number) {
			if (this.value && this.value.includes(number)) {
				return "selected";
			}

			return this.isFree(number) ? "free" : "taken";
		},

		seatTooltip(number) {
			const filtered = this.free.filter((seat) => seat.number === number);
			if (filtered.length === 0) {
				return "Занято";
			}

			let res = "Свободное место \n";
			if (number % 2 === 0) res += "верхнее\n";
			else res += "нижнее\n";

			return (res += this.price() + " RUB");
		},
		takeSeat(number) {
			if (this.isBusy(number)) {
				return;
			}

			const selected = this.value || [];
      if (selected.includes(number)) {
        const index = selected.indexOf(number);
        selected.splice(index, 1);
      } else {
        selected.push(number);
      }
      this.selectSeat(selected);
		}
	}
};
</script>

<style scoped lang="scss">

.seed {
	border: 1px solid;
	width: 30px;
	height: 30px;
	margin: 2px;
	cursor: pointer;
	text-align: center;
	background-color: #bbffbb;
	line-height: 28px;

	&:hover {
		background-color: #65C1F5;
	}
}

.active_seed {
	text-align: center;
	border: 1px solid;
	width: 30px;
	height: 30px;
	margin: 2px;
	cursor: pointer;
	line-height: 28px;
	background-color: #F78F1E;
}
.wrap_seed {
	display: flex;
	flex-wrap: wrap;
}
</style>
