import { ActionTree } from "vuex";
import { State as RootState } from "../index";
import { State, Actions, ActionTypes } from "./contract";
import { ApiService } from "../../../utils/ApiService";
import { MutationTypes as SystemMutationTypes } from "../system/MutationsType";
import { OrderCreateReqType } from "../../../interfaceAndType/typesOrder";
import {
  AviaPricingType,
  AviaSearchReqType,
  Leg
} from "../../../interfaceAndType/typesSearh";
import { MutationTypes } from "./MutationsType";
import { DropDownEntity } from "../../../interfaceAndType/typesShared";
import { OrderDTOType } from "@/interfaceAndType/typesTrips";
import { EVENT_LOADER } from "../system/contract";
// Define actions
export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GET_CLIENT]({ commit }, payload: { searchParam: string }) {
    const { searchParam } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.getCompany(searchParam);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object as Array<DropDownEntity>;
    }
  },
  async [ActionTypes.GET_CONTRACT](
    { commit },
    payload: { searchParam: string; clientId: number }
  ) {
    const { searchParam, clientId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.getContract(searchParam, clientId);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object as Array<DropDownEntity>;
    }
  },
  async [ActionTypes.GET_INITIATOR](
    { commit },
    payload: { searchParam: string; contractId: number }
  ) {
    const { searchParam, contractId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.getInitiator(searchParam, contractId);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object as Array<DropDownEntity>;
    }
  },
  async [ActionTypes.GET_TRIP]({ commit }, payload: { search: string }) {
    const { search } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.getTrips(search);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object as Array<DropDownEntity>;
    }
  },
  async [ActionTypes.GET_ORDERTYPE]({ commit }) {
    const apiService = new ApiService();
    const res = await apiService.getOrderTypeId();

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      return [];
    } else {
      commit(MutationTypes.SET_ORDER_TYPES, { types: res.object });
      return res.object as Array<DropDownEntity>;
    }
  },
  async [ActionTypes.SEND_ORDER](
    { commit },
    payload: { order: OrderCreateReqType }
  ) {
    const { order } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.sendOrder(order);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return false;
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return true;
    }
  },
  async [ActionTypes.GET_ORDER_OFFERS](
    { commit },
    payload: { orderId: number }
  ) {
    const { orderId } = payload;
    const apiService = new ApiService();
    const res = await apiService.getOrderOffers(orderId);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      return null;
    } else {
      return res.object as OrderDTOType;
    }
  }
};
