import { FareDrivenFlightSearchResponse } from "@/interfaceAndType/typesSearh";

/**
 * Есть ли данные в ответе
 * @param date
 */
export const hasDataInResultSearchAvia = (
  date: FareDrivenFlightSearchResponse
) => {
  let res = false;
  if (date && date.variants && date.variants.length > 0) {
    res = true;
  }
  return res;
};
