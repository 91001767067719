
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  toRefs
} from "vue";

import SingleDropdown from "../shared/SingleDropdown.vue";
import RailServices from "../tikets/rail/RailServices.vue";
import {
  CKTrainListReference,
  NTrainListReference,
  RailwayPricingRequest
} from "../../interfaceAndType/typesRail";
import { ActionTypes, State } from "../../store/modules/rail/contract";
import { MutationTypes } from "../../store/modules/rail/MutationsType";
import { useStore } from "../../store/modules";
import clearReactive from "../../helpers/clearReactive";
import RailSeatsSelect from "./rail/RailSeatsSelect.vue";

export default defineComponent({
  name: "RailSeatsModal",
  props: {
    display: {
      type: Boolean,
      default: false
    },
    toggleDisplay: {
      type: Function,
      default: () => {
        return;
      }
    },
    item: {
      type: Object as PropType<NTrainListReference>
    }
  },
  setup(props) {
    const { item } = toRefs(props);
    const store = useStore();
    const localDisplay = computed({
      get: () => {
        return props.display;
      },
      set: () => {
        props.toggleDisplay();
      }
    });
    const fields = reactive({
      carriageSelected: null,
      classSelected: null,
      carriageNumber: null
    });

    const selectedCarriage = ref<CKTrainListReference | null>();

    const clearSelectedFields = () => {
      fields.carriageSelected = null;
      fields.classSelected = null;
      fields.carriageNumber = null;
    };

    const clearSelectedCarriage = () => {
      selectedCarriage.value = null;
    };

    const carriages = computed<CKTrainListReference[] | null>(() => {
      if (
        store.state.rail.pricingCache &&
        item &&
        item.value &&
        item.value.numberTrain &&
        store.state.rail.pricingCache[item.value.numberTrain]
      ) {
        return store.state.rail.pricingCache[item.value.numberTrain]
          .ckTrainListReferences;
      } else return [];
    });
    const carriageTypes = computed(() => {
      if (carriages.value) {
        const types = carriages.value.map(item => item.categoryCarriagePrinted);
        return types.filter((item, index) => {
          return index == types.indexOf(item);
        });
      }
    });
    const classOptions = computed(() => {
      if (carriages.value) {
        const classes = carriages.value
          .filter(
            item => item.categoryCarriagePrinted == fields.carriageSelected
          )
          .map(item => item.classServicesCarriage);
        return classes.filter((item, index) => {
          return index == classes.indexOf(item);
        });
      }
    });
    const carriageNumbers = computed(() => {
      if (carriages.value) {
        const numbers = carriages.value
          .filter(
            item =>
              item.categoryCarriagePrinted == fields.carriageSelected &&
              item.classServicesCarriage == fields.classSelected
          )
          .map(item => item.cvTrainListReference.number);
        return numbers.filter((item, index) => {
          return index == numbers.indexOf(item);
        });
      }
    });

    const selectCarriageNumber = () => {
      if (
        fields.carriageSelected &&
        fields.classSelected &&
        fields.carriageNumber &&
        carriages.value
      ) {
        const selected = carriages.value.filter(item => {
          if (
            item.categoryCarriagePrinted == fields.carriageSelected &&
            item.classServicesCarriage == fields.classSelected &&
            item.cvTrainListReference.number == fields.carriageNumber
          ) {
            return true;
          }
        });
        selectedCarriage.value = selected[0];
      }
    };

    const selectProperty = (field, val) => {
      clearSelectedCarriage();
      fields[field] = val;
      if (field == "carriageNumber") {
        selectCarriageNumber();
      }
    };

    const selectSeat = seats => {
      if (selectedCarriage.value) {
        selectedCarriage.value.cvTrainListReference.selected = seats
          .sort((a, b) => a - b)
          .join(", ");
      }
    };

    const save = () => {
      if (item && item.value && item.value.numberTrain) {
        const nowTrain: NTrainListReference = clearReactive(
          store.state.rail.pricingCache[item.value.numberTrain]
        );
        if (
          selectedCarriage.value &&
          selectedCarriage.value.cvTrainListReference
        ) {
          const nowCarriage = clearReactive(selectedCarriage.value);
          const allSelectedPlaces = selectedCarriage.value.cvTrainListReference.selected!.split(
            ", "
          );

          if (allSelectedPlaces.length == 1) {
            nowCarriage.cvTrainListReference.selected = allSelectedPlaces[0];
          } else {
            nowCarriage.cvTrainListReference.selected =
              allSelectedPlaces[0] +
              "-" +
              allSelectedPlaces[allSelectedPlaces.length - 1];
          }

          if (
            nowCarriage.categoryCarriage == "КУПЕ" ||
            nowCarriage.categoryCarriage == "ПЛАЦ"
          ) {
            const isEven = value => {
              return !(value % 2);
            };
            let uppperPlaces = 0;
            let lowerPlaces = 0;
            allSelectedPlaces.forEach(place => {
              isEven(place) ? uppperPlaces++ : lowerPlaces++;
            });
            nowCarriage.cvTrainListReference.numberOfUp = uppperPlaces
              ? uppperPlaces
              : null;
            nowCarriage.cvTrainListReference.numberOfDown = lowerPlaces
              ? lowerPlaces
              : null;
          }
          nowTrain.ckTrainListReferences = [nowCarriage];
          store.commit(MutationTypes.SELECT_TRAIN_WITH_SEATS, {
            data: nowTrain,
            key: item.value.numberTrain
          });
        }
        localDisplay.value = false;
      }
    };

    const getPricing = async () => {
      if (
        item &&
        item.value &&
        item.value.numberTrain &&
        !store.state.rail.pricingCache[item.value.numberTrain]
      ) {
        clearSelectedFields();
        clearSelectedCarriage();
        if (item && item.value) {
          const reqObject = {
            origin: item.value.passengerDepartureStation.code,
            destination: item.value.passengerArrivalStation.code,
            date: item.value.dateDepartureFromStation,
            train: item.value.numberTrain,
            time: null,
            advertDomain: null,
            grouppingType: 0, // Уточнить откуда его получаем
            lang: null,
            clientId: null,
            passengers: null,
            contractId: null
          };
          const data = await store.dispatch(ActionTypes.GET_PRICING, {
            reqObject
          });
          if (data) {
            store.commit(MutationTypes.SET_TRAIN_PRICE, {
              data: data!.bodyTrainListReference!.nTrainListReferences![0],
              key: item.value.numberTrain
            });
            return data;
          }
        }
      } else {
        if (item && item.value && item.value.numberTrain) {
          return store.state.rail.pricingCache[item.value.numberTrain]
            .ckTrainListReferences;
        }
      }
    };
    return {
      localDisplay,
      carriageTypes,
      classOptions,
      carriageNumbers,
      getPricing,
      fields,
      selectProperty,
      selectedCarriage,
      selectCarriageNumber,
      selectSeat,
      save
    };
  },
  components: {
    SingleDropdown,
    RailServices,
    RailSeatsSelect
  }
});
