import { GetterTree } from "vuex";

import { State as RootState } from "../index";
import { State, Getters } from "./contract";

// getters
export const getters: GetterTree<State, RootState> & Getters = {
  isAuthenticated: state => {
    return state.token ? true : false;
  },
  hasProfile: state => {
    return state.userProfile ? true : false;
  }
};
