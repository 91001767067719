import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "aviaRoute cursorPointer" }
const _hoisted_2 = { class: "aviaRoute__main" }
const _hoisted_3 = { class: "aviaRoute__main__wrap" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_avia_data = _resolveComponent("avia-data")
  const _component_route_line = _resolveComponent("route-line")
  const _component_avia_info = _resolveComponent("avia-info")
  const _component_SeparateLine = _resolveComponent("SeparateLine")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_avia_data, { point: _ctx.startPoint }, null, 8, ["point"]),
        _createVNode(_component_route_line, { allFlight: _ctx.allFlight }, null, 8, ["allFlight"]),
        _createVNode(_component_avia_data, {
          isleft: false,
          point: _ctx.endPoint
        }, null, 8, ["point"])
      ]),
      (!_ctx.small)
        ? (_openBlock(), _createBlock(_component_avia_info, {
            key: 0,
            allFlight: _ctx.allFlight
          }, null, 8, ["allFlight"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_SeparateLine)
  ]))
}