
import { defineComponent, ref } from "vue";

import FilterAviacompany from "../filters/avia/FilterAviacompany.vue";
import FilterAirportFrom from "../filters/avia/FilterAirportForm.vue";
import FilterAirportTo from "../filters/avia/FilterAirportTo.vue";
// import FilterChanges from "../filters/avia/FilterChanges.vue";
// import FilterConnections from "../filters/avia/FilterConnections.vue";
// import FilterFlightTime from "../filters/avia/FilterFlightTime.vue";
// import FilterFlightType from "../filters/avia/FilterFlightType.vue";

export default defineComponent({
  name: "aviaFilters",
  components: {
    FilterAviacompany,
    FilterAirportFrom,
    FilterAirportTo,
    // FilterChanges,
    // FilterConnections,
    // FilterFlightTime
    // FilterFlightType
  },
  props: {
    filterByCompany: {
      type: Function,
      required: true
    },
    acceptFilter: {
      type: Function,
      required: true
    },
    acceptFilterAirlines: {
      type: Array,
      required: true
    },
    filterByAirportFrom: {
      type: Function,
      required: true
    },
    acceptFilterAirportFrom: {
      type: Array,
      required: true
    },
    filterByAirportTo: {
      type: Function,
      required: true
    },
    acceptFilterAirportTo: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const changesCount = ref<Array<string>>([]);
    const connectionsCount = ref<Array<string>>([]);
    const timeTo = ref<Array<number>>([2, 10]);
    const timeFrom = ref<Array<number>>([4, 20]);
    const selectedType = ref<string>("Регулярный рейс");
    const typesVariants = ref<Array<object>>([
      { name: "Регулярный рейс" },
      { name: "Чартерный рейс" },
      { name: "Любой рейс" }
    ]);

    return {
      changesCount,
      connectionsCount,
      timeTo,
      timeFrom,
      selectedType,
      typesVariants
    };
  }
});
