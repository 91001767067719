
import SubHeader from "../components/shared/SubHeader.vue";
import HotelsFilter from "../components/filters/HotelsFilter.vue";
import HotelsSearchCard from "../components/tikets/hotel/HotelsSearchCard.vue";
import HotelsSearchModal from "../components/modals/HotelsModal.vue";
import OrderCreateModal from "../components/modals/OrderCreateModal.vue";
import _ from "lodash";

import { computed, defineComponent, nextTick, ref, watch } from "vue";
import { useStore } from "../store/modules";
import { DEFAULT_PERPAGE, ORDER_TYPES } from "../constant";
import {
  BaseHotelAccesor,
  HotelTreeItem
} from "../interfaceAndType/typesHotel";

export default defineComponent({
  name: "HotelsSearchResult",
  components: {
    SubHeader,
    HotelsFilter,
    HotelsSearchCard,
    HotelsSearchModal,
    OrderCreateModal
  },
  setup() {
    const store = useStore();
    type DS = { show: boolean };
    const display = ref<boolean>(false);
    const accessorSelect = ref<number[]>([]); //выбранные поставщики
    const perPage = DEFAULT_PERPAGE;
    const toggleDisplay = () => {
      display.value = !display.value;
    };
    const hotels = computed(() => {
      return store.getters.getHotels;
    });
    const activeAccessKey = ref(null);
    const hotelItem = computed(() => {
      if (activeAccessKey.value && hotels.value)
        return hotels.value.find(
          item => item.accessKey == activeAccessKey.value
        );
      else return null;
    });
    const openModalPrice = key => {
      display.value = true;
      activeAccessKey.value = key;
    };
    const filteredVariants = ref<Array<HotelTreeItem>>(
      hotels.value ? hotels.value : []
    );
    const paginatedVariants = ref<Array<HotelTreeItem>>(
      filteredVariants.value && filteredVariants.value.length > 0
        ? filteredVariants.value.slice(0, perPage)
        : []
    );
    const paginatorFirst = ref<number>(0);
    const onPage = event => {
      if (filteredVariants.value) {
        paginatedVariants.value = filteredVariants.value.slice(
          event.first,
          event.first + perPage
        );
        paginatorFirst.value = event.first;
      }
    };
    const sortingDest = ref<string>("ASC");

    const sortByPrice = () => {
      if (sortingDest.value === "DESC" && hotels.value) {
        paginatedVariants.value = hotels.value.sort(
          (a: HotelTreeItem, b: HotelTreeItem) => {
            return a.comparisionPrice - b.comparisionPrice;
          }
        );
        sortingDest.value = "ASC";
      } else if (sortingDest.value === "ASC" && hotels.value) {
        paginatedVariants.value = hotels.value.sort(
          (a: HotelTreeItem, b: HotelTreeItem) => {
            return b.comparisionPrice - a.comparisionPrice;
          }
        );
        sortingDest.value = "DESC";
      }
      onPage({ first: 0 });
      return 0;
    };
    const priceRange = computed(() => {
      if (hotels.value && hotels.value.length > 0) {
        const minPrice = Math.min(
          ...hotels.value.map(item =>
            item.comparisionPrice ? item.comparisionPrice : 0
          )
        );
        const maxPrice = Math.max(
          ...hotels.value.map(item =>
            item.comparisionPrice ? item.comparisionPrice : 0
          )
        );
        return [minPrice, maxPrice];
      }
      return [0, 0];
    });
    const stars = ref<number>(0);
    const filterByStars = value => {
      stars.value = value;
    };
    const applyFilterStars = (stars, variant: HotelTreeItem) => {
      let res = false;
      if (variant.stars >= stars) res = true;
      return res;
    };
    const price = ref<Array<number>>([0, 0]);
    const filterByPrice = (min, max) => {
      price.value[0] = min;
      price.value[1] = max;
    };
    const applyFilterPrice = (min, max, variant: HotelTreeItem) => {
      let res = false;
      if (min == 0 && max == 0) res = true;
      if (variant.comparisionPrice >= min && variant.comparisionPrice <= max)
        res = true;
      return res;
    };
    const services = ref<Array<string>>([]);
    const filterByServices = servicesIn => {
      services.value = servicesIn;
    };
    const applyFilterServices = (
      services: Array<string>,
      variant: HotelTreeItem
    ) => {
      let res = true;
      if (services.length > 0) {
        services.forEach(key => {
          if (!variant[key]) res = false;
        });
      }
      return res;
    };

    const applyFilterAccessor = (
      accessorSelectIn: Array<number>,
      variant: HotelTreeItem
    ) => {
      let res = true;
      if (accessorSelectIn.length > 0) {
        if (variant.accessors) {
          res = false;
          variant.accessors.forEach(acc => {
            const find = accessorSelectIn.find(el => el == acc.idSystem);
            if (find) {
              res = true;
            }
          });
        } else {
          res = false;
        }
      }
      return res;
    };

    const applyAllFilters = () => {
      const res: Array<HotelTreeItem> = [];
      hotels!.value!.forEach(variant => {
        let result = false;
        result =
          applyFilterStars(stars.value, variant) &&
          applyFilterPrice(price.value[0], price.value[1], variant) &&
          applyFilterServices(services.value, variant) &&
          applyFilterAccessor(accessorSelect.value, variant);
        if (result) {
          res.push(variant);
        }
      });
      filteredVariants.value = res;
      onPage({ first: 0 });
    };

    const orderTypeId = computed(() => {
      return store.getters.getOrderTypeId(ORDER_TYPES.HOTEL);
    });

    const showOrderModal = ref<boolean>(false);

    const openOrderModal = () => {
      showOrderModal.value = true;
    };

    const closeOrderModal = (val = false) => {
      showOrderModal.value = val;
    };

    const passengersCount = computed(() => {
      if (store.state.hotels.searchReq) {
        return {
          adults: store.state.hotels.searchReq.adults,
          children: store.state.hotels.searchReq.children
        };
      }
    });

    watch(
      () => hotels.value,
      () => {
        filteredVariants.value = hotels.value ? hotels.value : [];
        paginatedVariants.value = filteredVariants.value
          ? filteredVariants.value.slice(0, perPage)
          : [];
      }
    );

    /**
     * Список поставшиков
     */
    const accessors = computed(() => {
      let res: BaseHotelAccesor[] = [];
      if (hotels.value && hotels.value.length > 0) {
        hotels.value.forEach(hotel => {
          hotel.accessors?.forEach(accessor => {
            res.push(accessor);
          });
        });
        if (res) {
          res = res.filter((accessor, index) => {
            const findIndex = res.findIndex(
              _accessor => _accessor.idSystem == accessor.idSystem
            );
            if (findIndex == index) {
              return true;
            }
            return false;
          });
        }
        return res;
      }
      return [];
    });

    const filterByAccesor = (idSystem: number) => {
      const find = accessorSelect.value.find(el => el == idSystem);
      if (find) {
        accessorSelect.value = accessorSelect.value.filter(
          el => el != idSystem
        );
      } else {
        accessorSelect.value.push(idSystem);
      }
    };

    const isSelected = key => {
      let res = false;
      if (filteredVariants.value) {
        const selected = Object.keys(store.state.hotels.selectedRooms).includes(key);
        if (selected) res = true;
      }
      return res;
    };

    return {
      display,
      toggleDisplay,
      hotels,
      perPage,
      onPage,
      filteredVariants,
      paginatedVariants,
      paginatorFirst,
      sortByPrice,
      priceRange,
      stars,
      filterByStars,
      filterByPrice,
      filterByServices,
      applyAllFilters,
      openModalPrice,
      activeAccessKey,
      hotelItem,
      orderTypeId,
      showOrderModal,
      openOrderModal,
      closeOrderModal,
      passengersCount,
      accessors,
      filterByAccesor,
      accessorSelect,
      isSelected
    };
  }
});
