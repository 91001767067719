import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "railRoute cursorPointer" }
const _hoisted_2 = { class: "railRoute__main" }
const _hoisted_3 = { class: "railRoute__main__wrap" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_rail_date = _resolveComponent("rail-date")
  const _component_rail_route_line = _resolveComponent("rail-route-line")
  const _component_rail_info_small = _resolveComponent("rail-info-small")
  const _component_SeparateLine = _resolveComponent("SeparateLine")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_rail_date, { item: _ctx.item }, null, 8, ["item"]),
        _createVNode(_component_rail_route_line, { item: _ctx.item }, null, 8, ["item"]),
        _createVNode(_component_rail_date, {
          item: _ctx.item,
          isLeft: false
        }, null, 8, ["item"])
      ]),
      _createVNode(_component_rail_info_small, { item: _ctx.item }, null, 8, ["item"])
    ]),
    _createVNode(_component_SeparateLine)
  ]))
}