
import { computed, defineComponent } from "vue";
import { ORDER_TYPES } from "../../constant";
import { FilterActiveOrder } from "../../interfaceAndType/typesTrips";
import { useStore } from "../../store/modules";
import { MutationTypes } from "../../store/modules/trips/MutationsType";
import { useRoute, useRouter } from "vue-router";
import BtnLeftFilter from "../trips/openTripPage/BtnLeftFilter.vue";
import SideBarTikets from "@/components/orderOpen/SideBarTikets.vue";
export default defineComponent({
  name: "LeftFilterAcceptTrip",
  components: { BtnLeftFilter, SideBarTikets },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const id = route.params.id;

    const filterData = computed<FilterActiveOrder>(
      () => store.getters.getFilterDataFromAccept
    );
    const activeTypeCode = computed(() => store.state.trips.activeTypeCode);
    const orderTypes = ORDER_TYPES;
    const isActive = (code: string) => {
      let res = false;
      if (activeTypeCode.value == code) {
        res = true;
      }
      return res;
    };
    const setActive = (code: string) => {
     store.commit(MutationTypes.SET_ACTIVE_FILTER_TYPE, { code });
    };
    return {
      filterData,
      activeTypeCode,
      orderTypes,
      isActive,
      setActive,
      id
    };
  }
});
