<template>
	<div style="width: 100%; background-color: white; padding: 10px">
		<div>
			<svg width="100%" height="100%" viewBox="0 0 2940 240" xmlns="http://www.w3.org/2000/svg">
				<g transform="translate(0,0)" class="wall">
					<line x1="0" y1="5" x2="2940" y2="5"></line>
					<line x1="0" y1="235" x2="2940" y2="235"></line>
				</g>

				<g transform="translate(0,0)" class="wall">
					<line x1="5" y1="0" x2="5" y2="120"></line>
					<line x1="5" y1="180" x2="5" y2="235"></line>
					<line x1="2935" y1="0" x2="2935" y2="120"></line>
					<line x1="2935" y1="180" x2="2935" y2="235"></line>
				</g>

				<g transform="translate(20,20)">
					<g transform="translate(0,0)" class="wc" title="Туалет">
						<rect x="0" y="0" width="100" height="100"></rect>
					</g>

					<g transform="translate(2800,0)" class="wc" title="Туалет">
						<rect x="0" y="0" width="100" height="100"></rect>
					</g>

					<g v-for="i in 9" :key="i" :transform="'translate(' + (i - 1) * 300 + ',0)'">
						<g :id="'seat' + seatNumber(i, 1)" transform="translate(100,0)" @click="select(seatNumber(i, 1))" class="seat-container">
							<rect x="0" y="0" width="100" height="100" :class="[seatClass(seatNumber(i, 1)), isHighlight(seatNumber(i, 1))]">
                <title>{{seatTooltip(seatNumber(i, 1))}}</title>
              </rect>
							<text x="50" y="60" text-anchor="middle" class="seat-number">
								{{ seatNumber(i, 1) }}
							</text>
						</g>

						<g transform="translate(200,0)" class="table" title="Стол">
							<path class="s-table" d="M10 0 v40 c0 20 20 40 40 40 c20 0 40 -20 40 -40 v-40 z"></path>
						</g>

						<g :id="'seat' + seatNumber(i, 2)" transform="translate(300,0)" @click="select(seatNumber(i, 2))" class="seat-container">
							<rect x="0" y="0" width="100" height="100" :class="[seatClass(seatNumber(i, 2)), isHighlight(seatNumber(i, 2))]">
                 <title>{{seatTooltip(seatNumber(i, 2))}}</title>
              </rect>
							<text x="50" y="60" text-anchor="middle" class="seat-number">
								{{ seatNumber(i, 2) }}
							</text>
						</g>

						<line x1="100" y1="0" x2="100" y2="100" class="wall"></line>
						<line x1="400" y1="0" x2="400" y2="100" class="wall"></line>
					</g>
				</g>
			</svg>
		</div>
	</div>
</template>

<script>
export default {
	name: "SVSeats",

	components: {},

props: {
    ck: { default: null, type: Object, required: true },
    cv: { default: null, type: Object, required: true },
    value: { 
      default: null, 
      type: Array, 
      required: true 
    },
    selectSeat: {
      type: Function
    }
  },

	computed: {
		free() {
			const seats = this.cv && this.cv.numberOfFreePlace ? this.cv.numberOfFreePlace.split(", ") : [];
			return seats.map((seat) => {
				let number = seat;
				let sign = "";

				const last = seat[seat.length - 1];
				if (["Ж", "М", "С", "Ц"].includes(last)) {
					sign = last;
					number = number.substring(0, number.indexOf(sign));
				}

				return {
					number: parseInt(number),
					sign
				};
			});
		}
	},

	methods: {
		isFree(number) {
			return this.free.filter((seat) => seat.number === number).length > 0;
		},

    isHighlight(number) {
      return this.isSelected(number) ? "highlight" : "";
    },

    isSelected(number) {
      return this.value && this.value.includes(number);
    },

		price() {
			return this.ck.costTicket;
		},

		seatNumber(coupeNumber, seat) {
			return coupeNumber * 2 - (2 - seat);
		},

		seatClass(number) {
			if (this.value && this.value.includes(number)) {
				return "selected";
			}

			return this.isFree(number) ? "free" : "taken";
		},

		seatTooltip(number) {
			const filtered = this.free.filter((seat) => seat.number === number);
			if (filtered.length === 0) {
				return "Занято";
			}
			const seat = filtered[0];
			let res = "Место " + seat.number + "\n";
			if (seat.sign) {
				res += "<br>" + seat.sign;
			}
			return (res += this.price() + " RUB");
		},

		select(number) {
			if (!this.isFree(number)) {
				return;
			}

			const selected = this.value || [];
			if (selected.includes(number)) {
			  const index = selected.indexOf(number);
        selected.splice(index, 1);
      } else {
        selected.push(number);
      }
      this.selectSeat(selected);
    }
	}
};
</script>

<style scoped lang="scss">

.seat-container {
  &:hover {
    .free {
      fill:#65C1F5;
    }
  }
}

.free {
  fill: #bbffbb;
  cursor: pointer;
}

.seat-number {
  fill: #393939;
  font: bold 40px sans-serif;
}

.selected {
  fill: #F78F1E;
}

.table {
  fill: burlywood;
}

.table:hover {
  cursor: help;
  fill: darkgrey;
}

.taken {
  fill: #FFCDD2;
}

.wall {
  stroke: #979797;
  stroke-width: 10px;
}

.window {
  stroke: #b6d0d4;
  stroke-width: 10px;
}

.wc {
  rect {
    opacity: 0;
  }
  text {
    font-size: 40px;
    fill: darkgreen;
  }
}

.wc:hover {
  cursor: help;
}
</style>

