import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "aviaCard__main aviaCard__main--small hoverShadow mb-8" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_rail_ticket_header = _resolveComponent("rail-ticket-header")
  const _component_rail_route_small = _resolveComponent("rail-route-small")
  const _component_rail_bottom_small = _resolveComponent("rail-bottom-small")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_rail_ticket_header, { item: _ctx.item }, null, 8, ["item"]),
    _createVNode(_component_rail_route_small, { item: _ctx.item }, null, 8, ["item"]),
    _createVNode(_component_rail_bottom_small, {
      item: _ctx.item,
      unselect: _ctx.unselect,
      isModeOffer: _ctx.isModeOffer,
      onSelectOffer: _ctx.selectOffer,
      onUnselectOffer: _cache[1] || (_cache[1] = $event => (_ctx.$emit('unselect-offer', null))),
      isCansel: _ctx.isCansel,
      hasCansel: _ctx.hasCansel,
      hasControl: _ctx.hasControl
    }, null, 8, ["item", "unselect", "isModeOffer", "onSelectOffer", "isCansel", "hasCansel", "hasControl"])
  ]))
}