
import { defineComponent, PropType, toRefs } from "vue";
import { BusinessTripType } from "../../interfaceAndType/typesTrips";

export default defineComponent({
  name: "TripCard",
  props: {
    item: {
      type: Object as PropType<BusinessTripType>,
      required: true
    }
  },
  setup(props) {
    const { item } = toRefs(props);

    return {};
  }
});
