import {
  ExtendedHotelInfo,
  HotelSearchRequest
} from "@/interfaceAndType/typesHotel";
import { State } from "./contract";
import {
  HotelTreeWithItem,
  ExtendedRate
} from "../../../interfaceAndType/typesHotel";

// mutations enums
export enum MutationTypes {
  SAVE_HOTEL_DATA = "SAVE_HOTEL_DATA",
  SET_HOTEL_DATA = "SET_HOTEL_DATA",
  SET_HOTEL_PRICE = "SET_HOTEL_PRICE",
  SELECT_ROOM = "SELECT_ROOM",
  UNSELECT_ROOM = "UNSELECT_ROOM"
}
// Mutation contracts
export type Mutations<S = State> = {
  [MutationTypes.SAVE_HOTEL_DATA](
    state: S,
    payload: { data: HotelSearchRequest }
  ): void;
  [MutationTypes.SET_HOTEL_DATA](
    state: S,
    payload: { data: HotelTreeWithItem }
  ): void;
  [MutationTypes.SET_HOTEL_PRICE](
    state: S,
    payload: { data: ExtendedHotelInfo; key: string }
  ): void;
  [MutationTypes.SELECT_ROOM](
    state: S,
    payload: { data: ExtendedHotelInfo; key: string }
  ): void;
  [MutationTypes.UNSELECT_ROOM](state: S, payload: { key: string }): void;
};
