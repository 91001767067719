import {
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions
} from "vuex";

import { State as RootState } from "../index";
import { GlobalMutations as Mutations } from "../allMutationsType";
import { DropDownEntity } from "../../../interfaceAndType/typesShared";
import {
  RailwaySearchRequest,
  TrainListResponse,
  NTrainListReference,
  RailwayPricingRequest
} from "../../../interfaceAndType/typesRail";

export type State = {
  trains: NTrainListReference[] | null;
  searchReq: RailwaySearchRequest | null;
  pricingCache: { [key: string]: NTrainListReference };
  trainWithSeats: { [key: string]: NTrainListReference };
  passengersCount: number;
};

// Action enums
export enum ActionTypes {
  SEARCH_STATION = "SEARCH_STATION",
  SEARCH_RAIL = "SEARCH_RAIL",
  GET_PRICING = "GET_PRICING"
}

// Actions contracts
export interface Actions {
  [ActionTypes.SEARCH_STATION](
    { commit }: AugmentedActionContext,
    payload: { station: string }
  ): Promise<Array<DropDownEntity>>;
  [ActionTypes.SEARCH_RAIL](
    { commit }: AugmentedActionContext,
    payload: { searchData: RailwaySearchRequest }
  ): Promise<Array<TrainListResponse>>;
  [ActionTypes.GET_PRICING](
    { commit }: AugmentedActionContext,
    payload: { reqObject: RailwayPricingRequest }
  ): Promise<TrainListResponse | null>;
}

// getters types
export type Getters = {
  getSelectedTrains(state: State): Array<NTrainListReference> | null;
  getPassengersCount(
    state: State
  ): {
    adults: number;
    children: number;
    infants: number;
  };
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  "commit" | "getters" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
