
import { ExtendedHotelInfo } from "@/interfaceAndType/typesHotel";
import { computed, defineComponent, PropType, ref, toRefs } from "vue";

export default defineComponent({
  name: "HotelImage",
  props: {
    item: {
      type: Object as PropType<ExtendedHotelInfo>,
      required: true
    }
  },
  setup(props) {
    const { item } = toRefs(props);
    const imgUrls = computed<string[]>(() => {
      if (item.value.images && item.value.images.length > 0) {
        return item.value.images;
      }
      return [];
    });

    const nowImg = ref(0);
    const nextImg = () => {
      if (imgUrls.value.length - 1 > nowImg.value) {
        nowImg.value = nowImg.value + 1;
      } else {
        nowImg.value = 0;
      }
    };
    const urlImg = computed(() => {
      if (imgUrls.value.length > 0 && imgUrls.value[nowImg.value]) {
        return imgUrls.value[nowImg.value];
      }
      return "";
    });
    return { nextImg, urlImg, nowImg, imgUrls };
  }
});
