
import { defineComponent, PropType, ref } from "vue";
import moment from "moment";
import { ExtendedRate } from "../../../interfaceAndType/typesHotel";

export default defineComponent({
  name: "HotelsModalCard",
  props: {
    room: {
      type: Object as PropType<ExtendedRate>
    },
    select: {
      type: Function
    },
    unselect: {
      type: Function
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const active = ref<number>();

    const open = () => {
      active.value === 0 ? (active.value = 1) : (active.value = 0);
    };
    const parseDate = (date, format) => {
      return moment(date).format(format);
    };

    return { active, open, parseDate };
  }
});
