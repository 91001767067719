import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from "vue-router";
import Login from "../views/Login.vue";
import { path } from "./path";
import MainLayout from "../layout/MainLayout.vue";

import AviaSearch from "../views/AviaSearch.vue";
import AllTrips from "../views/AllTrips.vue";

import TrainSearch from "../views/TrainSearch.vue";
import HotelSearch from "../views/HotelsSearch.vue";
import ServicesSearch from "../views/ServiceSearch.vue";

import Demo from "../views/Demo.vue";
import Trip from "../views/Trip.vue";
import PageNF from "../views/PageNF.vue";
import TripOrder from "../views/TripOrder.vue";

import HotelsSearchResult from "../views/HotelsSearchResult.vue";
import AviaSearchResult from "../views/AviaSearchResult.vue";
import RailSearchResult from "../views/RailSearchResult.vue";

import { ifAuthenticated } from "../middleware/ifAuthenticated";
import OffersTripPage from "../views/tripPage/OffersTripPage.vue";
import NoSelectOffersTripPage from "../views/tripPage/NoSelectOffersTripPage.vue"
import NoSelectOfferPage from "../views/tripPage/NoSelectOfferPage.vue"
import OpenOffer  from "../views/tripPage/OpenOffer.vue"
import AcceptTrip from "../views/AcceptTrip.vue"
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: MainLayout,
    redirect: path.trips,
    children: [
      {
        path: "/acceptrip",
        component: AcceptTrip,
        // beforeEnter: ifAuthenticated,
      },
      {
        path: path.login,
        component: Login,
      },
      {
        path: path.aviaSearch,
        component: AviaSearch,
        beforeEnter: ifAuthenticated,
      },
      {
        path: path.trips,
        component: AllTrips,
        beforeEnter: ifAuthenticated,
      },

      {
        path: path.trip,
        component: Trip,
        beforeEnter: ifAuthenticated,
        children: [
          {
            path: ``,
            name: "NoSelectType",
            component: NoSelectOffersTripPage,
            beforeEnter: ifAuthenticated,
          },
          {
            path: `type/:type`,
            component: OffersTripPage,
            beforeEnter: ifAuthenticated,
            // redirect: "/",
            children: [
              {
                path: ``,
                name: "NoSelectOffer",
                component: NoSelectOfferPage,
                beforeEnter: ifAuthenticated,
              },
              {
                path: `offer/:orderId`,
                name: "OpenOffer",
                component: OpenOffer,
                beforeEnter: ifAuthenticated,
              },
            ],
          },
        ],
      },

      // {
      //   path: path.trip + path.order,
      //   component: TripOrder,
      //   beforeEnter: ifAuthenticated,
      // },
      {
        path: path.railSearch,
        component: TrainSearch,
        beforeEnter: ifAuthenticated,
      },
      {
        path: path.hotelSearch,
        component: HotelSearch,
        beforeEnter: ifAuthenticated,
      },
      {
        path: path.service,
        component: ServicesSearch,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/demo",
        component: Demo,
        beforeEnter: ifAuthenticated,
      },
      {
        path: "/404",
        component: PageNF,
      },

      {
        path: path.hotelSearchResult,
        component: HotelsSearchResult,
        beforeEnter: ifAuthenticated,
      },
      {
        path: path.aviaSearchResult,
        component: AviaSearchResult,
        beforeEnter: ifAuthenticated,
      },
      {
        path: path.railSearchResult,
        component: RailSearchResult,
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  
  {
    path: "/about",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  // linkActiveClass: "router-link-active"
});

export default router;
