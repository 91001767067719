
import { computed, defineComponent, PropType, toRefs } from "vue";
import { Itinerary } from "../../../interfaceAndType/typesSearh";

export default defineComponent({
  name: "AviaTarifPlan",
  props: {
    plan: Object,
    pricePlane: Object as PropType<Itinerary>
  },
  setup(props) {
    const { pricePlane } = toRefs(props);
    const title = computed(() => {
      const priceNow = pricePlane ? pricePlane.value : null;
      return priceNow && priceNow.fareFamily && priceNow.fareFamily.name
        ? priceNow.fareFamily.name
        : "н/д";
    });
    const description = computed(() => {
      const priceNow = pricePlane ? pricePlane.value : null;

      const desc =
        priceNow && priceNow.fareFamily ? priceNow.fareFamily.services : [];
      return desc;
    });
    const equivTotal = computed(() => {
      const priceNow = pricePlane ? pricePlane.value : null;
      const equivTotalNow =
        priceNow && priceNow.equivTotal ? priceNow.equivTotal : null;
      return equivTotalNow;
    });

    return {
      title,
      description,
      equivTotal
    };
  }
});
