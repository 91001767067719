
import { computed, defineComponent, PropType, ref, toRefs } from "vue";
import { CKTrainListReference } from "../../../interfaceAndType/typesRail";
import CoupeSeats from "./CoupeSeats.vue";
import PlatscartSeats from "./PlatscartSeats.vue";
import SeatingSeats from "./SeatingSeats.vue";
import SVSeats from "./SVSeats.vue";

export default defineComponent({
  components: { CoupeSeats, SVSeats, PlatscartSeats, SeatingSeats },
  name: "RailSeatsSelect",
  props: {
    selectedCarriage: {
      type: Object as PropType<CKTrainListReference>,
    },
    selectSeat: {
      type: Function,
    },
    save: {
      type: Function,
    },
  },
  setup(props) {
    const { selectedCarriage } = toRefs(props);
    const totalCost = computed(() => {
      if (
        selectedCarriage &&
        selectedCarriage.value &&
        selectedCarriage.value.costTicket
      ) {
        return selectedCarriage.value.cvTrainListReference.selected
          ? selectedCarriage.value.cvTrainListReference.selected.split(",")
              .length * +selectedCarriage.value.costTicket
          : 0;
      }
    });
    const selectedSeats = ref<Array<object>>([]);
    const linen = computed(() => {
      if (
        selectedCarriage &&
        selectedCarriage.value
      ) {
        return selectedCarriage.value.cvTrainListReference.servicescarListEx.isPaidLinenProvided
      }
    });
    return { selectedSeats, linen, totalCost };
  },
});
