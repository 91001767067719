<template>
  <div class="login-footer">
    <partners-list />
    <p class="login-footer__text">
      Mobile BOOKING предоставляет пользователям возможность сравнивать и
      покупать авиабилеты более чем 800 авиакомпаний по всему миру, в том числе
      и авиакомпаний-лоукостеров, выполняющих рейсы по всему миру, ж/д билеты,
      заказывать трансферы и бронировать жилье, а также заказывать
      дополнительные у Вы можете выбрать нужный вариант и отфильтровать
      предложения в удобном виде. Содержимое сайта является объектом авторских
      прав. ©2019 ООО "Вайт Тревел". Все права защищены. Запрещается копировать,
      использовать, распространять, модифицировать любое содержимое этого сайта.
      OneTwoTrip не несёт ответственности за информацию, представленную на
      внешних сайтах.
    </p>
  </div>
</template>
<script>
import PartnersList from "../../shared/PartnersList";
export default {
  name: "LoginFooter",
  components: {
    PartnersList
  }
};
</script>
