import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "search-page search-page--train" }
const _hoisted_2 = { class: "container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_rail_search_form = _resolveComponent("rail-search-form")
  const _component_search_footer = _resolveComponent("search-footer")
  const _component_app_section = _resolveComponent("app-section")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_app_section, {
        title: 'Поиск ж/д билетов',
        descr: 'Введите нужные парметры для поиска билетов'
      }, {
        content: _withCtx(() => [
          _createVNode(_component_rail_search_form),
          _createVNode(_component_search_footer)
        ]),
        _: 1
      })
    ])
  ]))
}