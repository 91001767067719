import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h100" }
const _hoisted_2 = { class: "mainlayout" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_cookie_bar = _resolveComponent("cookie-bar")
  const _component_app_header = _resolveComponent("app-header")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_cookie_bar),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_app_header),
      _createVNode(_component_router_view)
    ])
  ]))
}