<template>
  <ul class="partners-list">
    <li class="partners-list__item partners-list__item--mc"></li>
    <li class="partners-list__item partners-list__item--visa"></li>
    <li class="partners-list__item partners-list__item--mir"></li>
    <li class="partners-list__item partners-list__item--iata"></li>
    <li class="partners-list__item partners-list__item--rzd"></li>
    <li class="partners-list__item partners-list__item--tripadvisor"></li>
    <li class="partners-list__item partners-list__item--pci"></li>
    <li class="partners-list__item partners-list__item--http"></li>
  </ul>
</template>
<script>
export default {
  name: "partnersList"
};
</script>
