import { MutationTree } from "vuex";
import { Mutations, MutationTypes } from "./MutationsType";
import { State } from "./contract";

// Define mutations
export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_TRIPS](state: State, { trips, pageCount }) {
    state.trips = trips;
    state.pageCount = pageCount;
  },
  [MutationTypes.SET_TRIP_TICKET_CACHE](state: State, { tripId, tickets }) {
    state.tripTicketsCach[tripId] = tickets;
  },

  [MutationTypes.SET_TRIP_ORDER_OFFERS_CACHE](
    state: State,
    { orderId, orderData }
  ) {
    state.orderTicketsCach[orderId] = orderData;
  },

  [MutationTypes.CLEAR_CACHE](state: State) {
    state.tripTicketsCach = {};
    state.orderTicketsCach = {};
  },
  /**
   * Полный загруженный трип вся информация о заказе
   * @param state
   * @param param1
   */
  [MutationTypes.SET_ACTIVE_ORDER](state: State, { data }) {
    state.activeOrderIsLoad = true;
    state.activeOrder = data;
  },
  [MutationTypes.CLEAR_ACTIVE_ORDER](state: State) {
    state.activeOrderIsLoad = false;
    state.activeOrder = [];
  },
  [MutationTypes.SET_ACTIVE_FILTER_TYPE](state: State, { code }) {
    state.activeTypeCode = code;
  },
  [MutationTypes.SET_ACTIVE_FILTER_OFFER](state: State, { key }) {
    state.activeOfferKey = key;
  },
  [MutationTypes.LOAD_ACCEPT_TRIP](state: State, { data }) {
    state.acceptTrip = data;
  }
  
};
