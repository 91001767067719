import {
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions
} from "vuex";

import { State as RootState } from "../index";
import { GlobalMutations as Mutations } from "../allMutationsType";
import {
  AlternativesContainer,
  AviaPricingType,
  AviaSearchReqType,
  FareDrivenFlightSearchResponse,
  Leg,
  MultiItinerary
} from "../../../interfaceAndType/typesSearh";

// Аэропорт тип
export type AirPortType = {
  entityId: number;
  languageId: number;
  entityCode: string;
  entityName: string;
};

// Declare state
export type State = {
  searhcData: FareDrivenFlightSearchResponse | null;
  hasSearch: boolean;
  resultSearch: boolean;
  pricingData: Array<any>;
  serachReq: AviaSearchReqType | null;
  pricingCach: { [key: string]: AlternativesContainer };
  selectedAviaTicket: Array<string>;
};

// Action enums
export enum ActionTypes {
  SEARH_AIRPORT = "SEARH_AIRPORT",
  SEARH_AVIA = "SEARCH_AVIA",
  LOAD_PRICING = "LOAD_PRICING",
  GET_FARES = "GET_FARES"
}

// Actions contracts
export interface Actions {
  [ActionTypes.SEARH_AIRPORT](
    { commit }: AugmentedActionContext,
    payload: { searhAirport: string }
  ): Promise<Array<AirPortType>>;
  [ActionTypes.SEARH_AVIA](
    { commit }: AugmentedActionContext,
    payload: { searhData: AviaSearchReqType }
  ): Promise<boolean>;
  [ActionTypes.LOAD_PRICING](
    { commit, state }: AugmentedActionContext,
    payload: { keytiket: string }
  ): Promise<boolean>;
  [ActionTypes.GET_FARES]({ commit, state }: AugmentedActionContext);
}

// getters types
export type Getters = {
  getNameAirport(state: State): (codeAiroport: string) => string;
  getAviaVariants(state: State): Array<MultiItinerary>;
  getTarifTiket(
    state: State
  ): (keytiket: string) => AlternativesContainer | null;
  getVariantByKey(state: State): (keytiket: string) => MultiItinerary | null;
  getSelectedTickets(state: State);
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  "commit" | "getters" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
