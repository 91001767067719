import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "transferCard__body" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_free_service_date = _resolveComponent("free-service-date")
  const _component_free_service_infoblock = _resolveComponent("free-service-infoblock")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_free_service_date, { item: _ctx.item }, null, 8, ["item"]),
    _createVNode(_component_free_service_date, {
      item: _ctx.item,
      isLeft: false
    }, null, 8, ["item"]),
    _createVNode(_component_free_service_infoblock, { item: _ctx.item }, null, 8, ["item"])
  ]))
}