
import SubHeader from "@/components/shared/SubHeader.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { SubHeader },
  setup() {
    return {};
  }
});
