
import LeftFilterAcceptTrip from "@/components/acceptPage/LeftFilterAcceptTrip.vue";
import AviaTiket from "@/components/tikets/avia/AviaTikets.vue";
import HotelsCardComplex from "@/components/tikets/hotel/HotelsCardComplex.vue";
import RailTiketSmall from "@/components/tikets/rail/RailTiketSmall.vue";
import FreeCard from "@/components/tikets/services/FreeCard.vue";
import TransferCard from "@/components/tikets/services/TransferCard.vue";
import { STATUS_ORDER } from "@/constant";
import useFilterMultiTiket from "@/hocks/useFilterMultiTiket";
import { OfferListType, CheckOffer } from "@/interfaceAndType/typesTrips";
import { useStore } from "@/store/modules";
import { useToast } from "primevue/usetoast";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import SubHeader from "../components/shared/SubHeader.vue";
import { ORDER_TYPES } from "../constant";
import { ActionTypes } from "../store/modules/trips/contract";
export default defineComponent({
  components: {
    SubHeader,
    AviaTiket,
    HotelsCardComplex,
    FreeCard,
    RailTiketSmall,
    TransferCard,
    LeftFilterAcceptTrip,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { query } = route;
    const { code = null } = query;
    const toast = useToast();
    const getData = async (code: string) => {
      await store.dispatch(ActionTypes.LOAD_ACCEPT_TRIP, { code });
    };
    if (code) {
      getData(code as string);
    }

    const fullTrip = computed(() => {
      return store.state.trips.acceptTrip;
    });

    const {
      isAvia,
      isHotel,
      isService,
      isRail,
      isFree,
    } = useFilterMultiTiket();

    const orderTypes = ORDER_TYPES;

    const isSelectedOfferType = (type: string) => {
      if (store.state.trips.activeTypeCode) {
        if (type == store.state.trips.activeTypeCode) {
          return true;
        }
        return false;
      }
      return true;
    };

    const groupByOffer = computed<
      Array<{
        orderId: number;
        data: Array<{ offerData: OfferListType; tiket: any }>;
        noChoice: boolean;
      }>
    >(() => {
      if (fullTrip.value) {
        const groupRes: any[] = [];
        fullTrip.value.orderList.forEach((_item) => {
          const res: any[] = [];
          if (_item.offerDTOList) {
            _item.offerDTOList.forEach((item) => {
              if (item.data) {
                const converData = JSON.parse(item.data);
                res.push({ offerData: item, tiket: converData });
              }
            });
          }

          groupRes.push({
            orderId: _item.orderId,
            data: res,
            noChoice:
              _item.statusCode == STATUS_ORDER.Created ||
              _item.statusCode == STATUS_ORDER.InWork,
          });
        });
        return groupRes;
      }
      return [];
    });
    const selectOfferVar = ref({});
    const selectOffer = (
      tiket: any,
      offerData: OfferListType,
      orderId: number
    ) => {
      if (selectOfferVar.value[orderId]) {
        selectOfferVar.value[orderId] = null;
      } else {
        selectOfferVar.value[orderId] = offerData.offerId;
      }
    };
    const isSelect = (
      tiket: any,
      offerData: OfferListType,
      orderId: number
    ) => {
      if (
        (selectOfferVar.value[orderId] &&
          selectOfferVar.value[orderId] == offerData.offerId) ||
        !selectOfferVar.value[orderId]
      ) {
        return true;
      } else {
        return false;
      }
    };
    const isSelectForce = (offerData: OfferListType, orderId: number) => {
      if (
        selectOfferVar.value[orderId] &&
        selectOfferVar.value[orderId] == offerData.offerId
      ) {
        return true;
      } else {
        return false;
      }
    };
    const userIsSelect = ref(false);
    const sendData = async () => {
      const nowOfferList = Object.values(selectOfferVar.value);
      if (nowOfferList && nowOfferList.length > 0 && code && fullTrip.value) {
        const convertData = nowOfferList.filter((offerId) => offerId);

        const CheckOffer: CheckOffer = {
          tripId: fullTrip.value?.businessTripId,
          code: code as string,
          offerList: convertData as number[],
        };
        const res = await store.dispatch(ActionTypes.ACCEPT_TRIP, {
          CheckOffer,
        });
        if (res) {
          toast.add({
            severity: "success",
            summary: "Успешно",
            detail: "Выбор принят",
            life: 3000,
          });
          userIsSelect.value = true;
        }
      }
    };

    const checkType = (
      data: Array<{ offerData: OfferListType; tiket: any }>
    ) => {
      if (!store.state.trips.activeTypeCode) {
        return true;
      }
      if (isAvia(data[0].tiket) && isSelectedOfferType(orderTypes.AVIA)) {
        return true;
      }
      if (isHotel(data[0].tiket) && isSelectedOfferType(orderTypes.HOTEL)) {
        return true;
      }
      if (
        isService(data[0].tiket) &&
        isSelectedOfferType(orderTypes.TRANSFER)
      ) {
        return true;
      }
      if (isRail(data[0].tiket) && isSelectedOfferType(orderTypes.RAILWAY)) {
        return true;
      }
      if (isFree(data[0].tiket) && isSelectedOfferType(orderTypes.FREE)) {
        return true;
      }
      return false;
    };

    const isNotDenie = (offerData: OfferListType) => {
      if (offerData.statusCode == STATUS_ORDER.Denied) {
        return false;
      }
      return true;
    };
    const isDenie = (offerData: OfferListType) => {
      if (offerData.statusCode == STATUS_ORDER.Denied) {
        return true;
      }
      return false;
    };
    const isApplied = (offerData: OfferListType) => {
      if (offerData.statusCode == STATUS_ORDER.Applied) {
        return true;
      }
      return false;
    };

    return {
      code,
      fullTrip,
      isAvia,
      isHotel,
      isService,
      isRail,
      isFree,
      selectOffer,
      isSelect,
      groupByOffer,
      selectOfferVar,
      isSelectForce,
      sendData,
      userIsSelect,
      orderTypes,
      isSelectedOfferType,
      checkType,

      isApplied,
      isDenie,
      isNotDenie,
    };
  },
});
