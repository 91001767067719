
import SubHeader from "../components/shared/SubHeader.vue";
import RailFilters from "../components/filters/RailFilters.vue";
import RailTicket from "../components/tikets/rail/RailTicket.vue";
import RailSeatsModal from "../components/modals/RailSeatsModal.vue";

import { computed, defineComponent, ref } from "vue";
import { useStore } from "../store/modules/index";
import { DEFAULT_PERPAGE, ORDER_TYPES } from "../constant";
import { NTrainListReference } from '../interfaceAndType/typesRail';
import moment from "moment";
import OrderCreateModal from "../components/modals/OrderCreateModal.vue";

export default defineComponent({
  name: "RailSearchResult",
  components: {
    SubHeader,
    RailFilters,
    RailTicket,
    RailSeatsModal,
    OrderCreateModal
  },
  setup(props) {
    type DS = { show: boolean };
    const store = useStore();
    const display = ref<boolean>(false);
    const toggleDisplay = () => {
      display.value = !display.value;
    };
    const perPage = DEFAULT_PERPAGE;
    const trains = computed(() => {
      return store.state.rail.trains;
    });
    const filteredVariants = ref<Array<NTrainListReference>>(
      trains.value ? trains.value : []
    );
    const paginatedVariants = ref<Array<NTrainListReference>>(
      filteredVariants.value ? filteredVariants.value.slice(0, perPage) : []
    );
    const paginatorFirst = ref<number>(0);
    const onPage = event => {
      if (filteredVariants.value) {
        paginatedVariants.value = filteredVariants.value.slice(
          event.first,
          event.first + perPage
        );
        paginatorFirst.value = event.first;
      }
    };
    const sortingDest = ref<string>("ASC");
    const sortByDuration = () => {
      const getDiff = item => {
        const departure = moment(item.departureTime.value);
        const arrival = moment(item.arrivalTime.value);
        const diff = departure.diff(arrival);
        return diff;
      };
      if (sortingDest.value === "DESC" && trains.value) {
        paginatedVariants.value = trains.value.sort(
          (a: NTrainListReference, b: NTrainListReference) => {
            return getDiff(a) - getDiff(b);
          }
        );
        sortingDest.value = "ASC";
      } else if (sortingDest.value === "ASC" && trains.value) {
        paginatedVariants.value = trains.value.sort(
          (a: NTrainListReference, b: NTrainListReference) => {
            return getDiff(b) - getDiff(a);
          }
        );
        sortingDest.value = "DESC";
      }
      onPage({ first: 0 });
      return 0;
    };
    const trainTypes = ref<Array<string>>([]);
    const filterByTrainTypes = types => {
      trainTypes.value = types;
    };
    const applyFilterByTrainTypes = (
      types: Array<string>,
      variant: NTrainListReference
    ) => {
      let res = true;
      if (types.length > 0) {
        types.forEach(key => {
          if (variant.categoryTrain != key) res = false;
        });
      }
      return res;
    };
    const trainBrands = ref<string[]>([]);
    const filterByTrainBrands = brands => {
      trainBrands.value = brands;
    };
    const applyFilterByTrainBrands = (
      brands: Array<string>,
      variant: NTrainListReference
    ) => {
      let res = true;
      if (brands.length > 0) {
        brands.forEach(key => {
          if (variant.brandTrain != key) res = false;
        });
      }
      return res;
    };
    const departure = ref<Array<number>>([0, 23]);
    const filterByDeparture = (min, max) => {
      departure.value[0] = min;
      departure.value[1] = max;
    };
    const applyFilterByDeparture = (min, max, variant: NTrainListReference) => {
      let res = false;
      if (min == 0 && max == 23) res = true;
      if (variant.timeDeparture && parseInt(variant.timeDeparture.slice(0,2), 10) >= min && parseInt(variant.timeDeparture.slice(0,2), 10) <= max)
        res = true;
      return res;
    };
    const applyAllFilters = () => {
      const res: Array<NTrainListReference> = [];
      trains!.value!.forEach(variant => {
        let result = false;
        result =
          applyFilterByTrainTypes(trainTypes.value, variant) &&
          applyFilterByTrainBrands(trainBrands.value, variant) &&
          applyFilterByDeparture(departure.value[0], departure.value[1], variant);
        if (result) {
          res.push(variant);
        }
      });
      filteredVariants.value = res;
      onPage({ first: 0 });
    };
    const activeTrainNumber = ref(null);
    const trainItem = computed(() => {
      if (activeTrainNumber.value && trains.value)
        return trains.value.find(
          item => item.numberTrain == activeTrainNumber.value
        );
      else return null;
    });
    const openPricingModal = key => {
      display.value = true;
      activeTrainNumber.value = key;
    };

    const orderTypeId = computed(() => {
      return store.getters.getOrderTypeId(ORDER_TYPES.RAILWAY);
    });

    const showOrderModal = ref<boolean>(false);

    const openOrderModal = () => {
      showOrderModal.value = true;
    };

    const closeOrderModal = (val = false) => {
      showOrderModal.value = val;
    };

    const passengersCount = computed(() => {
      return store.getters.getPassengersCount;
    });

    const isSelected = key => {
      let res = false;
      if (filteredVariants.value) {
        const selected = Object.keys(store.state.rail.trainWithSeats).includes(key);
        if (selected) res = true;
      }
      return res;
    };

    return {
      store,
      display,
      toggleDisplay,
      perPage,
      trains,
      filteredVariants,
      paginatedVariants,
      paginatorFirst,
      onPage,
      sortByDuration,
      trainTypes,
      filterByTrainTypes,
      applyFilterByTrainTypes,
      applyAllFilters,
      trainBrands,
      filterByTrainBrands,
      applyFilterByTrainBrands,
      openPricingModal,
      trainItem,
      activeTrainNumber,
      orderTypeId,
      showOrderModal,
      openOrderModal,
      closeOrderModal,
      passengersCount,
      isSelected,
      filterByDeparture,
      applyFilterByDeparture,
      departure
    };
  }
});
