
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { transferOffer } from "../../interfaceAndType/typesServices";
import SelectorPerson from "../shared/personEdit/SelectorPerson.vue";
import { DropDownEntity } from "../../interfaceAndType/typesShared";
import CommonSelector from "../shared/Selectors/CommonSelector.vue";
import { TYPE_DROPLIST } from "../../constant";
import TransferRoute from "./TransferRoute.vue";
import getElement from "../../helpers/getElement";
import useCreatePassenger from "../../hocks/useCreatePassenger";
import useCreateTransferRoute from "../../hocks/useCreateTransferRoute";
import BaseInput from "../components/base/BaseInput.vue";
export default defineComponent({
  name: "TransferForm",
  components: {
    SelectorPerson,
    CommonSelector,
    TransferRoute,
    BaseInput
  },
  emits: ["open-order-modal"],
  props: {
    contractId: {
      type: Number,
      required: true
    },
    OrderTypeId: {
      required: true
    }
  },
  setup(props, { emit }) {
    const transfer = reactive<transferOffer>({
      isGroup: false, // - bool - Признак группы. По умолчанию false. Если указан true, то должен быть заполнен 1 пассажир.
      NumberOfPersons: 1, // - int - Количество человек в группе. Необязательный.
      PersonList: [], //  - List - Список пассажиров. Обязательный.
      SupplierId: null, //  - int - Поставщик услуги. Обязательный. (api/RefEntity/DropDownList/supplier?subListId={OrderTypeId}, где OrderTypeId - id типа заказа трансфер). Обязательное.
      SupplierContractId: null, // - int - Контракт с поставщиком. Обязательный. (api/RefEntity/DropDownList/contract?subListId={supplierId}, где supplierId - id поставщика). Обязательное.
      Comment: "", // - string - Комментарий. Необязательное.
      PatternText: "", //- string - Шаблонный текст. Необязательное.
      CancelConditionChange: "", // - string - Условия аннуляции / изменения, штраф. Обязательное.
      Price: 0, //- double - Ориентировочная стоимость. Обязательное.
      PaymentType: null, //   - string - Тип оплаты. (api/RefEntity/DropDownList/paymenttype, передаём EntityName). Обязательное.
      CurrencyId: null, //  - int - Валюта(api/RefEntity/DropDownList/currency). Обязательное.
      TextOnBoard: null, // - string - Текст на табличке. Необязательное.
      CarClass: null, //  - string - Класс автомобиля (api/RefEntity/DropDownList/carclass, передаём EntityName). Обязательное.
      Phone: null, //- string - Телефон (который около поставщика). Необязательное.
      TransferRouteList: [] //  - List типа TransferRoute. Обязательное.
    });

    // persone
    const { deletePersone, addPerson, setPerson } = useCreatePassenger(
      transfer
    );
    // route
    const {
      addTransferRoute,
      setRoute,
      deleteTransferRoute
    } = useCreateTransferRoute(transfer);

    const setData = (
      val: null | DropDownEntity,
      field: string,
      fieldname = "entityId"
    ) => {
      transfer[field] = getElement(val, fieldname);
    };
    const simpleSetData = (val, field: string) => {
      transfer[field] = val;
    };
    const supplierType = TYPE_DROPLIST.SUPPLIER;
    const supplierContactType = TYPE_DROPLIST.CONTRACT;
    const curencyType = TYPE_DROPLIST.CURRENCY;
    const paymentTypeType = TYPE_DROPLIST.PAYMENT_TYPE;
    const carClassType = TYPE_DROPLIST.CARCLASS;
    const createTransfer = () => {
      emit("open-order-modal", transfer);
      console.log(transfer);

    }

    const isValid = computed(() => {
      return (!!transfer.PersonList && !!transfer.SupplierId && !!transfer.SupplierContractId && !!transfer.CancelConditionChange && !!transfer.Price && !!transfer.PaymentType && !!transfer.CurrencyId && !!transfer.CarClass && !!transfer.TransferRouteList)
    });

    watch(() => transfer, () => {
      if(!transfer.isGroup) {
        transfer.NumberOfPersons = transfer.PersonList.length;
      }
      if(transfer.isGroup) {
        transfer.PersonList.splice(1);
      }
    }, {deep: true });

    return {
      addPerson,
      transfer,
      setPerson,
      deletePersone,
      setData,
      supplierType,
      supplierContactType,
      curencyType,
      paymentTypeType,
      carClassType,
      addTransferRoute,
      deleteTransferRoute,
      setRoute,
      createTransfer,
      simpleSetData,
      isValid,
    };
  }
});
