import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "checkboxes-wrapper checkboxes-wrapper--airport" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Checkbox = _resolveComponent("Checkbox")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.shortAccessor, (acc) => {
      return (_openBlock(), _createBlock("div", {
        class: "p-field-checkbox",
        key: acc.id
      }, [
        _createVNode(_component_Checkbox, {
          name: acc.id,
          value: acc.id,
          modelValue: _ctx.selectAcc,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.selectAcc = $event)),
          onChange: _withModifiers($event => (_ctx.filterByAccesor(acc.id)), ["prevent"])
        }, null, 8, ["name", "value", "modelValue", "onChange"]),
        _createVNode("label", null, _toDisplayString(acc.name), 1)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}