
import NavMenu from "../components/base/NavMenu.vue";
import ControlsMenu from "../components/base/ControlsMenu.vue";
import Burger from "../components/base/Burger.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "appHeader",
  setup() {
    const isOpen = ref<boolean>(false);

    function openCloseMenu(event) {
      const burger = document.querySelector(".burger-btn");

      if (event.target != burger && isOpen.value == true) {
        isOpen.value = false;
      }
    }

    return { isOpen, openCloseMenu };
  },
  components: {
    NavMenu,
    ControlsMenu,
    Burger
  }
});
