import { createStore, createLogger } from "vuex";
import { AuthModule } from "./auth/store";
import { Store as AuthStore, State as AuthState } from "./auth/contract";

import { SystemModule } from "./system/store";
import { Store as SystemStore, State as SystemState } from "./system/contract";

import { AviaModule } from "./avia/store";
import { Store as AviaStore, State as AviaState } from "./avia/contract";

import { OrderModule } from "./order/store";
import { Store as OrderStore, State as OrderState } from "./order/contract";

import { TripsModule } from "./trips/store";
import { Store as TripsStore, State as TripsState } from "./trips/contract";

import { HotelsModule } from "./hotels/store";
import { Store as HotelsStore, State as HotelsState } from "./hotels/contract";

import { ServiceModule } from "./services/store";
import {
  Store as ServiceStore,
  State as ServiceState
} from "./services/contract";

import { SharedModule } from "./shared/store";
import { Store as SharedStore, State as SharedState } from "./shared/contract";

import { RailModule } from "./rail/store";
import { Store as RailStore, State as RailState } from "./rail/contract";

import {
  DomainModule,
  Store as DomainStore,
  State as DomainState
} from "./domain/store";

export type State = {
  auth: AuthState;
  domain: DomainState;
  system: SystemState;
  avia: AviaState;
  order: OrderState;
  trips: TripsState;
  hotels: HotelsState;
  services: ServiceState;
  shared: SharedState;
  rail: RailState;
};

export type Store = AuthStore<Pick<State, "auth">> &
  DomainStore<Pick<State, "domain">> &
  AviaStore<Pick<State, "avia">> &
  SystemStore<Pick<State, "system">> &
  OrderStore<Pick<State, "order">> &
  TripsStore<Pick<State, "trips">> &
  ServiceStore<Pick<State, "services">> &
  SharedStore<Pick<State, "shared">> &
  HotelsStore<Pick<State, "hotels">> &
  RailStore<Pick<State, "rail">>;

export const store = createStore({
  plugins: process.env.NODE_ENV === "production" ? [] : [createLogger()],
  modules: {
    auth: AuthModule,
    DomainModule,
    system: SystemModule,
    avia: AviaModule,
    order: OrderModule,
    trips: TripsModule,
    hotels: HotelsModule,
    services: ServiceModule,
    shared: SharedModule,
    rail: RailModule
  }
});

export function useStore(): Store {
  return store as Store;
}

export default store;
