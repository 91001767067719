import { MutationTree } from "vuex";
import { Mutations, MutationTypes } from "./MutationsType";
import { State } from "./contract";
// Define mutations
export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SAVE_RAIL_SEARCHREQUEST](state: State, { data }) {
    localStorage.setItem("tempdatasearchrail", JSON.stringify(data));
    state.searchReq = data;
  },
  [MutationTypes.SET_TRAIN_DATA](state: State, { data }) {
    localStorage.setItem("tempdatatrains", JSON.stringify(data));
    state.trains = data;
  },
  [MutationTypes.SET_TRAIN_PRICE](state: State, { data, key }) {
    state.pricingCache[key] = data;
  },
  [MutationTypes.SELECT_TRAIN_WITH_SEATS](state: State, { data, key }) {
    state.trainWithSeats[key] = data;
  },
  [MutationTypes.UNSELECT_TRAIN](state: State, {key}) {
    delete state.trainWithSeats[key];
  },
  [MutationTypes.CLEAR_CACHE](state: State) {
    state.pricingCache = {};
    state.searchReq = null;
    state.trainWithSeats = {},
    state.trains = null;
  },
  [MutationTypes.INCREASE_COUNT](state: State) {
    state.passengersCount++
  },
  [MutationTypes.DECREASE_COUNT](state: State) {
    state.passengersCount--
  },
};
