
import { defineComponent, reactive, watch } from "vue";
import { TYPE_DROPLIST } from "../../constant";
import CommonSelector from "../shared/Selectors/CommonSelector.vue";
import Datepicker from "vuejs3-datepicker";
import { RouteMainInterface } from "../../interfaceAndType/typesShared";
import BaseInput from "../components/base/BaseInput.vue";
import moment from "moment";
import clearReactive from "@/helpers/clearReactive";
export default defineComponent({
  name: "TransferRoute",
  emit: ["delete-route", "set-route"],
  components: { CommonSelector, Datepicker, BaseInput },
  setup(props, { emit }) {
    const mainData = reactive<RouteMainInterface>({
      city: null,
      date: new Date(),
      address: "",
      time: "",
      transportNum: ""
    });
    const deleteRoute = () => {
      emit("delete-route");
    };
    const setData = val => {
      mainData.city = val;
    };

    watch(mainData, () => {
      const connvertMainData = clearReactive(mainData);
      connvertMainData.time = moment(connvertMainData.time).format("HH:mm");
      emit("set-route", connvertMainData);
    });
    const simpleSetData = (val, field: string) => {
      mainData[field] = val;
    };
    const cityType = TYPE_DROPLIST.CITY;
    return {
      deleteRoute,
      setData,
      cityType,
      mainData,
      simpleSetData
    };
  }
});
