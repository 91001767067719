
import { computed, defineComponent } from "vue";
import { useStore } from "../../../store/modules";

export default defineComponent({
  name: "FilterAviacompany",
  props: {
    filterByCompany: {
      type: Function,
      required: true
    },
    acceptFilterAirlines: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const airlines = computed(() => {
      return store.state.avia?.searhcData?.referenceContainer?.airlines
        ? store.state.avia.searhcData.referenceContainer.airlines
        : [];
    });

    return {
      airlines
    };
  }
});
