
import { ExtendedHotelInfo } from "@/interfaceAndType/typesHotel";
import { computed, defineComponent, PropType, toRefs } from "vue";

export default defineComponent({
  name: "HotelService",
  props: {
    item: {
      type: Object as PropType<ExtendedHotelInfo>,
      required: true
    }
  },
  setup(props) {
    const { item } = toRefs(props);
    const allService = computed(() => {
      if (item.value) {
        const res = {
          ice: false,
          knife: false,
          news: false,
          wash: false,
          internet: item.value.hasInternet,
          bed: true
        };
        return res;
      }

      return false;
    });
    return { allService };
  }
});
