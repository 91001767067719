
import { computed, defineComponent, PropType, toRefs } from "vue";
import { Flight } from "../../../interfaceAndType/typesSearh";
export default defineComponent({
  props: {
    allFlight: {
      type: Object as PropType<Array<Flight>>,
      required: true
    }
  },
  setup(props) {
    const { allFlight } = toRefs(props);

    const flyGroup = computed(() => {
      const allFl = allFlight.value;
      let flG: Array<string> = [];
      allFl.forEach(fl => {
        flG.push(fl.origin ? fl.origin : "н/д");
        flG.push(fl.destination ? fl.destination : "н/д");
      });
      if (allFl.length == 1) {
        return null;
      }
      flG = flG.filter((flgItem, index) => {
        return flG.indexOf(flgItem) === index;
      });
      return {
        count: flG.length - 2,
        routes: flG.join("-")
      };
    });

    return { flyGroup };
  }
});
