
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  toRefs
} from "vue";
import { ORDER_TYPES, STATUS_ORDER } from "../../constant";
import useCreatePassenger from "../../hocks/useCreatePassenger";
import {
  BookingAviaTemp,
  OrderDTOType,
  TripWithOrders
} from "../../interfaceAndType/typesTrips";
import SelectorPerson from "../shared/personEdit/SelectorPerson.vue";
import ControlBlock from "./ControlBlock.vue";
import InfoOrder from "./InfoOrder.vue";
import SendMail from "./SendMail.vue";

export default defineComponent({
  components: { InfoOrder, SendMail, SelectorPerson, ControlBlock },
  name: "BookingForm",
  props: {
    selectTikets: {},
    activeOffer: { type: Object as PropType<OrderDTOType>, required: true },
    type: {},
    listOrders: { type: Object as PropType<TripWithOrders>, required: true },
    OfferId: {},
    initData: {
      type: Function
    }
  },
  setup(props) {
    const { listOrders, activeOffer, type } = toRefs(props);
    const isShowMode = ref(false);

    const simpleBokingReq: BookingAviaTemp = reactive({
      PersonList: []
    });
    const contractId = computed(() => listOrders.value.contractId);
    const { setPerson, deletePersone, addPerson } = useCreatePassenger(
      simpleBokingReq
    );

    const initBokingData = () => {
      /**
       * Поиск пассажирова
       */
      if (activeOffer.value && activeOffer.value.offerDTOList) {
        const findActive = activeOffer.value.offerDTOList.find(
          item =>
            item.statusCode == STATUS_ORDER.Booking ||
            item.statusCode == STATUS_ORDER.Canceled ||
            item.statusCode == STATUS_ORDER.TicketReady
        );
        if (findActive && findActive.passengerList) {
          isShowMode.value = true;
          simpleBokingReq.PersonList = findActive.passengerList;
        }
      }
      /**
       * если трансфер то надо показать тех пассажирова котороые мы выбрали в форме
       */
      if (
        (type?.value == ORDER_TYPES.TRANSFER ||
          type?.value == ORDER_TYPES.FREE) &&
        activeOffer.value &&
        activeOffer.value.offerDTOList
      ) {
        if (activeOffer.value.offerDTOList.length == 1) {
          const findActive = activeOffer.value.offerDTOList[0];
          if (findActive && findActive.passengerList) {
            isShowMode.value = true;
            simpleBokingReq.PersonList = findActive.passengerList;
          }
        }
      }
    };
    initBokingData();

    const getClearCount = (val, def = 0) => {
      return val ? parseInt(val) : def;
    };
    const showbtnAddPerson = computed(() => {
      const { adultCount, babyCount, childCount } = activeOffer.value;
      let maxCount =
        getClearCount(adultCount) +
        getClearCount(babyCount) +
        getClearCount(childCount);
      if (!maxCount) {
        maxCount = 1;
      }
      if (simpleBokingReq.PersonList.length >= maxCount || isShowMode.value) {
        return false;
      }
      return true;
    });

    const userHasInfo = computed(() => {
      let res = true;
      if (simpleBokingReq.PersonList.length == 0) {
        res = false;
      }
      simpleBokingReq.PersonList.forEach(el => {
        if (!el.personId || !el.documentId) {
          res = false;
        }
      });
      return res;
    });
    const titlePerson = computed(() => {
      let res = "Пассажиры";
      if (type && type.value == ORDER_TYPES.HOTEL) {
        res = "Гости";
      }
      return res;
    });

    const showInfoMess = computed(() => {
      let res = false;
      activeOffer.value.offerDTOList?.forEach(el => {
        if (
          el.statusCode &&
          [STATUS_ORDER.Denied, STATUS_ORDER.Canceled].indexOf(el.statusCode) ==
            -1
        ) {
          res = true;
        }
      });
      return res;
    });

    return {
      simpleBokingReq,
      setPerson,
      deletePersone,
      addPerson,
      contractId,
      isShowMode,
      showbtnAddPerson,
      userHasInfo,
      titlePerson,
      showInfoMess
    };
  }
});
