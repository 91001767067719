import { PersonType } from "@/interfaceAndType/typesShared";
import { generateUUID } from "./generateUUID";

/**
 * Поулчить экземляр чистого человека
 */
export const getClearPerson = () => {
  return {
    offerPassengerId: null,
    personId: 0,
    documentId: 0,
    bonusCardId: null,
    emailId: null,
    phoneId: null,
    personCodeValues: null,
    budgetCodeValues: null,
    uuid: generateUUID()
  } as PersonType;
};
