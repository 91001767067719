
import { defineComponent, PropType, ref, toRef, toRefs } from "vue";
import DepartureTimeFilter from "./rail/DepartureTimeFilter.vue";
import TrainBrandFilter from "./rail/TrainBrandFilter.vue";
import TrainTypeFilter from "./rail/TrainTypeFilter.vue";

export default defineComponent({
  name: "HotelsFilter",
  components: {
    TrainTypeFilter,
    TrainBrandFilter,
    DepartureTimeFilter,
  },
  props: {
    filterByTrainTypes: {
      type: Function
    },
    filterByTrainBrands: {
      type: Function
    },
    applyAllFilters: {
      type: Function
    },
    filterByDeparture: {
      type: Function
    }
  },
  setup(props) {
    return {};
  }
});
