
import _ from "lodash";
import { computed, defineComponent, PropType, reactive, ref, toRefs } from "@vue/runtime-core";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
import { OrderCreateReqType } from "../../interfaceAndType/typesOrder";
import { DropDownEntity } from "../../interfaceAndType/typesShared";
import { EVENT_LOADER } from "../../store/modules/system/contract";
import { ActionTypes as OrderActionTypes } from "../../store/modules/order/contract";
import router from "../../router";
import { ActionTypes } from '../../store/modules/trips/contract';
import { MutationTypes } from "../../store/modules/trips/MutationsType";

const ActionTypesConstants = { ...OrderActionTypes };

export default defineComponent({
  name: "TripsOrderModal",
  components: {},
  props: {
    showOrderModal: { type: Boolean, default: false },
    closeModal: { type: Function, required: true },
    keyActive: { type: String as PropType<string> },
    passengersCount: { type: Object, default: null },
    orderTypeId: { type: Number, required: true },
  },
  setup(props) {
    const {
      showOrderModal,
      closeModal,
    } = toRefs(props);
    const toast = useToast();
    const store = useStore();
    const names = [
      EVENT_LOADER.DEF_LOUDER,
      EVENT_LOADER.LOGIN_USER,
      EVENT_LOADER.LOAD_USER_DATA
    ];
    const newOrder = ref<boolean>(false);
    const defaultOrder = ref<OrderCreateReqType>({
      businessTripId: null,
      businessTrip: {
        name: "",
        clientId: 0,
        contractId: 0,
        initiatorId: 0
      },
      orderTypeId: props.orderTypeId,
      emailList: [],
      isSend: true,
      adultCount: props.passengersCount ? props.passengersCount.adults : null,
      childCount: props.passengersCount ? props.passengersCount.children : null,
      babyCount: props.passengersCount ? props.passengersCount.infants : null,
      budgetCodeValues: null,
      offerList: []
    });

    const localDisplay = computed({
      get: () => showOrderModal.value,
      set: val => {
        closeModal.value(val);
      }
    });

    interface Lists {
      clients: Array<DropDownEntity | null>;
      contracts: Array<DropDownEntity | null>;
      initiators: Array<DropDownEntity | null>;
      trips: Array<DropDownEntity | null>;
    }
    const lists = reactive<Lists>({
      clients: [],
      contracts: [],
      initiators: [],
      trips: []
    });
    const selectedClient = ref<DropDownEntity | null>(null);
    const selectedContract = ref<DropDownEntity | null>(null);
    const selectedInitiator = ref<DropDownEntity | null>(null);
    const selectedTrip = ref<DropDownEntity | null>(null);
    const tripName = ref<string>("");
    const isLoading = ref<boolean>(false);

    type paramsIn = {
      searchParam?: string;
      clientId?: number;
      contractId?: number;
      search?: string;
    };

    const asyncFindFunc = _.debounce(
      async (query: string, list: string, actionType: string) => {
        isLoading.value = true;
        let paramsIn: paramsIn = {
          searchParam: query
        };
        if (actionType === ActionTypesConstants.GET_CONTRACT) {
          paramsIn = {
            ...paramsIn,
            clientId: defaultOrder.value.businessTrip.clientId
          };
        }
        if (actionType === ActionTypesConstants.GET_INITIATOR) {
          paramsIn = {
            ...paramsIn,
            contractId: defaultOrder.value.businessTrip.contractId
          };
        }
        if (actionType === ActionTypesConstants.GET_TRIP) {
          paramsIn = {
            search: query
          };
        }

        lists[list] = await store.dispatch(
          OrderActionTypes[actionType],
          paramsIn
        );

        isLoading.value = false;
      },
      500
    );

    const asyncFind = (
      event,
      variants: Array<DropDownEntity>,
      actionType: string
    ) => {
      asyncFindFunc(event.query, variants, actionType);
    };

    const setData = (event, key: string) => {
      defaultOrder.value.businessTrip[key] = event.value.entityId;
    };

    const sendOrder = async () => {
      const res = await store.dispatch(OrderActionTypes.SEND_ORDER, {
        order: defaultOrder.value
      });
      if (res) {
        toast.add({
          severity: "success",
          summary: "Успешно",
          detail: "Заказ успешно создан",
          life: 3000
        });
        router.push("/");
      }
      await store.dispatch(ActionTypes.GET_TRIPS, {});
      store.commit(MutationTypes.CLEAR_CACHE);
      closeModal.value(false);
    };

    const isValid = computed(() => {
      return Object.values(defaultOrder.value.businessTrip).every(val => !!val);
    })

    return {
      newOrder,
      localDisplay,
      names,
      lists,
      selectedClient,
      selectedContract,
      selectedInitiator,
      selectedTrip,
      tripName,
      isLoading,
      asyncFind,
      defaultOrder,
      setData,
      ActionTypesConstants,
      sendOrder,
      isValid
    };
  },
});
