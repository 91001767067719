
import { defineComponent, PropType } from "vue";
import { HotelTreeItem } from "../../../interfaceAndType/typesHotel";

export default defineComponent({
  name: "SearchCardFooter",
  props: {
    toggleDisplay: {
      type: Function
    },
    item: {
      type: Object as PropType<HotelTreeItem>
    },
    showPrice: {
      type: Boolean,
      default: true
    },
        isSelected: {
      type: Boolean,
      default: false
    },
  }
});
