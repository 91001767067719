
import { defineComponent, PropType, toRefs } from "vue";
import { ExtendedHotelInfo } from "../../../interfaceAndType/typesHotel";
import moment from "moment";

export default defineComponent({
  name: "HotelTicketSmall",
  props: {
    item: {
      type: Object as PropType<ExtendedHotelInfo>
    },
    isModeOffer: {
      type: Boolean,
      default: false
    }
  },
  emits: ["select-offer"],
  setup(props, { emit }) {
    const { item } = toRefs(props);
    const parseDate = (date, format) => {
      return moment(date).format(format);
    };
    const selectTiket = () => {
      emit("select-offer", item?.value);
    };
    return { parseDate, selectTiket };
  }
});
