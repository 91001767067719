import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "mw" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_main_loader = _resolveComponent("main-loader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_Toast = _resolveComponent("Toast")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_main_loader, { name: _ctx.names }, null, 8, ["name"]),
    _createVNode(_component_router_view),
    _createVNode(_component_Toast, { position: "top-right" })
  ]))
}