
import { defineComponent, ref, reactive, watchEffect, computed } from "vue";
import { AviaSearchReqType } from "../../../interfaceAndType/typesSearh";
import SearhAviaElement from "./searchForm/SearhAviaElement.vue";
import SearchParams from "./searchForm/SearchParams.vue";

import { ActionTypes } from "../../../store/modules/avia/contract";
import { useStore } from "../../../store/modules";
import { useRouter } from "vue-router";
import { path } from "../../../router/path";
import { MutationTypes as AviaMutationTypes } from "../../../store/modules/avia/MutationsType";
import clearReactive from "../../../helpers/clearReactive";
import moment from "moment";
const TYPE_WAY = {
  oneway: "one-way",
  twoways: "two-ways",
  complexway: "complex-way"
};

export default defineComponent({
  name: "SearchForm",
  components: {
    SearhAviaElement,
    SearchParams
  },
  setup() {
    const store = useStore();
    //очитстить дынные в сторе
    store.commit(AviaMutationTypes.CLEAR_STORE);
    //----------------
    const router = useRouter();
    const wayType = ref<string>(TYPE_WAY.oneway);
    const isParamsOpen = ref<boolean>(false);
    const dateTo = ref<string>("");
    const searhcData = reactive<AviaSearchReqType>({
      children: 0,
      adults: 1,
      infants: 0,
      isOnlyDirect: true,
      fare: 0,
      migrationList: [
        {
          origin: "",
          destination: "",
          startHour: "00",
          endHour: "24",
          date: ""
        }
      ]
    });
    /**
     * Устанавливает вторую дату в режим туда обратно
     */
    const setDataToIn = (val: string) => {
      dateTo.value = val;
    };
    /**
     * Устанавливает данные
     */
    const setData = (name: string, val: string, index: number) => {
      searhcData.migrationList[index][name] = val;
    };
    /**
     * Запрос на поиск
     */

    const searchData = async () => {
      const preventData: AviaSearchReqType = clearReactive(searhcData);
      if (wayType.value == TYPE_WAY.twoways) {
        preventData.migrationList.push(clearReactive(preventData.migrationList[0]));
        const firstFly = preventData.migrationList[0];
        preventData.migrationList[1].origin = firstFly.destination;
        preventData.migrationList[1].destination = firstFly.origin;
        preventData.migrationList[1].date = dateTo.value;
      }

      preventData.migrationList = preventData.migrationList.map(list => {
        return {
          ...list,
          date: moment(list.date).format("YYYY-MM-DD")
        };
      });
      const res = await store.dispatch(ActionTypes.SEARH_AVIA, {
        searhData: preventData
      });
      if (res) {
        router.push(path.aviaSearchResult);
      }
    };

    const setDirectFlight = (val: boolean) => {
      searhcData.isOnlyDirect = val;
    };

    const increaseCount = (name: string) => {
      searhcData[name]++;
    };

    const decreaseCount = (name: string) => {
      searhcData[name]--;
    };

    const changeFare = (val: number) => {
      searhcData.fare = val;
    };

    const faresList = ref<Array<object>>([]);

    const getFares = async () => {
      const res = await store.dispatch(ActionTypes.GET_FARES);
      faresList.value = res.object;
    };
    getFares();

    watchEffect(() => {
      if (wayType.value == TYPE_WAY.complexway) {
        searhcData.migrationList.push({
          origin: "",
          destination: "",
          startHour: "00",
          endHour: "24",
          date: ""
        });
        return;
      }
      if (
        wayType.value == TYPE_WAY.oneway ||
        wayType.value == TYPE_WAY.twoways
      ) {
        const FirstFly = searhcData.migrationList[0];
        searhcData.migrationList = [FirstFly];
      }
    });

    const addRoute = () => {
      searhcData.migrationList.push({
        origin: "",
        destination: "",
        startHour: "00",
        endHour: "24",
        date: ""
      });
    };

    const deleteRoute = (index: number) => {
      searhcData.migrationList.splice(index, 1);
    };

    const disabledSearch = computed(() => {
      return searhcData.migrationList.every(el => {
        return Object.values(el).every(val => !!val);
      });
    });

    return {
      wayType,
      isParamsOpen,
      searhcData,
      setData,
      setDataToIn,
      searchData,
      addRoute,
      deleteRoute,
      setDirectFlight,
      increaseCount,
      decreaseCount,
      changeFare,
      getFares,
      faresList,
      dateTo,
      disabledSearch
    };
  }
});
