import { ActionTree } from "vuex";
import { State as RootState } from "../index";
import { State, Actions, ActionTypes, AirPortType } from "./contract";
import { ApiService } from "../../../utils/ApiService";
import { MutationTypes as AviaMutationTypes } from "./MutationsType";
import { MutationTypes as SystemMutationTypes } from "../system/MutationsType";
import { DropDownEntity } from "@/interfaceAndType/typesShared";
import { CodeValueType } from '../../../interfaceAndType/typesShared';

// Define actions
export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SEARH_DROPLIST](
    { commit },
    payload: {
      type: string;
      search: string | null;
      subListId: number | null;
      id?: number | null;
    }
  ) {
    const { type, search, subListId, id } = payload;
    const apiService = new ApiService();
    const res = await apiService.getDropList(type, search, subListId, id);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      return [];
    } else {
      if (id) {
        return [res.object] as Array<DropDownEntity>;
      }
      return res.object as Array<DropDownEntity>;
    }
  },
  async [ActionTypes.GET_BUDGET_CODES](
    { commit },
    payload: {
      ContractId: number,
      offerPersonId: number
    }
  ) {
    const { ContractId, offerPersonId } = payload;
    const apiService = new ApiService();
    const res = await apiService.getBudgetCodes(ContractId, offerPersonId);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      return [];
    } else {
      return res.object as Array<CodeValueType>;
    }
  },
  async [ActionTypes.GET_PERSON_CODES](
    { commit },
    payload: {
      PersonId: number
    }
  ) {
    const { PersonId } = payload;
    const apiService = new ApiService();
    const res = await apiService.getPersonCodes(PersonId);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      return [];
    } else {
      return res.object as Array<CodeValueType>;
    }
  }
};
