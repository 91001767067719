
import { computed, defineComponent, ref, toRefs } from "vue";
import { NTrainListReference } from "../../../interfaceAndType/typesRail";
import { useStore } from "../../../store/modules";

export default defineComponent({
  name: "TrainBrandFilter",
  props: {
    filterByTrainBrands: {
      type: Function
    }
  },
  setup(props) {
    const store = useStore();
    const allTrains = computed(() => {
      const searchData: NTrainListReference[] | null = store.state.rail.trains;
      if (searchData) {
        const res: NTrainListReference[] = [];
        searchData.forEach(item => {
          const find = res.find(type => type.brandTrain == item.brandTrain);
          if (!find  && item.brandTrain && item.brandTrain.length > 0) {
            res.push(item);
          }
        });
        return res;
      }
      return [];
    });
    const selectedTrainBrand = ref([]);
    return { allTrains, selectedTrainBrand };
  }
});
