import { TransferRoute } from "@/interfaceAndType/typesServices";
import { generateUUID } from "./generateUUID";

/**
 * Поулчить экземляр чистого направления
 */
export const getTransferRoute = () => {
  return {
    CityId: -1, // - int - Город. (api/RefEntity/DropDownList/city). Обязательный.
    Address: "", // - string - Адрес. Обязательный.
    Date: "", //- DateTime - Дата. Обязательный.
    Time: "", // - string - Время. Обязательный.
    TransportNum: "", // - string - Номер рейса / поезда. Необязательный.
    uuid: generateUUID()
  } as TransferRoute;
};
