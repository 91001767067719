
import { defineComponent } from "vue";

export default defineComponent({
  name: "BtnLeftFilter",
  props: {
    type: {
      type: String,
      required: true
    },
    setActive: {
      type: Function,
      required: true
    },
    orderType: {type: String, required: true },
    isActive: { type: Function, required: true },
    title: {
      type: String,
      required: true
    },
    filterData: {
      required: true
    }
  },
  setup() {
    return {};
  }
});
