
import { computed, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ORDER_TYPES, STATUS_ORDER } from "../../constant";
import { OrderDTOType } from "../../interfaceAndType/typesTrips";
import { useStore } from "../../store/modules";
import { MutationTypes } from "../../store/modules/trips/MutationsType";
export default defineComponent({
  name: "OffersTripPage",
  props: {
    listOrders: {},
    initData: {}
  },
  setup() {
    const route = useRoute();
    const routes = useRouter();
    const type = computed(() => route.params.type);
    const activeOfferKeyInRoute = computed(() => route.params.orderId);

    const store = useStore();
    const activeOfferKey = computed(() => store.state.trips.activeOfferKey);

    if (type.value !== store.state.trips.activeTypeCode) {
      store.commit(MutationTypes.SET_ACTIVE_FILTER_TYPE, {
        code: type.value as string
      });
    }

    const offers = computed<Array<OrderDTOType> | null>(() => {
      return store.getters.getSelectOffer(type.value as string);
    });

    const convertListOffer = computed(() => {
      const res: Array<{ isOffer: boolean; orderId: number }> = [];
      if (offers.value) {
        offers.value.forEach(item => {
          let isOffer = false;
          if (item.statusCode == STATUS_ORDER.Created) {
            isOffer = true;
          }
          res.push({ isOffer, orderId: item.orderId });
        });
      }
      return res;
    });

    if (
      activeOfferKeyInRoute.value &&
      convertListOffer.value &&
      activeOfferKeyInRoute.value !== activeOfferKey.value
    ) {
      const find = convertListOffer.value.find(
        i => i.orderId == parseInt(activeOfferKeyInRoute.value as string)
      );
      if (find) {
        store.commit(MutationTypes.SET_ACTIVE_FILTER_OFFER, {
          key: activeOfferKeyInRoute.value as string
        });
      }
    }

    const isActiveOffer = orderId => {
      let res = false;
      if (activeOfferKey.value == orderId) {
        res = true;
      }
      return res;
    };
    const setActiveOffer = orderId => {
      store.commit(MutationTypes.SET_ACTIVE_FILTER_OFFER, { key: orderId });
      routes.push(
        `/trip/${route.params.id}/type/${type.value}/offer/${orderId}`
      );
    };
    return { type, offers, convertListOffer, isActiveOffer, setActiveOffer };
  }
});
