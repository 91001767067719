import { StringTypedResponse } from "../typeSystem/api/response";
import { CheckOffer, CreateBooking } from "@/interfaceAndType/typesTrips";
export class ApiTrip {
  _http: any;
  constructor(_http) {
    this._http = _http;
  }
  errRes: StringTypedResponse = {
    errors: ["Ошибка сервера или нет соединения!"],
    isWarning: true,
    isValid: false,
    warnings: ["Ошибка сервера или нет соединения!"],
    countPage: 0,
    object: {}
  };
  /**
   * Получить варианты для подтверждения бронирование
   * @param PersonList
   * @param OfferId
   */
  async getAcceptVariant(code: string): Promise<StringTypedResponse> {
    const urlReq = `/api/Accept/Trip/${code}`;
    try {
      const res = await this._http.get(urlReq);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }
  /**
   * Подтверждение вариантов
   * @param checkOffer
   * @returns
   */
  async AcceptVariant(checkOffer: CheckOffer): Promise<StringTypedResponse> {
    const urlReq = `/api/Accept/Trip/${checkOffer.code}`;
    try {
      const res = await this._http.post(urlReq, checkOffer);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }
}
