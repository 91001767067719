<template>
	<div style="width: 100%; background-color: white; padding: 10px">
		<div>
			<svg width="100%" height="100%" :viewBox="`0, 0, ${vWidth}, ${vHeight}`" xmlns="http://www.w3.org/2000/svg">
				<!--границы вагона-->
				<g class="wall">
					<!--верх-->
					<line x1="0" y1="5" :x2="vWidth" y2="5"></line>
					<!--левая-->
					<line x1="5" y1="5" x2="5" :y2="vHeight - 5"></line>
					<!--нижняя-->
					<line x1="0" :y1="vHeight - 5" :x2="vWidth" :y2="vHeight - 5"></line>
					<!--правая-->
					<line :x1="vWidth - 5" y1="5" :x2="vWidth - 5" :y2="vHeight - 5"></line>
				</g>

				<!--левый край-->
				<g>
					<g title="Туалет" class="wc">
						<rect x="105" y="5" width="100" height="150"></rect>
						<text x="155" y="100" text-anchor="middle">WC</text>
					</g>
					<line x1="100" y1="0" x2="100" y2="150" class="wall"></line>
					<line x1="200" y1="0" x2="200" y2="180" class="wall"></line>
					<line x1="300" y1="215" x2="100" y2="145" class="wall"></line>
					<g>
						<line x1="100" :y1="250" x2="100" :y2="vHeight" class="wall"></line>
						<line x1="200" :y1="285" x2="200" :y2="vHeight" class="wall"></line>
						<line x1="100" y1="255" x2="200" y2="290" class="wall"></line>
					</g>
				</g>

				<!--правый край-->
				<g>
					<g title="Туалет" class="wc">
						<rect :x="vWidth - 205" y="5" width="100" height="150"></rect>
						<text :x="vWidth - 155" y="100" text-anchor="middle">WC</text>
					</g>
					<line :x1="vWidth - 100" y1="0" :x2="vWidth - 100" y2="220" class="wall"></line>
					<line :x1="vWidth - 95" y1="150" :x2="vWidth - 210" y2="150" class="wall"></line>
					<g>
						<line :x1="vWidth - 100" :y1="305" :x2="vWidth - 100" :y2="vHeight" class="wall"></line>
					</g>
				</g>
        <g v-for="i in 9" :key="i" :transform="`translate( ${300 + 210 * (i - 1)}, 0)`">
          <!--окна-->
          <line x1="55" y1="5" x2="155" y2="5" class="window"></line>
          <line x1="55" :y1="vHeight - 5" x2="155" :y2="vHeight - 5" class="window"></line>

          <!--стенки купе-->
          <!--левая-->
          <line x1="0" y1="5" x2="0" y2="220" class="wall"></line>
          <!--правая-->
          <line x1="210" y1="5" x2="210" y2="220" class="wall"></line>

          <!--места в купе-->
          <g
            v-for="seat in 4"
            :id="seat + seatsOffset(i)"
            :key="seat + seatsOffset(i)"
            :title="seatInfo(seat + seatsOffset(i))"
            :class="['seat-container', busyClass(seat + seatsOffset(i)), isHighlight(seat + seatsOffset(i))]"
            @click="takeSeat(seat + seatsOffset(i))"
          >
            <rect :x="seat > 2 ? 135 : 5" :y="seat % 2 === 0 ? 10 : 120" width="70" height="100" class="seat">
              <title>{{ seatInfo(seat + seatsOffset(i)) }}</title>
            </rect>
            <text :x="seat > 2 ? 175 : 40" :y="seat % 2 === 0 ? 80 : 185" text-anchor="middle" class="seat-number">
              {{ seat + seatsOffset(i) }}
            </text>
          </g>
          <rect x="80" y="10" width="50" height="50" title="Столик" class="train-table"></rect>

          <!--стенки боковушек-->
          <!--левая-->
          <line x1="0" y1="305" x2="0" :y2="vHeight" class="wall"></line>
          <!--правая-->
          <line x1="210" y1="305" x2="210" :y2="vHeight" class="wall"></line>

          <!--места боковые-->
          <!--верхнее-->
          <g
            :id="1 + sideSeatsOffset(i)"
            class="seat-container"
            :class="['seat-container', busyClass(1 + sideSeatsOffset(i)), isHighlight(1 + sideSeatsOffset(i))]"
            :title="seatInfo(1 + sideSeatsOffset(i))"
            @click="takeSeat(1 + sideSeatsOffset(i))"
          >
            <rect x="5" :y="vHeight - 80" width="95" height="70" class="seat">
              <title>{{ seatInfo(1 + sideSeatsOffset(i)) }}</title>
            </rect>
            <text x="50" :y="vHeight - 30" text-anchor="middle" class="seat-number">
              {{ 1 + sideSeatsOffset(i) }}
            </text>
          </g>

          <!--нижнее-->
          <g
            :id="2 + sideSeatsOffset(i)"
            class="seat-container"
            :class="['seat-container', isHighlight(2 + sideSeatsOffset(i)), busyClass(2 + sideSeatsOffset(i))]"
            :title="seatInfo(2 + sideSeatsOffset(i))"
            @click="takeSeat(2 + sideSeatsOffset(i))"
          >
            <rect x="110" :y="vHeight - 80" width="95" height="70" class="seat" @click="takeSeat(2 + sideSeatsOffset(i))">
              <title>{{ seatInfo(2 + sideSeatsOffset(i)) }}</title>
            </rect>
            <text x="160" :y="vHeight - 30" text-anchor="middle" class="seat-number">
              {{ 2 + sideSeatsOffset(i) }}
            </text>
          </g>
        </g>
			</svg>
		</div>
	</div>
</template>

<script>

export default {
	name: "PlatscartSeats",
	components: {},
  props: {
    ck: { default: null, type: Object, required: true },
    cv: { default: null, type: Object, required: true },
    value: { 
      default: null, 
      type: Array, 
      required: true 
    },
    selectSeat: {
      type: Function
    }
  },
	data() {
		return {
			vHeight: 385,
			vWidth: 2400
		};
	},
	computed: {
		freeSeats() {
			return this.cv.numberOfFreePlace.split(", ");
		},
		freeSeatsNums() {
			return this.freeSeats.map((n) => parseInt(n));
		},
		free() {
			const seats = this.cv.numberOfFreePlace.split(", ");
			return seats.map((seat) => {
				let number = seat;
				let sign = "";

				const last = seat[seat.length - 1];
				if (["Ж", "М", "С", "Ц"].includes(last)) {
					sign = last;
					number = number.substring(0, number.indexOf(sign));
				}

				return {
					number: parseInt(number),
					sign
				};
			});
		}
	},
	methods: {
		seatsOffset(i) {
			// 4 это сдвиг относительно по порядку купе
			return 4 * (i - 1);
		},
		sideSeatsOffset(i) {
			// 36 это сдвиг от не боковых мест в купе
			// 9 - i  это изза обратного счета
			// 2 это сдвиг на количество боковых мест в купе
			return (9 - i) * 2 + 36;
		},
		isHighlight(number) {
			return this.isSelected(number) ? "highlight" : "";
		},
		busyClass(number) {
			return this.isBusy(number) ? "busy" : "";
		},
		isBusy(number) {
			return !this.freeSeatsNums.includes(number);
		},
		isSelected(number) {
			return this.value && this.value.includes(number);
		},
		price() {
			return this.ck.costTicket;
		},

		seatNumber(coupeNumber, seat) {
			return coupeNumber * 2 - (2 - seat);
		},
		seatInfo(number) {
			return this.seatTooltip(number);
		},
		seatClass(number) {
			if (this.value && this.value.includes(number)) {
				return "selected";
			}

			return this.isFree(number) ? "free" : "taken";
		},

		seatTooltip(number) {
			const filtered = this.free.filter((seat) => seat.number === number);
			if (filtered.length === 0) {
				return "Занято";
			}

			let res = "Свободное место \n";
			if (number % 2 === 0) res += "верхнее\n";
			else res += "нижнее\n";

			return (res += this.price() + " RUB");
		},
		takeSeat(number) {
			if (this.isBusy(number)) {
				return;
			}

			const selected = this.value || [];
      if (selected.includes(number)) {
        const index = selected.indexOf(number);
        selected.splice(index, 1);
      } else {
        selected.push(number);
      }
      this.selectSeat(selected);
		}
	}
};
</script>

<style scoped lang="scss">

.seat-container {
  &:hover {
    cursor: pointer;
    .seat {
      fill:#65C1F5;
    }
  }
  &.highlight {
    cursor: pointer;
    .seat {
      fill:#F78F1E;
    }
  }

  &.busy {
    .seat {
      fill: #FFCDD2;
    }
    cursor: default;
  }
}

.seat {
  fill: #bbffbb;
}

.seat-number {
  fill: #393939;
  font: bold 40px sans-serif;
}

.selected {
  fill: #F78F1E;
}

.train-table {
  fill: burlywood;
}

.train-table:hover {
  cursor: help;
  fill: darkgrey;
}

.taken {
  fill: #eeeeee;
}

.taken:hover {
  cursor: pointer;
}

.wall {
  stroke: #979797;
  stroke-width: 10px;
}

.window {
  stroke: #b6d0d4;
  stroke-width: 10px;
}

.wc {
  rect {
    opacity: 0;
  }
  text {
    font-size: 40px;
    fill: darkgreen;
  }
}

.wc:hover {
  cursor: help;
}
</style>
