import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "filter-hotelsList" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("ul", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.airlines, (item) => {
      return (_openBlock(), _createBlock("li", {
        class: "filter-hotelsList__item",
        key: item.code
      }, [
        _createVNode("a", {
          href: "#",
          class: ["filter-hotelsList__link", {
          'filter-hotelsList__link--active':
            _ctx.acceptFilterAirlines.indexOf(item.code) > -1
        }],
          onClick: _withModifiers($event => (_ctx.filterByCompany(item)), ["prevent"])
        }, _toDisplayString(item.rus), 11, ["onClick"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}