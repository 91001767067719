/**
 * Api service
 */

import { StringTypedResponse } from "../typeSystem/api/response";
import {
  AviaPricingType,
  AviaSearchReqType
} from "../interfaceAndType/typesSearh";
import { Http } from "./Http";
import { OrderCreateReqType } from "../interfaceAndType/typesOrder";
import {
  BaseHotelAccesor,
  HotelSearchRequest,
  HotelTreeRequest
} from "../interfaceAndType/typesHotel";

import { PersonType } from "@/interfaceAndType/typesShared";
import { ApiHotel } from "./ApiHotel";
import { ApiServices } from "./ApiServices";
import {
  RailwaySearchRequest,
  RailwayPricingRequest
} from "../interfaceAndType/typesRail";
import { ApiRailway } from "./ApiRailway";
import { ApiAuth } from "./ApiAuth";
import { ApiTrip } from "./ApiTrip";

export class ApiService {
  private _http: any;
  hotelApi: ApiHotel;
  servicesApi: ApiServices;
  railway: ApiRailway;
  authApi: ApiAuth;
  tripApi: ApiTrip;
  constructor() {
    this._http = new Http().HTTP();
    this.hotelApi = new ApiHotel(this._http); //апи для отелей
    this.servicesApi = new ApiServices(this._http); //апи для услуг
    this.railway = new ApiRailway(this._http); //апи для жд
    this.authApi = new ApiAuth(this._http);
    this.tripApi = new ApiTrip(this._http);
  }

  errRes: StringTypedResponse = {
    errors: ["Ошибка сервера или нет соединения!"],
    isWarning: true,
    isValid: false,
    warnings: ["Ошибка сервера или нет соединения!"],
    countPage: 0,
    object: {}
  };

  /**
   * api логина
   * @param email  -- email
   * @param password -- пароль
   */
  async login(email: string, password: string): Promise<StringTypedResponse> {
    const urlReq = "api/auth/signin";
    try {
      const res = await this._http.post(urlReq + "?lang=ru", {
        email,
        password
      });
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * api me
   * @param email  -- email
   * @param password -- пароль
   */
  async me(): Promise<StringTypedResponse> {
    const urlReq = "api/auth/me";
    try {
      const res = await this._http.get(urlReq + "?lang=ru");
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * поиск аэропорт
   * @param search  -- аэропорт
   */
  async getAirport(search: string): Promise<StringTypedResponse> {
    const urlReq = "api/RefEntity/DropDownList/Airport";
    try {
      const res = await this._http.get(urlReq, {
        params: { lang: "ru", search: search }
      });
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * запрос на список возможных тарифов (классов обслуживания)
   *
   *
   */
  async getFares(): Promise<StringTypedResponse> {
    const urlReq = "api/RefEntity/DropDownList/Fare";
    try {
      const res = await this._http.get(urlReq);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * поиск авия билетов по цене
   * @param searchData  -- аэропорт
   */

  async searchAvia(searhData: AviaSearchReqType): Promise<StringTypedResponse> {
    const urlReq = "/api/Avia/Search";
    try {
      const res = await this._http.post(urlReq, searhData);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  async getPricing(AviaPricing: AviaPricingType): Promise<StringTypedResponse> {
    const urlReq = "/api/Avia/Pricing";
    try {
      const res = await this._http.post(urlReq, AviaPricing);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * апи для получения списка компаний-клиентов
   * @param search - данные из поля поиска
   * @returns массив объектов
   */

  async getCompany(search: string): Promise<StringTypedResponse> {
    const urlReq = "api/RefEntity/DropDownList/client";
    try {
      const res = await this._http.get(urlReq, {
        params: { lang: "ru", search: search }
      });
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * апи для получения списка контрактов у выбранного клиента
   * @param search - данные из поля поиска
   * @param clientId - id выбранного клиента
   * @returns массив объектов
   */

  async getContract(
    search: string,
    clientId: number
  ): Promise<StringTypedResponse> {
    const urlReq = `api/RefEntity/DropDownList/contract?subListId=${clientId}`;
    try {
      const res = await this._http.get(urlReq, {
        params: { lang: "ru", search: search }
      });
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * апи для получения списка инициаторов выбранного контракта
   * @param search - данные из поля поиска
   * @param contractId - id выбранного контракта
   * @returns массив объектов
   */

  async getInitiator(
    search: string,
    contractId: number
  ): Promise<StringTypedResponse> {
    const urlReq = `api/RefEntity/DropDownList/person?subListId=${contractId}`;
    try {
      const res = await this._http.get(urlReq, {
        params: { lang: "ru", search: search }
      });
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * апи для получения списка командировок
   * @param search - данные из поля поиска
   * @returns массив объектов
   */

  async getTrips(
    search?: string,
    page?: number,
    orderBy?: string,
    orderDir?: boolean
  ): Promise<StringTypedResponse> {
    const urlReq = "api/Trip";
    try {
      const res = await this._http.get(urlReq, {
        params: { search, page, orderBy, orderDir }
      });
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * апи для получения заказов по номеру командировки
   * @param tripId - Id командировки
   */

  async getTripOrders(tripId: number): Promise<StringTypedResponse> {
    const urlReq = `/api/Trip/${tripId}/Order`;
    try {
      const res = await this._http.get(urlReq, {
        params: { tripId }
      });
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * апи для получения списка типов заказа
   * @returns массив объектов
   */

  async getOrderTypeId(): Promise<StringTypedResponse> {
    const urlReq = "api/RefEntity/DropDownList/OrderType";
    try {
      const res = await this._http.get(urlReq);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * апи для создания заказа
   *
   */

  async sendOrder(order: OrderCreateReqType): Promise<StringTypedResponse> {
    const urlReq = "api/Order";
    try {
      const res = await this._http.post(urlReq, order);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * апи для получения предложений по номеру заказа
   * @param orderId - id заказа
   *
   */

  async getOrderOffers(orderId: number): Promise<StringTypedResponse> {
    const urlReq = `api/Order/${orderId}`;
    try {
      const res = await this._http.get(urlReq);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * поиск города
   * @param city  -- город
   */
  async getCity(city: string): Promise<StringTypedResponse> {
    const urlReq = "api/RefEntity/DropDownList/City";
    try {
      const res = await this._http.get(urlReq, {
        params: { lang: "ru", search: city }
      });
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  async searchHotels(
    searchData: HotelSearchRequest
  ): Promise<StringTypedResponse> {
    const urlReq = "/api/Hotel/Search";
    try {
      const res = await this._http.post(urlReq, searchData);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  async getHotelTree(
    hotelTree: HotelTreeRequest
  ): Promise<StringTypedResponse> {
    const urlReq = "/api/Hotel/GetTree";
    try {
      const res = await this._http.post(urlReq, hotelTree);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  async getHotelPricing(
    accessors: BaseHotelAccesor[],
    cityId: number
  ): Promise<StringTypedResponse> {
    const urlReq = "/api/Hotel/Pricing";
    try {
      const sendData = {
        accessors,
        cityId
      };
      const res = await this._http.post(urlReq, sendData);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }
  /**
   * Общий Дроп поиск
   * @param type
   * @param subListId
   * @param search
   * @param id
   */
  async getDropList(
    type = "person",
    search: string | null,
    subListId: number | null,
    id: number | null = null
  ): Promise<StringTypedResponse> {
    let urlReq = `api/RefEntity/DropDownList/${type}`;
    if (id) {
      urlReq += `/${id}`;
    }
    const params = {
      subListId,
      search
    };
    try {
      const res = await this._http.get(urlReq, { params });
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * Бронирование авия
   * @param PersonList
   * @param OfferId
   */
  async bookingAvia(
    PersonList: Array<PersonType>,
    OfferId: string | number
  ): Promise<StringTypedResponse> {
    const urlReq = `/api/Avia/Booking/${OfferId}`;
    const sendData = PersonList;
    try {
      const res = await this._http.post(urlReq, sendData);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /** RAIL */

  /**
   * поиск жд станции
   * @param search  -- станция
   */
  async getRailStation(search: string): Promise<StringTypedResponse> {
    const urlReq = "api/RefEntity/DropDownList/RailwayStation";
    try {
      const res = await this._http.get(urlReq, {
        params: { lang: "ru", search: search }
      });
      return res.data;
    } catch (err) {
      const errRes: StringTypedResponse = {
        errors: ["Ошибка сервера или нет соединения!"],
        isWarning: true,
        isValid: false,
        warnings: ["Ошибка сервера или нет соединения!"],
        countPage: 0,
        object: {}
      };
      return errRes;
    }
  }
  /**
   * поиск жд билетов
   * @param searchData  -- поисковой запрос
   */

  async searchRail(
    searchData: RailwaySearchRequest
  ): Promise<StringTypedResponse> {
    const urlReq = "/api/Railway/Search";
    try {
      const res = await this._http.post(urlReq, searchData);
      return res.data;
    } catch (err) {
      const errRes: StringTypedResponse = {
        errors: ["Ошибка сервера или нет соединения!"],
        isWarning: true,
        isValid: false,
        warnings: ["Ошибка сервера или нет соединения!"],
        countPage: 0,
        object: {}
      };
      return errRes;
    }
  }
  /**
   * запрос прайсинга для ЖД
   */

  async getRailPricing(
    reqObject: RailwayPricingRequest
  ): Promise<StringTypedResponse> {
    const urlReq = "/api/Railway/Pricing";
    try {
      const res = await this._http.post(urlReq, reqObject);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }
  /**
   * Выписка билета
   * @param PersonList
   * @param OfferId
   */
  async ticketingAvia(OfferId: string | number): Promise<StringTypedResponse> {
    const urlReq = `/api/Avia/Ticketing/${OfferId}`;
    try {
      const res = await this._http.post(urlReq);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * Отмена бронирования
   * @param PersonList
   * @param OfferId
   */
  async cancelAvia(OfferId: string | number): Promise<StringTypedResponse> {
    const urlReq = `/api/Avia/Cancel/${OfferId}`;
    try {
      const res = await this._http.post(urlReq);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }
  /**
   * Загрузка билета
   * @param TicketId
   */
  async loadTiket(TicketId: string | number): Promise<StringTypedResponse> {
    const urlReq = `/api/Order/Ticket/${TicketId}`;
    try {
      const res = await this._http.get(urlReq, { responseType: "arraybuffer" });

      return {
        errors: [],
        isWarning: false,
        isValid: false,
        warnings: [],
        countPage: 0,
        object: {
          blobPart: res.data as BlobPart,
          type: res.headers["content-type"]
        }
      };
    } catch (err) {
      return this.errRes;
    }
  }

  /**
   * запрос бюджетных кодов
   */

  async getBudgetCodes(
    ContractId: number,
    offerPersonId: number
  ): Promise<StringTypedResponse> {
    const urlReq = `/api/RefEntity/Code/${ContractId}?offerPersonId=${offerPersonId}`;
    try {
      const res = await this._http.get(urlReq);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }
  /**
   * запрос персональных кодов
   */

  async getPersonCodes(PersonId: number): Promise<StringTypedResponse> {
    const urlReq = `/api/RefEntity/PersonCode/${PersonId}`;
    try {
      const res = await this._http.get(urlReq);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }

  async SendTrip(OrderId: number, email: string): Promise<StringTypedResponse> {
    const urlReq = `/api/Trip/Send/${OrderId}`;
    try {
      const sendData = { email };
      const res = await this._http.post(urlReq, sendData);
      return res.data;
    } catch (err) {
      return this.errRes;
    }
  }
}
