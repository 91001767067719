
import { TYPE_DROPLIST } from "@/constant";
import { PersonType } from "@/interfaceAndType/typesShared";
import { useStore } from "@/store/modules";
import { defineComponent, PropType, reactive, ref, toRefs } from "vue";
import { ActionTypes } from "../../../store/modules/shared/contract";
import { CodeValueType } from "../../../interfaceAndType/typesShared";
export default defineComponent({
  name: "ShowPerson",
  props: {
    dataShow: {
      type: Object as PropType<PersonType>,
      required: true
    },
    ContractId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    // нужно получить всю инфу о пользователе
    const store = useStore();
    const { dataShow, ContractId } = toRefs(props);
    const getDataById = async (id: number | null, type: string) => {
      const paramsIn = {
        type: type,
        search: null,
        subListId: null,
        id: id
      };
      if (id) {
        const res = await store.dispatch(ActionTypes.SEARH_DROPLIST, paramsIn);
        if (res) {
          return res[0];
        }
      }
      return false;
    };

    const personType = TYPE_DROPLIST.PERSON;
    const docType = TYPE_DROPLIST.DOCUMENT;
    const bonusType = TYPE_DROPLIST.BONUS_CARD;
    const emailType = TYPE_DROPLIST.CONTAC_EMAIL;
    const phoneType = TYPE_DROPLIST.CONTACT_MOBILE;
    const personData = reactive({
      person: "",
      document: "",
      bonusCard: "",
      email: "",
      phone: ""
    });
    const budgetCodes = ref<CodeValueType[] | null>(null);
    const getbudgetCodes = async (contract, offerPerson) => {
      const res = await store.dispatch(ActionTypes.GET_BUDGET_CODES, {
        ContractId: contract,
        offerPersonId: offerPerson
      });
      if (res) {
        const converRes = res.map(item => {
          const convertResitem = item as any;
          if (convertResitem.isEnum && convertResitem.codeValue) {
            const findEl = convertResitem.codeEnumList?.find(
              el =>
                el.entityId == ((convertResitem.codeValue as unknown) as number)
            );
            if (findEl) {
              convertResitem.codeValue = findEl.entityName;
            }
          }
          convertResitem.codeValue = convertResitem.codeValue
            ? convertResitem.codeValue
            : "Нет значения";
          return convertResitem;
        });
        budgetCodes.value = converRes;
        return res;
      }
      return null;
    };
    const personCodes = ref<CodeValueType[] | null>(null);
    const getPersonCodes = async personId => {
      const res = await store.dispatch(ActionTypes.GET_PERSON_CODES, {
        PersonId: personId
      });
      if (res) {
        personCodes.value = res;
        return res;
      }
      return null;
    };

    const getDataPerson = async () => {
      const person = await getDataById(dataShow.value.personId, personType);
      if (person && person.entityName) {
        personData.person = person.entityName;
      }
      const document = await getDataById(dataShow.value.documentId, docType);
      if (document && document.entityName) {
        personData.document = document.entityName;
      }
      const bonusCard = await getDataById(
        dataShow.value.bonusCardId,
        bonusType
      );
      if (bonusCard && bonusCard.entityName) {
        personData.bonusCard = bonusCard.entityName;
      }
      const email = await getDataById(dataShow.value.emailId, emailType);
      if (email && email.entityName) {
        personData.email = email.entityName;
      }
      const phone = await getDataById(dataShow.value.phoneId, phoneType);
      if (phone && phone.entityName) {
        personData.phone = phone.entityName;
      }
    };

    getDataPerson();
    getbudgetCodes(ContractId.value, dataShow.value.offerPassengerId);
    getPersonCodes(dataShow.value.personId);
    return {
      personData,
      budgetCodes,
      getbudgetCodes,
      personCodes,
      getPersonCodes
    };
  }
});
