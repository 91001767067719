
import { defineComponent, PropType } from "@vue/runtime-core";
import { FreeOffer } from "../../../../interfaceAndType/typesServices";

export default defineComponent({
  name: "FreeServiceInfoblock",
  components: {},
  props: {
    item: {
      type: Object as PropType<FreeOffer>
    }
  },
  setup(props) {
    return {};
  }
});
