import { GetterTree } from "vuex";

import { State as RootState } from "../index";
import { State, Getters } from "./contract";

// getters
export const getters: GetterTree<State, RootState> & Getters = {
  getNameAirport: state => codeAiroport => {
    if (state.searhcData) {
      const listAirport = state.searhcData.referenceContainer.airports;
      const find = listAirport?.find(item => item.code === codeAiroport);
      return find && find.rus ? find.rus : codeAiroport;
    }
    return codeAiroport;
  },
  /**
   * фунция фильтрации и пагинации
   * @param state
   * @returns
   */
  getAviaVariants: state => {
    const avia = state;
    if (
      avia.hasSearch &&
      avia.resultSearch &&
      avia.searhcData &&
      avia.searhcData.variants
    ) {
      return avia.searhcData.variants;
    }
    return [];
  },
  /**
   * Получение тарифа
   * @param state
   * @returns
   */
  getTarifTiket: state => keytiket => {
    const avia = state;
    if (avia.pricingCach && avia.pricingCach[keytiket]) {
      return avia.pricingCach[keytiket];
    }
    return null;
  },
  /**
   * Получение определенного варианта
   * @param state
   * @returns
   */
  getVariantByKey: state => keytiket => {
    const avia = state;
    if (avia.searhcData && avia.searhcData.variants) {
      const findVariant = avia.searhcData.variants.find(
        item => item.key === keytiket
      );
      return findVariant ? findVariant : null;
    }
    return null;
  },
  getSelectedTickets: state => {
    if (state.selectedAviaTicket !== null && state.searhcData !== null) {
      const selected = state.searhcData!.variants?.filter(variant => {
        const find = state.selectedAviaTicket.find(
          item => item === variant.key
        );
        if (find) return true;
      });
      return selected;
    }
  }
};
