
import { defineComponent, PropType, toRefs } from "vue";

export default defineComponent({
  name: "EmailList",
  emits: ["set-emails", "add-email"],
  props: {
    emails: {
      type: Array as PropType<Array<string>>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { emails } = toRefs(props);
    const setData = (index, val) => {
      let nowData = [...emails.value];
      if (val.target.value == "" && index != 0) {
        nowData = nowData.filter((_el, indx) => indx !== index);
      } else {
        nowData[index] = val.target.value;
      }

      emit("set-emails", nowData);
    };
    const addEmail = () => emit("add-email");
    return { setData, addEmail };
  }
});
