import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "railData" }
const _hoisted_2 = {
  key: 2,
  class: "railData__timeData"
}
const _hoisted_3 = { class: "railData__timeData__time " }
const _hoisted_4 = { class: "railData__timeData__data pl18" }
const _hoisted_5 = { class: "railData__timeData__data__day" }
const _hoisted_6 = { class: "railData__timeData__data__dw" }
const _hoisted_7 = { class: "railData__timeData__data " }
const _hoisted_8 = { class: "railData__timeData__data__day" }
const _hoisted_9 = { class: "railData__timeData__data__dw" }
const _hoisted_10 = { class: "railData__timeData__time pl18" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.isLeft)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: ["railData__from", { 'railData__from--right': !_ctx.isLeft }]
        }, _toDisplayString(_ctx.item.TransferRouteList[0].City ? _ctx.item.TransferRouteList[0].City.Name : ''), 3))
      : _createCommentVNode("", true),
    (!_ctx.isLeft)
      ? (_openBlock(), _createBlock("div", {
          key: 1,
          class: ["railData__from", { 'railData__from--right': !_ctx.isLeft }]
        }, _toDisplayString(_ctx.item.TransferRouteList[_ctx.item.TransferRouteList.length - 1].City ? 
            _ctx.item.TransferRouteList[_ctx.item.TransferRouteList.length - 1].City.Name : ''), 3))
      : _createCommentVNode("", true),
    (_ctx.isLeft)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.item.TransferRouteList[0].Time), 1),
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, _toDisplayString(_ctx.parseDate(_ctx.item.TransferRouteList[0].Date, "DD MMM")), 1),
            _createVNode("div", _hoisted_6, _toDisplayString(_ctx.parseDate(_ctx.item.TransferRouteList[0].Date, "dddd")), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isLeft)
      ? (_openBlock(), _createBlock("div", {
          key: 3,
          class: ["railData__timeData", { 'railData__timeData--right': !_ctx.isLeft }]
        }, [
          _createVNode("div", _hoisted_7, [
            _createVNode("div", _hoisted_8, _toDisplayString(_ctx.parseDate(_ctx.item.TransferRouteList[_ctx.item.TransferRouteList.length - 1].Date, "DD MMM")), 1),
            _createVNode("div", _hoisted_9, _toDisplayString(_ctx.parseDate(_ctx.item.TransferRouteList[_ctx.item.TransferRouteList.length - 1].Date, "dddd")), 1)
          ]),
          _createVNode("div", _hoisted_10, _toDisplayString(_ctx.item.TransferRouteList[_ctx.item.TransferRouteList.length - 1].Time), 1)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}