import { State } from "./contract";
import { DropDownEntity } from "../../../interfaceAndType/typesShared";
// mutations enums
export enum MutationTypes {
  SET_ORDER_TYPES = "SET_ORDER_TYPES"
}
// Mutation contracts
export type Mutations<S = State> = {
  [MutationTypes.SET_ORDER_TYPES](
    state: S,
    payload: { types: Array<DropDownEntity> }
  ): void;
};
