
import moment from "moment";
import { defineComponent, PropType } from "vue";
import { NTrainListReference } from "../../../interfaceAndType/typesRail";

export default defineComponent({
  name: "RailTicketDate",
  props: {
    isLeft: { type: Boolean, default: true },
    item: {
      type: Object as PropType<NTrainListReference>
    }
  },
  setup(props) {
    const parseDate = (date, format) => {
      return moment(date).format(format);
    };
    return { parseDate };
  }
});
