
import { computed, defineComponent } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { path } from "../../../router/path";
export default defineComponent({
  setup() {
    const router = useRoute();
    const aviaSearch = path.aviaSearch;
    const railSearch = path.railSearch;
    const hotelSearch = path.hotelSearch;
    const service = path.service;
    const trips = path.trips;

    const activePath = (str) => {
      if(router.path.includes(str)) {
        return true
      }
      return false
    };

    return {
      router,
      aviaSearch,
      railSearch,
      hotelSearch,
      service,
      trips,
      activePath
    };
  }
});
