import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")

  return (_openBlock(), _createBlock(_component_AutoComplete, {
    minLength: _ctx.minLength,
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.selected = $event)),
    placeholder: _ctx.placeholder + `${_ctx.isImportant ? '*' : ''}`,
    field: _ctx.field,
    suggestions: _ctx.options,
    onComplete: _cache[2] || (_cache[2] = $event => (_ctx.onSearch($event))),
    onItemSelect: _ctx.setData,
    dropdown: true
  }, {
    item: _withCtx((slotProps) => [
      _createVNode("div", null, _toDisplayString(!!slotProps.item[_ctx.field]
            ? slotProps.item[_ctx.field]
            : slotProps.item[_ctx.alternativeField]), 1)
    ]),
    _: 1
  }, 8, ["minLength", "modelValue", "placeholder", "field", "suggestions", "onItemSelect"]))
}