
import { computed, defineComponent, ref, toRefs } from "vue";
import { NTrainListReference } from "../../../interfaceAndType/typesRail";
import { useStore } from "../../../store/modules";

export default defineComponent({
  name: "TrainTypeFilter",
  props: {
    filterByTrainTypes: {
      type: Function
    }
  },
  setup(props) {
    const store = useStore();
    const allTrains = computed(() => {
      const searchData: NTrainListReference[] | null = store.state.rail.trains;
      if (searchData) {
        const res: NTrainListReference[] = [];
        searchData.forEach(item => {
          const find = res.find(
            type => type.categoryTrain == item.categoryTrain
          );
          if (!find) {
            res.push(item);
          }
        });
        return res;
      }
      return [];
    });
    const selectedTrainType = ref([]);
    return { allTrains, selectedTrainType };
  }
});
