
import { useStore } from "@/store/modules";
import { ActionTypes } from "../../store/modules/trips/contract";
import { defineComponent, PropType, ref, toRefs } from "vue";
import { OrderDTOType } from "@/interfaceAndType/typesTrips";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  name: "SendMail",
  props: {
    order: {
      type: Object as PropType<OrderDTOType>,
      required: true
    }
  },
  setup(props) {
    const { order } = toRefs(props);
    const store = useStore();
    const toast = useToast();
    const email = ref("");
    const send = async () => {
      const res = await store.dispatch(ActionTypes.SEND_TRIP, {
        email: email.value,
        OrderId: order.value.orderId
      });
      if (res) {
        toast.add({
          severity: "success",
          summary: "Успешно",
          detail: "Письмо отправленно",
          life: 3000
        });
        email.value = "";
      }
      console.log(res);
    };
    return { send, email };
  }
});
