import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "personSelect"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_avia_tiket = _resolveComponent("avia-tiket")
  const _component_hotels_card_complex = _resolveComponent("hotels-card-complex")
  const _component_transfer_card = _resolveComponent("transfer-card")
  const _component_rail_tiket_small = _resolveComponent("rail-tiket-small")
  const _component_free_card = _resolveComponent("free-card")
  const _component_boking_form = _resolveComponent("boking-form")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.activeOffer)
      ? (_openBlock(), _createBlock(_component_boking_form, {
          key: 0,
          listOrders: _ctx.listOrders,
          selectTikets: _ctx.selectTikets,
          OfferId: _ctx.selectOfferData,
          activeOffer: _ctx.activeOffer,
          type: _ctx.type,
          initData: _ctx.initData
        }, {
          varians: _withCtx(() => [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.tikets, (item) => {
              return (_openBlock(), _createBlock(_Fragment, {
                key: item.offerId
              }, [
                (_ctx.isPersonSelect(item.offerData) && _ctx.isSelect(item.tiket))
                  ? (_openBlock(), _createBlock("div", _hoisted_1, " Этот вариант был одобрен "))
                  : _createCommentVNode("", true),
                (_ctx.isAvia(item.tiket) && _ctx.isSelect(item.tiket))
                  ? (_openBlock(), _createBlock(_component_avia_tiket, {
                      key: item.tiket.key,
                      item: item.tiket,
                      small: false,
                      btnTarrif: false,
                      tripId: -1,
                      isModeOffer: true,
                      onSelectOffer: $event => (_ctx.selectOffer($event, item.offerData)),
                      isCansel: _ctx.isSelect(item.tiket) && _ctx.selectTikets,
                      hasCansel: _ctx.isSelect(item.tiket) && _ctx.selectTikets,
                      onUnselectOffer: _cache[1] || (_cache[1] = $event => (_ctx.unselectOffer($event, null)))
                    }, null, 8, ["item", "onSelectOffer", "isCansel", "hasCansel"]))
                  : _createCommentVNode("", true),
                (_ctx.isHotel(item.tiket) && _ctx.isSelect(item.tiket))
                  ? (_openBlock(), _createBlock(_component_hotels_card_complex, {
                      key: item.tiket.hotelName.bs.en,
                      item: item.tiket,
                      isModeOffer: true,
                      onSelectOffer: $event => (_ctx.selectOffer($event, item.offerData)),
                      onUnselectOffer: _cache[2] || (_cache[2] = $event => (_ctx.unselectOffer($event, null))),
                      isCansel: _ctx.isSelect(item.tiket) && _ctx.selectTikets,
                      hasCansel: _ctx.isSelect(item.tiket) && _ctx.selectTikets
                    }, null, 8, ["item", "onSelectOffer", "isCansel", "hasCansel"]))
                  : _createCommentVNode("", true),
                (_ctx.isService(item.tiket) && _ctx.isSelect(item.tiket))
                  ? (_openBlock(), _createBlock(_component_transfer_card, {
                      key: item.tiket.SupplierContractId,
                      item: item.tiket,
                      isModeOffer: true,
                      onSelectOffer: $event => (_ctx.selectOffer($event, item.offerData)),
                      onUnselectOffer: _cache[3] || (_cache[3] = $event => (_ctx.unselectOffer($event, null))),
                      isCansel: _ctx.isSelect(item.tiket) && _ctx.selectTikets,
                      hasCansel: _ctx.isSelect(item.tiket) && _ctx.selectTikets
                    }, null, 8, ["item", "onSelectOffer", "isCansel", "hasCansel"]))
                  : _createCommentVNode("", true),
                (_ctx.isRail(item.tiket) && _ctx.isSelect(item.tiket))
                  ? (_openBlock(), _createBlock(_component_rail_tiket_small, {
                      key: item.tiket.numberTrain,
                      item: item.tiket,
                      isModeOffer: true,
                      onSelectOffer: $event => (_ctx.selectOffer($event, item.offerData)),
                      onUnselectOffer: _cache[4] || (_cache[4] = $event => (_ctx.unselectOffer($event, null))),
                      isCansel: _ctx.isSelect(item.tiket) && _ctx.selectTikets,
                      hasCansel: _ctx.isSelect(item.tiket) && _ctx.selectTikets
                    }, null, 8, ["item", "onSelectOffer", "isCansel", "hasCansel"]))
                  : _createCommentVNode("", true),
                (_ctx.isFree(item.tiket) && _ctx.isSelect(item.tiket))
                  ? (_openBlock(), _createBlock(_component_free_card, {
                      key: item.tiket.SupplierContractId,
                      item: item.tiket,
                      isModeOffer: true,
                      onSelectOffer: $event => (_ctx.selectOffer($event, item.offerData)),
                      onUnselectOffer: _cache[5] || (_cache[5] = $event => (_ctx.unselectOffer($event, null))),
                      isCansel: _ctx.isSelect(item.tiket) && _ctx.selectTikets,
                      hasCansel: _ctx.isSelect(item.tiket) && _ctx.selectTikets
                    }, null, 8, ["item", "onSelectOffer", "isCansel", "hasCansel"]))
                  : _createCommentVNode("", true)
              ], 64 /* STABLE_FRAGMENT */))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1
        }, 8, ["listOrders", "selectTikets", "OfferId", "activeOffer", "type", "initData"]))
      : _createCommentVNode("", true)
  ]))
}