
import { STATUS_ORDER } from "@/constant";
import {
  OfferListType,
  OfferTicketType,
  OrderDTOType
} from "@/interfaceAndType/typesTrips";
import { useStore } from "@/store/modules";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import { ActionTypes } from "../../store/modules/trips/contract";
export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();
    const type = computed(() => route.params.type);

    const aciveOffers = computed<Array<OrderDTOType> | null>(() => {
      return store.getters.getSelectOffer(type.value as string);
    });

    const convertOffert = computed(() => {
      const res: Array<{
        offer: OfferListType;
        offerTicket: OfferTicketType[];
      }> = [];
      if (aciveOffers.value) {
        aciveOffers.value.forEach(item => {
          if (item.statusCode == STATUS_ORDER.TicketReady) {
            item.offerDTOList?.forEach(offer => {
              if (
                offer.offerTicketDTOList &&
                offer.offerTicketDTOList.length > 0
              ) {
                res.push({ offer, offerTicket: offer.offerTicketDTOList });
              }
            });
          }
        });
      }
      return res;
    });

    const loadDoc = async (TicketId: number, nameFile: string) => {
      const data = await store.dispatch(ActionTypes.LOAD_TIKET, { TicketId });
      if (data) {
        const res = data as { blobPart: BlobPart; type: string };
        const blob = new Blob([res.blobPart], {
          type: res.type
        });
        const fileName = nameFile;
        if (window.navigator.msSaveOrOpenBlob) {
          // IE11
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      }
    };
    return { convertOffert, loadDoc };
  }
});
