import { GetterTree } from "vuex";

import { State as RootState } from "../index";
import { State, Getters } from "./contract";

// getters
export const getters: GetterTree<State, RootState> & Getters = {
  getSelectedTrains: (state) => {
    if (state.trainWithSeats) {
      return Object.values(state.trainWithSeats);
    }
    return [];
  },
  getPassengersCount: (state) => {
    return {
      adults: state.passengersCount,
      children: 0,
      infants: 0,
    };
  },
};
