
import { defineComponent, PropType } from "@vue/runtime-core";
import { FreeOffer } from "../../../../interfaceAndType/typesServices";

export default defineComponent({
  name: "TransferFooter",
  components: {},
  props: {
    item: {
      type: Object as PropType<FreeOffer>,
    },
    isModeOffer: {
      type: Boolean,
      default: false,
    },
    isCansel: {
      type: Boolean,
      default: false,
    },
    hasCansel: {
      type: Boolean,
      default: false,
    },
    hasControl: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["select-offer", "unselect-offer"],
  setup(props, { emit }) {
    const selectData = () => {
      emit("select-offer");
    };
    const unselect = () => {
      emit("unselect-offer", null);
    };
    return { selectData, unselect };
  },
});
