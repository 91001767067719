import { State } from "./contract";
import { BusinessTripType, OrderDTOType, TripFullDTO } from "@/interfaceAndType/typesTrips";
import { TripWithOrders } from "../../../interfaceAndType/typesTrips";
// mutations enums
export enum MutationTypes {
  SET_TRIPS = "SET_TRIPS",
  SET_TRIP_TICKET_CACHE = "SET_TRIP_TICKET_CACHE",
  CLEAR_CACHE = "CLEAR_CACHE",
  SET_TRIP_ORDER_OFFERS_CACHE = "SET_TRIP_ORDER_OFFERS_CACHE",
  //---------------------------------------------------
  SET_ACTIVE_ORDER = "SET_ACTIVE_ORDER",
  CLEAR_ACTIVE_ORDER = "CLEAR_ACTIVE_ORDER",
  //----------------------------------------------------
  SET_ACTIVE_FILTER_TYPE = "SET_ACTIVE_FILTER_TYPE", // выбранный тип авия или дургие
  SET_ACTIVE_FILTER_OFFER = "SET_ACTIVE_FILTER_OFFER", // выбранное предложение
  //--------------------------------------------------
  LOAD_ACCEPT_TRIP = "LOAD_ACCEPT_TRIP",
  SELECT_ACCEPT_TRIP="SELECT_ACCEPT_TRIP"
}
// Mutation contracts
export type Mutations<S = State> = {
  [MutationTypes.SET_TRIPS](
    state: S,
    payload: { trips: Array<BusinessTripType>; pageCount: number }
  ): void;
  [MutationTypes.SET_TRIP_TICKET_CACHE](
    state: S,
    payload: { tripId: number; tickets: TripWithOrders }
  ): void;
  [MutationTypes.SET_TRIP_ORDER_OFFERS_CACHE](
    state: S,
    payload: { orderId: number; orderData: OrderDTOType }
  ): void;
  [MutationTypes.CLEAR_CACHE](state: S, payload: {}): void;
  [MutationTypes.SET_ACTIVE_ORDER](
    state: S,
    payload: { data: Array<OrderDTOType> }
  ): void;
  [MutationTypes.CLEAR_ACTIVE_ORDER](state: S, payload: {}): void;
  [MutationTypes.SET_ACTIVE_FILTER_TYPE](
    state: S,
    payload: { code: string | null }
  ): void;
  [MutationTypes.SET_ACTIVE_FILTER_OFFER](
    state: S,
    payload: { key: string | number | null }
  ): void;

  [MutationTypes.LOAD_ACCEPT_TRIP](
    state: S,
    payload: { data: TripFullDTO }
  ): void;

};
