
import SubHeader from "../components/shared/SubHeader.vue";
import AviaFilter from "../components/filters/AviaFilters.vue";
import AviaTiket from "../components/tikets/avia/AviaTikets.vue";
import OrderCreateModal from "../components/modals/OrderCreateModal.vue";

import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useStore } from "../store/modules";
import AviaTarif from "../components/modals/AviaTarif.vue";
import {
  Itinerary,
  MultiItinerary,
  ReferenceAirline,
  ReferenceAirport
} from "../interfaceAndType/typesSearh";
import { MutationTypes } from "../store/modules/avia/MutationsType";
import { ActionTypes } from "../store/modules/order/contract";
import { ORDER_TYPES } from "../constant";
import { path } from "@/router/path";
export default defineComponent({
  name: "AviaSearchResult",
  components: {
    SubHeader,
    AviaFilter,
    AviaTiket,
    AviaTarif,
    OrderCreateModal
  },
  setup() {
    const store = useStore();
    const blockWatch = ref(false);
    type DS = { show: boolean };
    const display = ref<boolean>(false);
    const passengersCount = computed(() => {
      return {
        adults: store.state.avia.serachReq?.adults,
        children: store.state.avia.serachReq?.children,
        infants: store.state.avia.serachReq?.infants
      };
    });
    const toggleDisplay = () => {
      display.value = !display.value;
    };
    const variants = computed(() => {
      console.log("variant");
      return store.getters.getAviaVariants;
    });
    const countVariant = computed(() => {
      return variants.value.length;
    });
    const showModalTarrif = ref<boolean>(false);
    const showOrderModal = ref<boolean>(false);

    const keyActive = ref<string>("");
    const showTarrif = (item: MultiItinerary) => {
      keyActive.value = item && item.key ? item.key : "";
      showModalTarrif.value = true;
      blockWatch.value = true;
    };

    const closeModal = (val = false) => {
      showModalTarrif.value = val;
      setTimeout(() => {
        blockWatch.value = false;
      }, 1000);
    };
    const openOrderModal = () => {
      showOrderModal.value = true;
    };
    const closeOrderModal = (val = false) => {
      showOrderModal.value = val;
    };
    const paginationRows = ref<number>(5);
    const paginatorFirst = ref<number>(0);
    const filteredVariants = ref<Array<MultiItinerary>>(variants.value);
    const paginatedVariants = ref<Array<MultiItinerary>>(
      filteredVariants.value.slice(0, paginationRows.value)
    );
    const onPage = event => {
      paginatedVariants.value = filteredVariants.value.slice(
        event.first,
        event.first + paginationRows.value
      );
      paginatorFirst.value = event.first;
    };

    const acceptFilterAirlines = ref<Array<string>>([]);
    const acceptFilterAirportFrom = ref<Array<string>>([]);
    const acceptFilterAirportTo = ref<Array<string>>([]);

    const filterByCompany = (item: ReferenceAirline) => {
      const find = acceptFilterAirlines.value.find(code => code == item.code);
      if (find) {
        acceptFilterAirlines.value = acceptFilterAirlines.value.filter(
          code => code != item.code
        );
      } else {
        acceptFilterAirlines.value.push(item.code!);
      }
    };

    const filterByAirportFrom = (codeInput: Array<string>) => {
      acceptFilterAirportFrom.value = codeInput;
    };

    const filterByAirportTo = (codeInput: Array<string>) => {
      acceptFilterAirportTo.value = codeInput;
    };

    const applyFilterByCompany = (
      variant: MultiItinerary,
      acceptFilterAirlines: Array<string>
    ) => {
      let result = false;
      if (acceptFilterAirlines.length == 0) {
        return true;
      }
      variant.itineraries!.forEach(itinerary => {
        itinerary.legs!.forEach(leg => {
          leg.flights!.forEach(flight => {
            if (flight.airline) {
              const find = acceptFilterAirlines.find(
                code => code == flight.airline
              );
              if (find) {
                result = true;
              }
            }
          });
        });
      });
      return result;
    };

    const applyFilterAirportFrom = (
      variant: MultiItinerary,
      acceptFilterAirportFrom: Array<string>
    ) => {
      let result = false;
      if (acceptFilterAirportFrom.length == 0) {
        return true;
      }
      variant.itineraries!.forEach(itinerary => {
        itinerary.legs!.forEach(leg => {
          leg.flights!.forEach(flight => {
            if (flight.airportDeparture) {
              const find = acceptFilterAirportFrom.find(
                code => code == flight.origin
              );
              if (find) {
                result = true;
              }
            }
          });
        });
      });
      return result;
    };

    const applyFilterAirportTo = (
      variant: MultiItinerary,
      acceptFilterAirportTo: Array<string>
    ) => {
      let result = false;
      if (acceptFilterAirportTo.length == 0) {
        return true;
      }
      variant.itineraries!.forEach(itinerary => {
        itinerary.legs!.forEach(leg => {
          leg.flights!.forEach(flight => {
            if (flight.airportArrival) {
              const find = acceptFilterAirportTo.find(
                code => code == flight.destination
              );
              if (find) {
                result = true;
              }
            }
          });
        });
      });
      return result;
    };

    const sortingDest = ref<string>("ASC");

    const sortByPrice = () => {
      if (sortingDest.value === "DESC") {
        paginatedVariants.value = filteredVariants.value.sort(
          (a: MultiItinerary, b: MultiItinerary) => {
            return a.equivTotal - b.equivTotal;
          }
        );
        sortingDest.value = "ASC";
      } else if (sortingDest.value === "ASC") {
        paginatedVariants.value = filteredVariants.value.sort(
          (a: MultiItinerary, b: MultiItinerary) => {
            return b.equivTotal - a.equivTotal;
          }
        );
        sortingDest.value = "DESC";
      }
      onPage({ first: 0 });
      return 0;
    };

    const sortByDuration = () => {
      if (sortingDest.value === "DESC") {
        paginatedVariants.value = filteredVariants.value.sort(
          (a: MultiItinerary, b: MultiItinerary) => {
            return (
              +a.itineraries!.map(itinerary =>
                itinerary.legs!.map(leg => leg.durationMinutes)
              ) -
              +b.itineraries!.map(itinerary =>
                itinerary.legs!.map(leg => leg.durationMinutes)
              )
            );
          }
        );
        sortingDest.value = "ASC";
      } else if (sortingDest.value === "ASC") {
        paginatedVariants.value = filteredVariants.value.sort(
          (a: MultiItinerary, b: MultiItinerary) => {
            return (
              +b.itineraries!.map(itinerary =>
                itinerary.legs!.map(leg => leg.durationMinutes)
              ) -
              +a.itineraries!.map(itinerary =>
                itinerary.legs!.map(leg => leg.durationMinutes)
              )
            );
          }
        );
        sortingDest.value = "DESC";
      }
      onPage({ first: 0 });
      return 0;
    };

    const acceptFilter = () => {
      const res: Array<MultiItinerary> = [];
      variants.value.forEach(variant => {
        let result = false;
        result =
          applyFilterByCompany(variant, acceptFilterAirlines.value) &&
          applyFilterAirportFrom(variant, acceptFilterAirportFrom.value) &&
          applyFilterAirportTo(variant, acceptFilterAirportTo.value);
        if (result) {
          res.push(variant);
        }
      });
      filteredVariants.value = res;
      onPage({ first: 0 });
    };

    const isSelected = key => {
      let res = false;
      if (filteredVariants.value) {
        const selected = store.state.avia.selectedAviaTicket.find(
          item => item === key
        );
        if (selected) res = true;
      }
      return res;
    };

    const deleteTicket = key => {
      store.commit(MutationTypes.DELETE_SELECTED_AVIA_TICKET, {
        ticketKey: key
      });
    };

    const orderType = computed(() => {
      return store.getters.getOrderTypeId(ORDER_TYPES.AVIA);
    });
    acceptFilter();
    const linkBack = path.aviaSearch;

    watch(
      () => [...variants.value],
      () => {
        if (!blockWatch.value) {
          filteredVariants.value = variants.value ? variants.value : [];
          paginatedVariants.value = filteredVariants.value
            ? filteredVariants.value.slice(0, paginationRows.value)
            : [];
        }
      },
      { deep: true }
    );

    return {
      display,
      toggleDisplay,
      variants,
      countVariant,
      showTarrif,
      showModalTarrif,
      showOrderModal,
      closeModal,
      openOrderModal,
      closeOrderModal,
      keyActive,
      paginationRows,
      paginatorFirst,
      onPage,
      acceptFilterAirlines,
      acceptFilterAirportFrom,
      acceptFilterAirportTo,
      filterByCompany,
      acceptFilter,
      filteredVariants,
      paginatedVariants,
      filterByAirportFrom,
      filterByAirportTo,
      sortByPrice,
      sortByDuration,
      isSelected,
      deleteTicket,
      passengersCount,
      orderType,
      linkBack
    };
  }
});
