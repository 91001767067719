import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_AutoComplete, {
      modelValue: _ctx.selectedCountries,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.selectedCountries = $event)),
      field: "complexName",
      placeholder: _ctx.placeholder,
      suggestions: _ctx.countries,
      loading: _ctx.isLoading,
      onComplete: _cache[2] || (_cache[2] = $event => (_ctx.asyncFind($event))),
      onItemSelect: _cache[3] || (_cache[3] = $event => (_ctx.select($event)))
    }, null, 8, ["modelValue", "placeholder", "suggestions", "loading"])
  ]))
}