import { getClearPerson } from "@/helpers/getClearPerson";
import getElement from "@/helpers/getElement";
import { FreeOffer, transferOffer } from "@/interfaceAndType/typesServices";
import { PersonaMainInterface } from "@/interfaceAndType/typesShared";
import { BookingAviaTemp } from "@/interfaceAndType/typesTrips";
/**
 * хук по создание пассажира в трансферах
 * @param transfer
 */
export default function useCreatePassenger(transfer: transferOffer | FreeOffer | BookingAviaTemp ) {
  // persone
  /**
   * добавление персоны
   */
  const addPerson = () => {
    const clearPerson = getClearPerson();
    transfer.PersonList.push(clearPerson);
  };
  /**
   * установка значение для персоны
   * */

  const setPerson = (index, data: PersonaMainInterface) => {
    const { passenger, document, bonusCard, email, phone, budgetCodes } = data;
    transfer.PersonList[index].personId = getElement(passenger);
    transfer.PersonList[index].documentId = getElement(document);
    transfer.PersonList[index].bonusCardId = getElement(bonusCard);
    transfer.PersonList[index].emailId = getElement(email);
    transfer.PersonList[index].phoneId = getElement(phone);
    transfer.PersonList[index].budgetCodeValues = budgetCodes;
  };
  /**
   * Удаление персоны
   */
  const deletePersone = (index: number) => {
    transfer.PersonList.splice(index, 1);
  };
  // persone end

  return {
    addPerson,
    setPerson,
    deletePersone
  };
}
