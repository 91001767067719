import { MutationTree } from "vuex";
import { Mutations, MutationTypes } from "./MutationsType";
import { State } from "./contract";
// Define mutations
export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SAVE_HOTEL_DATA](state: State, { data }) {
    localStorage.setItem("tempdatasearhqhotel", JSON.stringify(data));
    state.searchReq = data;
  },
  [MutationTypes.SET_HOTEL_DATA](state: State, { data }) {
    localStorage.setItem("tempdatasearh", JSON.stringify(data.items));
    state.hotels = data.items;
  },
  [MutationTypes.SET_HOTEL_PRICE](state: State, { data, key }) {
    state.pricingCache[key] = data;
  },
  [MutationTypes.SELECT_ROOM](state: State, { data, key }) {
    state.selectedRooms[key] = data;
  },
  [MutationTypes.UNSELECT_ROOM](state: State, { key }) {
    delete state.selectedRooms[key];
  }
};
