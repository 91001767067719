
import { defineComponent } from "vue";

export default defineComponent({
  name: "SubHeader",
  props: {
    title: { type: String, default: "Командировки" },
    subText: {
      type: String,
      default: "Выберите командировку или добавьте новую"
    },

    isMain: { type: Boolean, default: true },

    canCreate: {
      type: Boolean,
      default: true
    },
    canClose: {
      type: Boolean,
      default: false
    },
    linkBack: {
      type: String,
      default: "/"
    },
    openOrderModal: {
      type: Function
    }
  },
  setup() {
    const trip = "/trip";
    return {
      trip
    };
  }
});
