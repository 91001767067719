import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "base-input-wrapper" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createBlock("label", {
          key: 0,
          for: _ctx.name
        }, _toDisplayString(_ctx.label), 9, ["for"]))
      : _createCommentVNode("", true),
    (!_ctx.textarea && !_ctx.password)
      ? _withDirectives((_openBlock(), _createBlock("input", {
          key: 1,
          id: _ctx.name,
          type: _ctx.type,
          name: _ctx.name,
          placeholder: _ctx.placeholder,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.val = $event)),
          class: "base-text-input",
          disabled: _ctx.disabled
        }, null, 8, ["id", "type", "name", "placeholder", "disabled"])), [
          [_vModelDynamic, _ctx.val]
        ])
      : _createCommentVNode("", true),
    (_ctx.textarea)
      ? _withDirectives((_openBlock(), _createBlock("textarea", {
          key: 2,
          id: _ctx.name,
          name: _ctx.name,
          placeholder: _ctx.placeholder,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (_ctx.val = $event)),
          rows: _ctx.rows,
          cols: _ctx.cols,
          class: "base-input-textarea",
          disabled: _ctx.disabled
        }, null, 8, ["id", "name", "placeholder", "rows", "cols", "disabled"])), [
          [_vModelText, _ctx.val]
        ])
      : _createCommentVNode("", true)
  ]))
}