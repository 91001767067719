import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "transferCard hoverShadow" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_free_service_header = _resolveComponent("free-service-header")
  const _component_free_service_main = _resolveComponent("free-service-main")
  const _component_separate_line = _resolveComponent("separate-line")
  const _component_free_service_footer = _resolveComponent("free-service-footer")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_free_service_header, { item: _ctx.item }, null, 8, ["item"]),
    _createVNode(_component_free_service_main, { item: _ctx.item }, null, 8, ["item"]),
    _createVNode(_component_separate_line),
    _createVNode(_component_free_service_footer, {
      item: _ctx.item,
      onSelectOffer: _ctx.selectOffer,
      onUnselectOffer: _cache[1] || (_cache[1] = $event => (_ctx.$emit('unselect-offer', null))),
      isModeOffer: _ctx.isModeOffer,
      isCansel: _ctx.isCansel,
      hasCansel: _ctx.hasCansel,
      hasControl: _ctx.hasControl
    }, null, 8, ["item", "onSelectOffer", "isModeOffer", "isCansel", "hasCansel", "hasControl"])
  ]))
}