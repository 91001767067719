
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CookieBar",
  setup() {
    const accepted = ref<boolean>(false);

    const acceptCookie = () => {
      document.cookie = "accepted=true";
      accepted.value = true;
    };

    const checkCookie = () => {
      if (
        document.cookie
          .split(";")
          .filter(item => item.includes("accepted=true")).length
      ) {
        accepted.value = true;
      }
    };

    checkCookie();
    return { accepted, acceptCookie };
  }
});
