// import store from "../store";
import { useStore, Store } from "../store/modules";
import { ActionTypes } from "../store/modules/auth/contract";
import { MutationTypes as AuthMutation } from "../store/modules/auth/MutationsType";
import { path } from "../router/path";
import { ActionTypes as ActionTypesOrder } from "../store/modules/order/contract";
import {
  LAST_TIME_CHECK,
  EXP_TIME_TOKEN,
  MAX_TIME_LIVE_TOKEN
} from "../constant";
import { ApiService } from "@/utils/ApiService";
import moment from "moment";
const FORMAT_DATA_CONVERT = "DD.MM.YYYYTHH:mm";

import { MutationTypes as SystemMutationTypes } from "../store/modules/system/MutationsType";
import { EVENT_LOADER } from "@/store/modules/system/contract";
/**
 * Пингует сервер
 * @returns
 */
const serverPing = async (store: Store) => {
  const apiService = new ApiService();
  const lastTimePing = localStorage.getItem(LAST_TIME_CHECK);
  const timeNow = moment();
  let diffTime = 0;
  let ping = true;
  if (lastTimePing) {
    const lastPing = moment(lastTimePing, FORMAT_DATA_CONVERT);
    diffTime = timeNow.diff(lastPing, "minute");
  }
  if (diffTime > 5 || !lastTimePing) {
    store.commit(SystemMutationTypes.LOADER_SET, {
      event: EVENT_LOADER.DEF_LOUDER
    });

    ping = await apiService.authApi.pingServer();
    localStorage.setItem(LAST_TIME_CHECK, timeNow.format(FORMAT_DATA_CONVERT));
    store.commit(SystemMutationTypes.LOADER_SET, {
      event: EVENT_LOADER.DEF_LOUDER,
      show: false
    });
  }
  return ping;
};
/**
 * Обновляем токен
 * @param store
 */
const refreshToken = async (store: Store) => {
  const timeNow = moment();
  const expTime = localStorage.getItem(EXP_TIME_TOKEN);
  let needRefresh = true;
  if (expTime) {
    const lastRefreshToken = moment(expTime, FORMAT_DATA_CONVERT);
    if (timeNow.diff(lastRefreshToken, "minute") < MAX_TIME_LIVE_TOKEN) {
      needRefresh = false;
    }
  }
  if (needRefresh) {
    const apiService = new ApiService();
    const newToken = await apiService.authApi.refreshToken();
    if (newToken.object) {
      const token = newToken.object as string;
      store.commit(AuthMutation.SET_NEW_TOKEN, { token });
      localStorage.setItem(EXP_TIME_TOKEN, timeNow.format(FORMAT_DATA_CONVERT));
    }
  }
};

export const ifAuthenticated = async (
  to: any,
  from: any,
  next: any
): Promise<any> => {
  const store = useStore();
  const isAuthenticated = store.getters.isAuthenticated;
  const hasProfile = store.getters.hasProfile;
  if (isAuthenticated) {
    const ping = await serverPing(store);

    if (!ping) {
      next(path.login);
      return;
    }
    refreshToken(store);
    if (hasProfile) {
      next();
    } else {
      const res = await store.dispatch(ActionTypes.AUTH_ME);
      if (res) {
        await store.dispatch(ActionTypesOrder.GET_ORDERTYPE);
        next();
      } else {
        next(path.login);
      }
    }
  } else {
    next(path.login);
  }
};
