
import { computed, defineComponent, toRefs } from "@vue/runtime-core";

export default defineComponent({
  name: "BaseInput",
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "text"
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      required: true
    },
    textarea: {
      type: Boolean,
      default: false
    },
    password: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 3
    },
    cols: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["input"],
  setup(props, { emit }) {
    const { value } = toRefs(props);
    const val = computed({
      get: () => value.value,
      set: newVal => emit("input", newVal)
    });
    return { val };
  }
});
