
import { computed, defineComponent, PropType, ref, toRefs } from "vue";
import { useStore } from "../../store/modules";
import { EVENT_LOADER } from "../../store/modules/system/contract";
export default defineComponent({
  name: "MainLoader",
  props: {
    name: {
      type: Array as PropType<Array<string>>,
      default: () => [EVENT_LOADER.DEF_LOUDER]
    }
  },
  setup(props) {
    const { name } = toRefs(props);
    const store = useStore();
    const show = computed(() => {
      let showTemp = false;
      store.state.system.showLoader.forEach(loader => {
        const find = name.value.find(i => i === loader.event);
        if (find && loader.show == true) {
          console;
          showTemp = true;
        }
      });
      return showTemp;
    });

    return { show };
  }
});
