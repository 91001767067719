import {
  // ActionContext,
  // GetterTree,
  Store as VuexStore,
  CommitOptions
  // DispatchOptions
} from "vuex";
import { GlobalMutations as Mutations } from "../allMutationsType";
// import { State as RootState } from "../index";

/**
 * Описание лоудера
 */
export type LoaderType = {
  event: EVENT_LOADER; //название события
  show: boolean; //его результат
  message: string; // сообщение
};
/**
 * Описание загрузчика
 */
export enum EVENT_LOADER {
  DEF_LOUDER = "DEF_LOUDER",
  LOAD_USER_DATA = "LOAD_USER_DATA",
  LOGIN_USER = "LOGIN_USER"
}
// Declare state
export type State = {
  errors: Array<string>;
  warnings: Array<string>;
  showLoader: Array<LoaderType>;
};

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  "commit" | "getters" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
};
// & {
//   getters: {
//     [K in keyof Getters]: ReturnType<Getters[K]>;
//   };
// } & {
//   dispatch<K extends keyof Actions>(
//     key: K,
//     payload: Parameters<Actions[K]>[1],
//     options?: DispatchOptions
//   ): ReturnType<Actions[K]>;
// }
