<template>
  <div class="login-page">
    <div class="container">
      <app-section
        :logo="false"
        :title="'Добро пожаловать!'"
        :descr="'Введите данные для входа'"
        :dopClass="'login_section'"
      >
        <template v-slot:content>
          <login-form />
          <login-footer />
        </template>
      </app-section>
    </div>
  </div>
</template>
<script>
import loginForm from "../components/components/login/LoginForm";
import loginFooter from "../components/components/login/LoginFooter";
export default {
  components: {
    loginForm,
    loginFooter
  }
};
</script>
<style lang="scss">
.login_section {
  height: calc(100vh - 47px) !important;
}
</style>
