<template>
  <div class="search-page search-page--services">
    <div class="container">
      <app-section :title="'Создать услугу'" :descr="'Введите нужные парметры'">
        <template v-slot:content>
          <service-form />
          <search-footer />
        </template>
      </app-section>
    </div>
  </div>
</template>
<script>
import ServiceForm from "../components/serviceForm/ServiceForm.vue";
export default {
  name: "ServicesSearch",
  components: {
    ServiceForm
  }
};
</script>
<style lang="scss" scoped></style>
