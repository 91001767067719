
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorMsgPerson",
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
