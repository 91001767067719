
import { computed, defineComponent, PropType, toRefs } from "vue";
import { ExtendedHotelInfo } from "../../../interfaceAndType/typesHotel";
import moment from "moment";
/**
 * более детальный билет
 */
export default defineComponent({
  name: "HotelRoomTiketSmall",
  props: {
    item: {
      type: Object as PropType<ExtendedHotelInfo>
    },
    isModeOffer: {
      type: Boolean,
      default: false
    }
  },
  emits: ["select-offer"],
  setup(props, { emit }) {
    const { item } = toRefs(props);
    const parseDate = (date, format) => {
      return moment(date).format(format);
    };
    const selectTiket = () => {
      emit("select-offer", item?.value);
    };

    const priceRoom = computed(() => {
      let res = 0;

      if (
        item &&
        item.value?.rooms &&
        item.value?.rooms[0]?.extendedRatePrice?.comparisionTotalPrice
      ) {
        res = item?.value?.rooms[0]?.extendedRatePrice?.comparisionTotalPrice;
      }
      if (!res && item && item.value?.rooms) {
        res = item?.value?.rooms[0].baseHotelRateAccessor.price;
      }
      return res;
    });
    const allService = computed(() => {
      if (item && item.value?.rooms) {
        const room = item?.value?.rooms[0];
        const res = {
          cond: false,
          news: false,
          clean: true,
          wifi: room.hasInternet,
          food: room.hasBreakfast,
          bath: room.hasBathroom
        };
        return res;
      }

      return false;
    });
    const roomDescription = computed(() => {
      if (item && item.value?.rooms) {
        const room = item?.value?.rooms[0];
        return room.roomDescription &&
          room.roomDescription.bs &&
          room.roomDescription.bs.rus
          ? room.roomDescription.bs.rus
          : "";
      }
      return false;
    });
    const roomName = computed(() => {
      if (item && item.value?.rooms) {
        const room = item?.value?.rooms[0];
        return room.roomName && room.roomName.bs && room.roomName.bs.rus
          ? room.roomName.bs.rus
          : "";
      }
      return false;
    });
    const cancelationPolicies = computed(() => {
      if (item && item.value?.rooms) {
        const room = item?.value?.rooms[0];
        return room.cancelationPolicies ? room.cancelationPolicies : [];
      }
      return [];
    });
    return {
      parseDate,
      selectTiket,
      priceRoom,
      allService,
      roomDescription,
      roomName,
      cancelationPolicies
    };
  }
});
