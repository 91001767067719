
import { ExtendedHotelInfo } from "@/interfaceAndType/typesHotel";
import { computed, defineComponent, PropType, ref, toRefs } from "vue";
import HotelRoomTiketSmall from "../HotelRoomTiketSmall.vue";

export default defineComponent({
  components: { HotelRoomTiketSmall },
  name: "HotelCardFooter",
  props: {
    toggleDisplay: {
      type: Function,
    },
    item: {
      type: Object as PropType<ExtendedHotelInfo>,
      required: true,
    },
    isModeOffer: {
      type: Boolean,
      default: false,
    },
    isCansel: {
      type: Boolean,
      default: false,
    },
    hasCansel: {
      type: Boolean,
      default: false,
    },
    hasControl: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["select-offer", "unselect-offer"],
  setup(props, { emit }) {
    const { item } = toRefs(props);

    const extendedRatePrice = computed(() => {
      if (item.value && item.value.rooms && item.value.rooms[0]) {
        const room = item.value.rooms[0];

        return room.extendedRatePrice;
      }
      return false;
    });
    const show = ref(false);
    const setShow = () => {
      show.value = !show.value;
    };
    const selectOffer = () => {
      emit("select-offer", item.value);
    };
    const unselect = () => {
      emit("unselect-offer", null);
    };
    return { extendedRatePrice, show, setShow, selectOffer, unselect };
  },
});
