import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "aviaData" }
const _hoisted_2 = {
  key: 0,
  class: "aviaData__from"
}
const _hoisted_3 = {
  key: 1,
  class: "aviaData__timeData"
}
const _hoisted_4 = { class: "aviaData__timeData__time " }
const _hoisted_5 = { class: "aviaData__timeData__data pl18" }
const _hoisted_6 = { class: "aviaData__timeData__data__day" }
const _hoisted_7 = { class: "aviaData__timeData__data__dw" }
const _hoisted_8 = {
  key: 2,
  class: "aviaData__timeData"
}
const _hoisted_9 = { class: "aviaData__timeData__data " }
const _hoisted_10 = { class: "aviaData__timeData__data__day" }
const _hoisted_11 = { class: "aviaData__timeData__data__dw" }
const _hoisted_12 = { class: "aviaData__timeData__time pl18" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.info.codeAirPort)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          (_ctx.isleft)
            ? (_openBlock(), _createBlock("b", {
                key: 0,
                tooltip: `${_ctx.info.shornameAirport} ${_ctx.info.terminal}`
              }, _toDisplayString(_ctx.info.codeAirPort.code), 9, ["tooltip"]))
            : _createCommentVNode("", true),
          (!_ctx.isleft)
            ? (_openBlock(), _createBlock("b", {
                key: 1,
                tooltip: `${_ctx.info.shornameAirport} ${_ctx.info.terminal}`
              }, _toDisplayString(_ctx.info.codeAirPort.code), 9, ["tooltip"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isleft)
      ? (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, _toDisplayString(_ctx.info.time), 1),
          _createVNode("div", _hoisted_5, [
            _createVNode("div", _hoisted_6, _toDisplayString(_ctx.info.date), 1),
            _createVNode("div", _hoisted_7, _toDisplayString(_ctx.info.day), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isleft)
      ? (_openBlock(), _createBlock("div", _hoisted_8, [
          _createVNode("div", _hoisted_9, [
            _createVNode("div", _hoisted_10, _toDisplayString(_ctx.info.date), 1),
            _createVNode("div", _hoisted_11, _toDisplayString(_ctx.info.day), 1)
          ]),
          _createVNode("div", _hoisted_12, _toDisplayString(_ctx.info.time), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}