
import { computed, defineComponent, nextTick, reactive, ref } from "vue";
import { TYPE_DROPLIST } from "../../../constant";
import { PersonaMainInterface } from "../../../interfaceAndType/typesShared";
import CommonSelector from "../Selectors/CommonSelector.vue";
import BudgetCodesSelector from "./BudgetCodesSelector.vue";
import ErrorMsgPerson from "./ErrorMsgPerson.vue";
import ShowPerson from "./ShowPerson.vue";

export default defineComponent({
  name: "SelectorPerson",
  emit: ["set-person", "delete-persone"],
  components: {
    CommonSelector,
    ShowPerson,
    ErrorMsgPerson,
    BudgetCodesSelector
  },
  props: {
    contractId: {
      type: Number,
      required: true
    },
    isShowMode: {
      type: Boolean,
      default: false
    },
    dataShow: {
      type: Object
    },
    showBudgetCodes: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const showEl = ref(true);
    const errorSearhc = reactive({
      passenger: false,
      document: false,
      bonusCard: false,
      email: false,
      phone: false
    });
    const setError = (field, val = false) => {
      errorSearhc[field] = val;
    };

    const personMain = reactive<PersonaMainInterface>({
      passenger: null,
      document: null,
      bonusCard: null,
      email: null,
      phone: null,
      budgetCodes: null
    });
    const setData = (val, field) => {
      personMain[field] = val ? val : null;
      if (field == "passenger") {
        personMain.document = null;
        personMain.bonusCard = null;
        personMain.email = null;
        personMain.phone = null;
        personMain.budgetCodes = null;
        showEl.value = false;
        errorSearhc.passenger = false;
        errorSearhc.document = false;
        errorSearhc.bonusCard = false;
        errorSearhc.email = false;
        errorSearhc.phone = false;
        nextTick(() => {
          showEl.value = true;
        });
      }
      emit("set-person", personMain);
    };
    const personId = computed(() => {
      const passenger = personMain.passenger;
      return passenger ? passenger.entityId : null;
    });
    const deletePersone = () => {
      emit("delete-persone");
    };
    const personType = TYPE_DROPLIST.PERSON;
    const docType = TYPE_DROPLIST.DOCUMENT;
    const bonusType = TYPE_DROPLIST.BONUS_CARD;
    const emailType = TYPE_DROPLIST.CONTAC_EMAIL;
    const phoneType = TYPE_DROPLIST.CONTACT_MOBILE;
    return {
      docType,
      personId,
      bonusType,
      emailType,
      phoneType,
      personType,
      setData,
      deletePersone,
      errorSearhc,
      setError,
      showEl,
      personMain
    };
  }
});
