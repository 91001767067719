import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hotels-search-card-info-header__stars-container"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_ctx.item.stars >= 1)
    ? (_openBlock(), _createBlock("ul", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.item.stars, (star) => {
          return (_openBlock(), _createBlock("li", {
            class: "hotels-search-card-info-header__stars-icon",
            key: star.index
          }))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
    : _createCommentVNode("", true)
}