
import SearchCardFooter from "../../components/search/SearchCardFooter.vue";
import { HotelTreeItem } from "../../../interfaceAndType/typesHotel";
import { defineComponent, PropType, toRefs } from "vue";
import moment from "moment";
export default defineComponent({
  name: "HotelsSearchCard",
  props: {
    toggleDisplay: {
      type: Function
    },
    item: {
      type: Object as PropType<HotelTreeItem>
    },
    openModalPrice: {
      type: Function
    },
    isSelected: {
      type: Boolean,
      default: false
    },
  },
  components: {
    SearchCardFooter
  },
  setup(props) {
    const parseDate = (date, format) => {
      return moment(date).format(format);
    };
    return { parseDate };
  }
});
