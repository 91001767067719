
//todo проблему клика решить через модификаторы вохможно мпоможет
import moment from "moment";
import { computed, defineComponent, PropType, toRefs } from "vue";
import { useRouter } from "vue-router";
// import router from "../../../router/index"
import { BusinessTripType } from "../../../interfaceAndType/typesTrips";
export default defineComponent({
  name: "OrderElement",
  props: {
    item: {
      type: Object as PropType<BusinessTripType>,
      required: true
    },
    isOpen: Boolean,
    toggleFun: {
      type: Function as PropType<() => void>
    }
  },
  setup(props, context) {
    const router = useRouter();
    const { item, isOpen, toggleFun } = toRefs(props);
    const hasAvia = computed(() => {
      if(item && item.value && item.value.orderTypeList)
        return item.value.orderTypeList.includes('Avia');
    });
    const hasHotel = computed(() => {
      if(item && item.value && item.value.orderTypeList)
        return item.value.orderTypeList.includes('Hotel');
    });
    const hasRail = computed(() => {
      if(item && item.value && item.value.orderTypeList)
        return item.value.orderTypeList.includes('Railway');
    });
    const hasTransfer = computed(() => {
      if(item && item.value && item.value.orderTypeList)
        return item.value.orderTypeList.includes('Transfer');
    });
    const goTrip = (id: number) => {
      router.push(`/trip/${id}`);
    };
    return {
      goTrip,
      hasAvia,
      hasHotel,
      hasRail,
      hasTransfer
    };
  }
});
