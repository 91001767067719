import { MAIN_DATE_FORMAT } from "@/constant";
import { getTransferRoute } from "@/helpers/getClearTransferRoute";
import { transferOffer } from "@/interfaceAndType/typesServices";
import { RouteMainInterface } from "@/interfaceAndType/typesShared";
import moment from "moment";
/**
 * хук для создания маршрута в трасфере
 * @param transfer
 */
export default function useCreateTransferRoute(transfer: transferOffer) {
  // route
  const addTransferRoute = () => {
    const clearRoute = getTransferRoute();
    transfer.TransferRouteList.push(clearRoute);
  };
  const setRoute = (index: number, data: RouteMainInterface) => {
    transfer.TransferRouteList[index].CityId = data.city
      ? data.city.entityId
      : -1;
    transfer.TransferRouteList[index].Address = data.address;
    transfer.TransferRouteList[index].Time = data.time;
    transfer.TransferRouteList[index].TransportNum = data.transportNum;
    transfer.TransferRouteList[index].Date = moment(data.date).format(
      MAIN_DATE_FORMAT
    );
  };
  const deleteTransferRoute = index => {
    transfer.TransferRouteList.splice(index, 1);
  };
  //route end

  return {
    addTransferRoute,
    deleteTransferRoute,
    setRoute
  };
}
