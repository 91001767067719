
import appHeader from "../components/shared/AppHeader.vue";
import CookieBar from "../components/shared/CookieBar.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "MainLayout",
  components: {
    appHeader,
    CookieBar
  }
});
