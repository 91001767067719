import {
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions
} from "vuex";

import { State as RootState } from "../index";
import { GlobalMutations as Mutations } from "../allMutationsType";
import { OrderCreateReqType } from "../../../interfaceAndType/typesOrder";
import { DropDownEntity } from "../../../interfaceAndType/typesShared";
import { OrderDTOType } from "@/interfaceAndType/typesTrips";

// Declare state
export type State = {
  orderTypes: Array<DropDownEntity>;
};

// Action enums
export enum ActionTypes {
  GET_CLIENT = "GET_CLIENT",
  GET_CONTRACT = "GET_CONTRACT",
  GET_INITIATOR = "GET_INITIATOR",
  GET_TRIP = "GET_TRIP",
  GET_ORDERTYPE = "GET_ORDERTYPE",
  SEND_ORDER = "SEND_ORDER",
  GET_ORDER_OFFERS = "GET_ORDER_OFFERS"
}

// Actions contracts
export interface Actions {
  [ActionTypes.GET_CLIENT](
    { commit }: AugmentedActionContext,
    payload: { searchParam: string }
  ): Promise<Array<DropDownEntity>>;
  [ActionTypes.GET_CONTRACT](
    { commit }: AugmentedActionContext,
    payload: { searchParam: string; clientId: number }
  ): Promise<Array<DropDownEntity>>;
  [ActionTypes.GET_INITIATOR](
    { commit }: AugmentedActionContext,
    payload: { searchParam: string; contractId: number }
  ): Promise<Array<DropDownEntity>>;
  [ActionTypes.GET_TRIP](
    { commit }: AugmentedActionContext,
    payload: { search: string }
  ): Promise<Array<DropDownEntity>>;
  [ActionTypes.GET_ORDERTYPE]({
    commit
  }: AugmentedActionContext): Promise<Array<DropDownEntity>>;
  [ActionTypes.SEND_ORDER](
    { commit }: AugmentedActionContext,
    payload: { order: OrderCreateReqType }
  ): Promise<Array<OrderCreateReqType> | boolean>;
  [ActionTypes.GET_ORDER_OFFERS](
    { commit }: AugmentedActionContext,
    payload: { orderId: number }
  ): Promise<OrderDTOType | null>;
}

// getters types
export type Getters = {
  getOrderTypeId(state: State): (code: string) => number;
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  "commit" | "getters" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
