import { ActionTree } from "vuex";
import { State as RootState } from "../index";
import { State, Actions, ActionTypes, UserProfile } from "./contract";
import { MutationTypes } from "../auth/MutationsType";
import { ApiService } from "../../../utils/ApiService";
import { MutationTypes as SystemMutationTypes } from "../system/MutationsType";
import { LoaderType, EVENT_LOADER } from "../system/contract";
import router from "../../../router/index";
import { path } from "../../../router/path";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
// Define actions
export const actions: ActionTree<State, RootState> & Actions = {
  /**
   * Вход пользователя
   * @param param0
   * @param payload
   */
  async [ActionTypes.AUTH_LOGIN](
    { commit },
    payload: { email: string; password: string }
  ) {
    const { email, password } = payload;
    const loader: LoaderType = {
      event: EVENT_LOADER.LOGIN_USER,
      show: true,
      message: ""
    };
    commit(SystemMutationTypes.CONTROL_LOADER, { loader: loader });
    await sleep(1000);
    const apiService = new ApiService();
    const res = await apiService.login(email, password);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      loader.show = false;
      commit(SystemMutationTypes.CONTROL_LOADER, { loader });
    } else {
      commit(MutationTypes.SET_USER_AUTHENTICATED, {
        token: res.object,
        result: true
      });
      loader.show = false;
      commit(SystemMutationTypes.CONTROL_LOADER, { loader });
      router.push(path.home);
    }
  },
  /**
   * Получение данных о пользовате
   * @param param0
   * @returns
   */
  async [ActionTypes.AUTH_ME]({ commit, state }) {
    const loader: LoaderType = {
      event: EVENT_LOADER.LOAD_USER_DATA,
      show: true,
      message: ""
    };
    commit(SystemMutationTypes.CONTROL_LOADER, { loader: loader });
    await sleep(2000);
    const apiService = new ApiService();
    const res = await apiService.me();
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      loader.show = false;
      commit(SystemMutationTypes.CONTROL_LOADER, { loader });
      return false;
    } else {
      // console.log(res);
      const userInfo: UserProfile = res.object;
      commit(MutationTypes.SET_USER_PROFILE, {
        userProfile: userInfo
      });
      loader.show = false;
      commit(SystemMutationTypes.CONTROL_LOADER, { loader });
      return true;
    }
  },
  /**
   * Выход пользователя
   * @param param0
   */
  async [ActionTypes.AUTH_LOGOUT]({ commit }) {
    try {
      // some logic that logs a user in
    } catch (err) {
      // some error handling logic
    }
  }
};
