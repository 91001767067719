
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  toRefs,
  watch
} from "@vue/runtime-core";
import clearReactive from "../../../helpers/clearReactive";
import {
  CodeValueType,
  DropDownEntity
} from "../../../interfaceAndType/typesShared";
import { useStore } from "../../../store/modules";
import { ActionTypes } from "../../../store/modules/shared/contract";
import BaseInput from "../../components/base/BaseInput.vue";

export default defineComponent({
  name: "BudgetCodesSelector",
  components: { BaseInput },
  props: {
    ContractId: {
      type: Number,
      required: true
    },
    offerPersonId: {
      type: Number,
      required: true
    }
  },
  setup(props, context) {
    const store = useStore();
    const { ContractId, offerPersonId } = toRefs(props);
    const codes = ref<CodeValueType[] | null>(null);
    const getCodes = async (contract, offerPerson) => {
      const res = await store.dispatch(ActionTypes.GET_BUDGET_CODES, {
        ContractId: contract,
        offerPersonId: 0 //0 при создании ставим 0 это логика такая
      });
      if (res) {
        const converRes = res.map(item => {
          const convertResitem = item as any;
          if (convertResitem.isEnum && convertResitem.codeValue) {
            const findEl = convertResitem.codeEnumList?.find(
              el => el.entityId == ((convertResitem.codeValue as unknown) as number)
            );
            if (findEl) {
              convertResitem.codeValue = findEl;
            }
          }
          return convertResitem;
        });
        codes.value = converRes;
        return res;
      }
      return null;
    };
    const simpleSetData = (val, index: number) => {
      if (codes.value) codes.value[index].codeValue = val;
    };
    onMounted(() => {
      getCodes(ContractId.value, offerPersonId.value);
    });

    watch(
      codes,
      () => {
        const copyCodes = clearReactive(codes.value);
        if (copyCodes) {
          context.emit(
            "set-data",
            copyCodes.map(item => {
              const res = item;
              if (res.isEnum && res.codeValue && res.codeValue.entityId) {
                res.codeValue = (((res.codeValue as unknown) as DropDownEntity)
                  .entityId as unknown) as string;
              }

              return res;
            })
          );
        }
      },
      { deep: true }
    );

    return { codes, getCodes, simpleSetData };
  }
});
