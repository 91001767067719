import { computed, toRefs } from "vue";
/**
 * Делает грубый фильтр данных
 * @param props
 */
export default function useFilterMultiTiket() {
  const isAvia = item => {
    if ("itineraries" in item) {
      //&& (show == all || show == avia)
      return true;
    }
    return false;
  };
  const isHotel = item => {
    if ("hotelType" in item) {
      return true;
    }
    return false;
  };
  const isService = item => {
    if ("SupplierId" in item && !("Name" in item)) {
      return true;
    }
    return false;
  };
  const isFree = item => {
    if ("SupplierId" in item && "Name" in item) {
      return true;
    }
    return false;
  };
  const isRail = item => {
    if ("numberTrain" in item) {
      return true;
    }
    return false;
  };

  return { isAvia, isHotel, isService, isRail, isFree };
}
