
import { defineComponent, ref, reactive, computed } from "vue";
import Datepicker from "vuejs3-datepicker";
import _ from "lodash";
import { useStore } from "../../../store/modules";
import { ActionTypes } from "../../../store/modules/hotels/contract";
import { DropDownEntity } from "../../../interfaceAndType/typesShared";
import { HotelSearchRequest } from "../../../interfaceAndType/typesHotel";
import moment from "moment";
import { ActionTypes as OrderActionTypes } from "../../../store/modules/order/contract";

import router from "../../../router";
import { path } from "../../../router/path";
import clearReactive from "../../../helpers/clearReactive";
export default defineComponent({
  name: "HotelsSearchForm",
  components: {
    Datepicker
  },
  props: {},
  setup(props) {
    type extendedCity = {
      entityId: number;
      languageId: number;
      entityCode: string | null;
      entityName: string | null;
      complexName?: string | null;
    };
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const showParams = ref<boolean>(false);
    interface Lists {
      cities: Array<extendedCity | null>;
      clients: Array<DropDownEntity | null>;
    }
    const requestObj = reactive<HotelSearchRequest>({
      children: 0,
      adults: 1,
      clientId: null,
      passengers: null,
      contractId: null,
      date: "",
      nights: 0,
      childAges: null,
      cityId: 0,
      radius: 20000
    });
    const lists = reactive<Lists>({
      cities: [],
      clients: []
    });
    const selectedCity = ref<DropDownEntity | null>(null);
    const selectedClient = ref<DropDownEntity | null>(null);
    const asyncFindFunc = _.debounce(async (query: string) => {
      console.log("search");
      isLoading.value = true;
      const paramsIn = {
        city: query
      };
      const res = await store.dispatch(ActionTypes.SEARCH_CITY, paramsIn);
      if (res) {
        lists.cities = res.map(item => {
          return {
            ...item,
            complexName: item.entityCode + " " + item.entityName
          };
        });
      }
      isLoading.value = false;
    }, 500);

    const asyncFind = (event: { query: string }) => {
      asyncFindFunc(event.query);
    };

    const searchClient = _.debounce(async event => {
      const paramsIn = {
        searchParam: event.query
      };
      lists.clients = await store.dispatch(
        OrderActionTypes.GET_CLIENT,
        paramsIn
      );
      isLoading.value = false;
    });

    const setCity = event => {
      selectedCity.value = event.value;
      requestObj.cityId = event.value.entityId;
    };

    const setClient = event => {
      requestObj.clientId = event.value.entityId;
    };

    const disabledDatesFrom = {
      to: new Date(),
      preventDisableDateSelection: true
    };

    const disabledDatesTo = computed(() => {
      const disabledDates = moment(requestObj.date)
        .add(1, "d")
        .toDate();
      return {
        to: disabledDates,
        preventDisableDateSelection: true
      };
    });

    const setDate = (val: string) => {
      requestObj.date = val;
      console.log(requestObj.date);
    };

    const calculateNights = (val: string) => {
      const checkoutDate = moment(val);
      const checkinDate = moment(requestObj.date);
      const diff = checkoutDate.diff(checkinDate, "days");
      requestObj.nights = diff;
    };

    const increaseCount = (name: string) => {
      requestObj[name]++;
    };

    const decreaseCount = (name: string) => {
      requestObj[name]--;
    };

    const searchData = async () => {
      const preventData: HotelSearchRequest = clearReactive(requestObj);

      preventData.date = moment(preventData.date).format("YYYY-MM-DD");

      const res = await store.dispatch(ActionTypes.SEARCH_HOTELS, {
        searchData: preventData
      });
      if (res) {
        router.push(path.hotelSearchResult);
      }
    };

    const disabledSearch = computed(() => {
      return (!!requestObj.date && requestObj.nights && requestObj.cityId)
    });

    return {
      isLoading,
      showParams,
      lists,
      selectedCity,
      selectedClient,
      asyncFind,
      setCity,
      disabledDatesFrom,
      disabledDatesTo,
      requestObj,
      setDate,
      calculateNights,
      increaseCount,
      decreaseCount,
      searchClient,
      setClient,
      searchData,
      disabledSearch
    };
  }
});
