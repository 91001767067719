import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/modules";
import directives from "./directives/Index";

import appSection from "./components/components/base/AppSection.vue";
import searchFooter from "./components/components/search/SearchFooter.vue";
import langList from "./components/shared/LangList.vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import Galleria from "primevue/galleria";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Slider from "primevue/slider";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import RadioButton from "primevue/radiobutton";
import Paginator from "primevue/paginator";
import AutoComplete from "primevue/autocomplete";
import Chips from "primevue/chips";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Rating from "primevue/rating";
import { VueCookieNext } from "vue-cookie-next";
import MainLoader from "../src/components/shared/MainLoader.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "./assets/styles/main.scss";

createApp(App)
  .use(ToastService)
  .use(store)
  .use(router)
  .use(VueCookieNext)
  .use(directives)
  .component(appSection.name, appSection)
  .component(searchFooter.name, searchFooter)
  .component(langList.name, langList)
  .component("Accordion", Accordion)
  .component("AccordionTab", AccordionTab)
  .component("Checkbox", Checkbox)
  .component("Dialog", Dialog)
  .component("Galleria", Galleria)
  .component("TabView", TabView)
  .component("TabPanel", TabPanel)
  .component("Slider", Slider)
  .component("MultiSelect", MultiSelect)
  .component("Dropdown", Dropdown)
  .component("InputNumber", InputNumber)
  .component("RadioButton", RadioButton)
  .component("Paginator", Paginator)
  .component(MainLoader.name, MainLoader)
  .component("AutoComplete", AutoComplete)
  .component("Chips", Chips)
  .component("InputText", InputText)
  .component("Toast", Toast)
  .component("Calendar", Calendar)
  .component("VueSlider", VueSlider)
  .component("Rating", Rating)


  .mount("#app");
