
import { computed, defineComponent, ref, toRefs } from "vue";

export default defineComponent({
  name: "SingleDropdown",
  props: {
    selected: String,
    options: Array,
    placeholder: String,
    label: String,
    select: Function
  },
  emits: ["select"],
  setup(props, { emit }) {
    const { selected, select } = toRefs(props);
    const localSelected = computed({
      get: () => {
        return selected ? selected.value : null;
      },
      set: val => {
        emit("select", val);
      }
    });
    return { localSelected };
  }
});
