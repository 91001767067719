
import { defineComponent, PropType, ref, toRefs } from "vue";
import SeparateLine from "../common/SeparateLine.vue";
import AviaData from "./AviaData.vue";
import AviaInfo from "./AviaInfo.vue";
import RouteLine from "./RouteLine.vue";
import { Leg, Flight } from "../../../interfaceAndType/typesSearh";
export default defineComponent({
  components: {
    SeparateLine,
    AviaData,
    RouteLine,
    AviaInfo
  },
  props: {
    leg: {
      type: Object as PropType<Leg>,
      required: true
    },
    small: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  setup(props) {
    const startPoint = ref<Flight | null>(null);
    const endPoint = ref<Flight | null>(null);
    const allFlight = ref<Array<Flight>>([]);
    const { leg } = toRefs(props);
    if (leg.value && leg.value.flights) {
      startPoint.value = leg.value.flights[0];
      endPoint.value = leg.value.flights[leg.value.flights.length - 1];
      allFlight.value = leg.value.flights;
    }
    return {
      startPoint,
      endPoint,
      allFlight
    };
  }
});
