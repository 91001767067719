import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../../assets/images/icons/avia/icon-avia.png'
import _imports_1 from '../../../assets/images/icons/rail/icon-rail.png'
import _imports_2 from '../../../assets/images/icons/hotel/icon-hotel.png'
import _imports_3 from '../../../assets/images/icons/services/icon-services.png'


const _hoisted_1 = { class: "cursorPointer" }
const _hoisted_2 = { class: "multiIcon" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: "avia",
  class: "icon-avia"
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  alt: "rail",
  class: "icon-rail"
}
const _hoisted_5 = {
  key: 2,
  src: _imports_2,
  alt: "hotel",
  class: "icon-hotel"
}
const _hoisted_6 = {
  key: 3,
  src: _imports_3,
  alt: "services",
  class: "icon-services"
}
const _hoisted_7 = { class: "col7" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("tr", _hoisted_1, [
    _createVNode("td", {
      class: "col1",
      onClick: _cache[1] || (_cache[1] = $event => (_ctx.goTrip(_ctx.item.businessTripId)))
    }, [
      _createVNode("div", _hoisted_2, [
        (_ctx.hasAvia)
          ? (_openBlock(), _createBlock("img", _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.hasRail)
          ? (_openBlock(), _createBlock("img", _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.hasHotel)
          ? (_openBlock(), _createBlock("img", _hoisted_5))
          : _createCommentVNode("", true),
        (_ctx.hasTransfer)
          ? (_openBlock(), _createBlock("img", _hoisted_6))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode("td", {
      class: "col2",
      onClick: _cache[2] || (_cache[2] = $event => (_ctx.goTrip(_ctx.item.businessTripId)))
    }, " №" + _toDisplayString(_ctx.item.businessTripId), 1),
    _createVNode("td", {
      class: "col3",
      onClick: _cache[3] || (_cache[3] = $event => (_ctx.goTrip(_ctx.item.businessTripId)))
    }, _toDisplayString(_ctx.item.dateCreated), 1),
    _createVNode("td", {
      class: "col4",
      onClick: _cache[4] || (_cache[4] = $event => (_ctx.goTrip(_ctx.item.businessTripId)))
    }, _toDisplayString(_ctx.item.clientName), 1),
    _createVNode("td", {
      class: "col5",
      onClick: _cache[5] || (_cache[5] = $event => (_ctx.goTrip(_ctx.item.businessTripId)))
    }, _toDisplayString(_ctx.item.name), 1),
    _createVNode("td", _hoisted_7, [
      _createVNode("div", {
        class: ["plusIcon", { isOpen: _ctx.isOpen }],
        onClick: _cache[6] || (_cache[6] = $event => (_ctx.toggleFun(_ctx.item.businessTripId, _ctx.isOpen)))
      }, null, 2)
    ])
  ]))
}