import { Module } from "vuex";
import { State as RootState } from "../index";
import { State } from "./contract";

import { mutations } from "./mutations";
// Create initial state
const state: State = {
  warnings: [],
  errors: [],
  showLoader: []
};

export const SystemModule: Module<State, RootState> = {
  state,
  mutations
};
