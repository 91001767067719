
import { ExtendedHotelInfo } from "../../../interfaceAndType/typesHotel";
import { computed, defineComponent, PropType, toRefs } from "vue";
import moment from "moment";
import HotelService from "./complexCard/HotelService.vue";
import HotelMainInfo from "./complexCard/HotelMainInfo.vue";
import HotelImage from "./complexCard/HotelImage.vue";
import HotelStars from "./complexCard/HotelStars.vue";
import HotelCardFooter from "./complexCard/HotelCardFooter.vue";
export default defineComponent({
  name: "HotelsCardComplex",
  props: {
    toggleDisplay: {
      type: Function
    },
    item: {
      type: Object as PropType<ExtendedHotelInfo>,
      required: true
    },
    openModalPrice: {
      type: Function
    },
    isModeOffer: {
      type: Boolean,
      default: false
    },
     isCansel: {
      type: Boolean,
      default: false
    },
    hasCansel: {
      type: Boolean,
      default: false
    },
    hasControl: {
      type: Boolean,
      default: true
    }
  },
  emits: ["select-offer", "unselect-offer"],
  components: {
    HotelService,
    HotelImage,
    HotelStars,
    HotelMainInfo,
    HotelCardFooter
  },
  setup(props, { emit }) {
    const { item } = toRefs(props);
    const parseDate = (date, format) => {
      return moment(date).format(format);
    };
    const selectTiket = () => {
      emit("select-offer", item?.value);
    };
    return { parseDate, selectTiket };
  }
});
