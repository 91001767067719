export const path = {
  login: "/login",
  home: "/",
  aviaSearch: "/avia-search",
  aviaSearchResult: "/search-result-avia",
  railSearch: "/rail-search",
  railSearchResult: "/search-result-rail",
  hotelSearch: "/hotel-search",
  hotelSearchResult: "/search-result-hotel",
  transfer: "/",
  trips: "/trip",
  trip: "/trip/:id",
  service: "/services-search",
  order: "/order"
};
