import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_avia_tikets = _resolveComponent("avia-tikets")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.selectedTickets, (item) => {
      return (_openBlock(), _createBlock(_component_avia_tikets, {
        key: item.key,
        item: item,
        deleteTicket: _ctx.deleteTicket,
        isSelected: true,
        btnTarrif: false
      }, null, 8, ["item", "deleteTicket"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}