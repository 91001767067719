
import { defineComponent, PropType } from "@vue/runtime-core";
import { transferOffer } from '../../../../interfaceAndType/typesServices';
import TransferDate from "./TransferDate.vue";
import TransferInfoblock from "./TransferInfoblock.vue";
import TransferRouteLine from "./TransferRouteLine.vue";

export default defineComponent({
  components: { TransferDate, TransferRouteLine, TransferInfoblock, },
  name: "TransferRoute",
  component: {},
  props: {
    item: {
      type: Object as PropType<transferOffer>
    },
  },
  setup(props) {
    return {}
  }
});
