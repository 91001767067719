<template>
  <div class="search-page search-page--avia">
    <div class="container">
      <app-section
        :title="'Поиск авиабилетов'"
        :descr="'Введите нужные парметры для поиска билетов'"
      >
        <template v-slot:content>
          <avia-search-form />
          <search-footer />
        </template>
      </app-section>
    </div>
  </div>
</template>
<script>
import AviaSearchForm from "../components/components/search/AviaSearchForm";
export default {
  name: "AviaSearch",
  components: {
    AviaSearchForm
  }
};
</script>
<style lang="scss" scoped></style>
