import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "aviaCard__main hoverShadow mb-8" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_rail_ticket_header = _resolveComponent("rail-ticket-header")
  const _component_rail_route = _resolveComponent("rail-route")
  const _component_rail_bottom = _resolveComponent("rail-bottom")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_rail_ticket_header, { item: _ctx.item }, null, 8, ["item"]),
    _createVNode(_component_rail_route, { item: _ctx.item }, null, 8, ["item"]),
    _createVNode(_component_rail_bottom, {
      item: _ctx.item,
      openPricingModal: _ctx.openPricingModal,
      isSelected: _ctx.isSelected,
      unselect: _ctx.unselect
    }, null, 8, ["item", "openPricingModal", "isSelected", "unselect"])
  ]))
}