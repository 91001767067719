import {
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions
} from "vuex";

import { State as RootState } from "../index";
import { GlobalMutations as Mutations } from "../allMutationsType";
import { DropDownEntity } from "@/interfaceAndType/typesShared";
import { CodeValueType } from '../../../interfaceAndType/typesShared';

// Аэропорт тип
export type AirPortType = {};

// Declare state
export type State = {};

// Action enums
export enum ActionTypes {
  SEARH_DROPLIST = "SEARH_DROPLIST",
  GET_BUDGET_CODES = "GET_BUDGET_CODES",
  GET_PERSON_CODES = "GET_PERSON_CODES",
}

// Actions contracts
export interface Actions {
  [ActionTypes.SEARH_DROPLIST](
    { commit }: AugmentedActionContext,
    payload: {
      type: string | null;
      search: string | null;
      subListId: number | null;
      id?: number | null;
    }
  ): Promise<Array<DropDownEntity>>;
  [ActionTypes.GET_BUDGET_CODES] (
    { commit }: AugmentedActionContext,
    payload: {
      ContractId: number,
      offerPersonId: number
    }
  ): Promise<Array<CodeValueType>>;
  [ActionTypes.GET_PERSON_CODES] (
    { commit }: AugmentedActionContext,
    payload: {
      PersonId: number
    }
  ): Promise<Array<CodeValueType>>;
}

// getters types
export type Getters = {};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  "commit" | "getters" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
