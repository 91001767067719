import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tripList" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TripCard = _resolveComponent("TripCard")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.listOrders, (item) => {
      return (_openBlock(), _createBlock(_component_TripCard, {
        key: item.businessTripId,
        item: item,
        onClick: $event => (_ctx.goTrip(item.businessTripId))
      }, null, 8, ["item", "onClick"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}