
import { computed, defineComponent, PropType, toRefs } from "vue";
import { NTrainListReference } from "../../../interfaceAndType/typesRail";

export default defineComponent({
  props: {
    unselect: {
      type: Function,
    },
    item: {
      type: Object as PropType<NTrainListReference>,
    },
    isModeOffer: {
      type: Boolean,
      default: false,
    },
    isCansel: {
      type: Boolean,
      default: false,
    },
    hasCansel: {
      type: Boolean,
      default: false,
    },
    hasControl: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["select-offer", "unselect-offer"],
  setup(props, { emit }) {
    const { item } = toRefs(props);
    const totalCost = computed(() => {
      if (
        item &&
        item.value &&
        item.value.ckTrainListReferences &&
        item.value.ckTrainListReferences[0].costTicket
      ) {
        return item.value.ckTrainListReferences[0].cvTrainListReference.selected
          ? item.value.ckTrainListReferences[0].cvTrainListReference.selected.split(
              ","
            ).length * +item.value.ckTrainListReferences[0].costTicket
          : 0;
      }
    });
    const selectTiket = () => {
      emit("select-offer", item?.value);
    };
    const unselectTicket = () => {
      emit("unselect-offer", null);
    };
    return { totalCost, selectTiket, unselectTicket };
  },
});
