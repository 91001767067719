
import { computed, defineComponent, toRefs } from "vue";
import router from "../../router";
import TripCard from "./TripCard.vue";
export default defineComponent({
  components: {
    TripCard
  },
  props: {
    trips: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const { trips } = toRefs(props);
    const listOrders = computed(() => trips.value.slice(0, 3));
    const goTrip = (id: number) => {
      router.push(`/trip/${id}`);
    };
    return { listOrders, goTrip };
  }
});
