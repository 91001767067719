
import { BaseHotelRateAccessor } from "@/interfaceAndType/typesHotel";
import { computed, defineComponent, PropType, toRefs } from "vue";

export default defineComponent({
  name: "AccesorRoomFilter",
  props: {
    roomAccesor: {
      type: Array as PropType<Array<BaseHotelRateAccessor>>,
      required: true
    },
    setService: {
      type: Function
    }
  },
  setup(props) {
    const { roomAccesor } = toRefs(props);
    const shortAccesor = computed(() => {
      const res: { id: number; name: string }[] = [];
      if (roomAccesor.value && roomAccesor.value.length > 0) {
        roomAccesor.value.forEach(acc => {
          const name = acc.systemName
            ? acc.systemName
            : "Код поставщика" + acc.id_System;
          const find = res.find(el => el.id == acc.id_System);
          if (!find) {
            res.push({ id: acc.id_System, name });
          }
        });
        return res;
      }
      return [];
    });
    return { shortAccesor };
  }
});
