
import { defineComponent, ref } from "vue";

import { ActionTypes } from "../../../store/modules/auth/contract";

import { useStore } from "../../../store/modules";
import { LoginParams } from "../../../typeSystem/auth/authType";
export default defineComponent({
  name: "loginForm",
  setup() {
    const login = ref<string>("admin@test.com");
    const password = ref<string>("123");
    const store = useStore();
    const doLogin = async () => {
      const paramsIn = {
        email: login.value,
        password: password.value
      };
      try {
        await store.dispatch(ActionTypes.AUTH_LOGIN, paramsIn);
      } catch (error) {
        console.error(error);
      }
    };

    return {
      login,
      password,
      doLogin
    };
  }
});
