import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "aviaCard__head" }
const _hoisted_2 = { class: "aviaCard__head__left" }
const _hoisted_3 = { class: "airBlock" }
const _hoisted_4 = { class: "air" }
const _hoisted_5 = { class: "route" }
const _hoisted_6 = { class: "aviaCard__head__right" }
const _hoisted_7 = { class: "infoBlock" }
const _hoisted_8 = { class: "info" }
const _hoisted_9 = { class: "info" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, _toDisplayString(_ctx.item.numberTrain), 1)
      ]),
      _createVNode("div", _hoisted_5, _toDisplayString(_ctx.item.npScheduleHelper.fromTo[0]) + " — " + _toDisplayString(_ctx.item.npScheduleHelper.fromTo[1]), 1)
    ]),
    _createVNode("div", _hoisted_6, [
      _createVNode("div", _hoisted_7, [
        _createVNode("div", _hoisted_8, _toDisplayString(_ctx.item.brandTrain ? _ctx.item.brandTrain : 'Н/Д'), 1),
        _createVNode("div", _hoisted_9, _toDisplayString(_ctx.item.categoryTrain), 1)
      ])
    ])
  ]))
}