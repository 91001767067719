
import { defineComponent, PropType } from "@vue/runtime-core";
import moment from "moment";
import { transferOffer } from '../../../../interfaceAndType/typesServices';

export default defineComponent({
  name: "TransferRouteLine",
  component: {},
  props: {
    item: {
      type: Object as PropType<transferOffer>
    },
  },
  setup(props) {
    const parseDate = (date, format) => {
      return moment(date).format(format);
    };
    return { parseDate };
  }
});
