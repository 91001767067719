
import moment from "moment";
import { defineComponent, PropType } from "vue";
import { FreeOffer } from '../../../../interfaceAndType/typesServices';

export default defineComponent({
  name: "FreeServiceDate",
  props: {
    item: {
      type: Object as PropType<FreeOffer>
    },
    isLeft: { type: Boolean, default: true },
  },
  setup(props) {
    const parseDate = (date, format) => {
      return moment(date).format(format);
    };
    return { parseDate };
  }
});
