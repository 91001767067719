import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "hotels-search-card-info-header__services" }
const _hoisted_2 = {
  key: 0,
  class: "hotels-search-card-info-header__services-item hotels-search-card-info-header__services-item--ice"
}
const _hoisted_3 = {
  key: 1,
  class: "hotels-search-card-info-header__services-item hotels-search-card-info-header__services-item--knife"
}
const _hoisted_4 = {
  key: 2,
  class: "hotels-search-card-info-header__services-item hotels-search-card-info-header__services-item--news"
}
const _hoisted_5 = {
  key: 3,
  class: "hotels-search-card-info-header__services-item hotels-search-card-info-header__services-item--wash"
}
const _hoisted_6 = {
  key: 4,
  class: "hotels-search-card-info-header__services-item hotels-search-card-info-header__services-item--internet"
}
const _hoisted_7 = {
  key: 5,
  class: "hotels-search-card-info-header__services-item hotels-search-card-info-header__services-item--bed"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("ul", _hoisted_1, [
    (_ctx.allService.ice)
      ? (_openBlock(), _createBlock("li", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.allService.knife)
      ? (_openBlock(), _createBlock("li", _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.allService.news)
      ? (_openBlock(), _createBlock("li", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.allService.wash)
      ? (_openBlock(), _createBlock("li", _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.allService.internet)
      ? (_openBlock(), _createBlock("li", _hoisted_6))
      : _createCommentVNode("", true),
    (_ctx.allService.bed)
      ? (_openBlock(), _createBlock("li", _hoisted_7))
      : _createCommentVNode("", true)
  ]))
}