<template>
  <ul class="lang-list" :class="{ open: isOpen }" @click="openLang">
    <li class="lang-list__item lang-list__item--ru"></li>
    <ul class="lang-list-options" v-show="isOpen">
      <li class="lang-list-options__item lang-list__item"></li>
    </ul>
  </ul>
</template>
<script>
export default {
  name: "langList",
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openLang() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>
