import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "search-result" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "results" }
const _hoisted_4 = { class: "results__header" }
const _hoisted_5 = { class: "sorting" }
const _hoisted_6 = { class: "results__title" }
const _hoisted_7 = { class: "sorting__item" }
const _hoisted_8 = { class: "sorting__item" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_sub_header = _resolveComponent("sub-header")
  const _component_avia_filter = _resolveComponent("avia-filter")
  const _component_avia_tiket = _resolveComponent("avia-tiket")
  const _component_Paginator = _resolveComponent("Paginator")
  const _component_avia_tarif = _resolveComponent("avia-tarif")
  const _component_order_create_modal = _resolveComponent("order-create-modal")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_sub_header, {
      canClose: true,
      canCreate: false,
      title: 'Результаты поиска',
      subText: '',
      linkBack: _ctx.linkBack
    }, null, 8, ["linkBack"]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_avia_filter, {
        filterByCompany: _ctx.filterByCompany,
        filterByAirportFrom: _ctx.filterByAirportFrom,
        filterByAirportTo: _ctx.filterByAirportTo,
        acceptFilter: _ctx.acceptFilter,
        acceptFilterAirlines: _ctx.acceptFilterAirlines,
        acceptFilterAirportFrom: _ctx.acceptFilterAirportFrom,
        acceptFilterAirportTo: _ctx.acceptFilterAirportTo
      }, null, 8, ["filterByCompany", "filterByAirportFrom", "filterByAirportTo", "acceptFilter", "acceptFilterAirlines", "acceptFilterAirportFrom", "acceptFilterAirportTo"]),
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("ul", _hoisted_5, [
            _createVNode("h3", _hoisted_6, " Найдено: " + _toDisplayString(_ctx.filteredVariants.length), 1),
            _createVNode("li", _hoisted_7, [
              _createVNode("a", {
                href: "#",
                class: "sorting__link",
                onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => (_ctx.sortByDuration(...args)), ["prevent"]))
              }, "Время ↑↓")
            ]),
            _createVNode("li", _hoisted_8, [
              _createVNode("a", {
                href: "#",
                class: "sorting__link",
                onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => (_ctx.sortByPrice(...args)), ["prevent"]))
              }, "Стоимость ↑↓")
            ]),
            _createVNode("button", {
              class: "order-create-btn",
              onClick: _cache[3] || (_cache[3] = _withModifiers((...args) => (_ctx.openOrderModal(...args)), ["prevent"]))
            }, " СОЗДАТЬ ")
          ])
        ]),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.paginatedVariants, (item) => {
          return (_openBlock(), _createBlock(_component_avia_tiket, {
            key: item.key,
            item: item,
            showTarrif: _ctx.showTarrif,
            deleteTicket: _ctx.deleteTicket,
            class: { selected: _ctx.isSelected(item.key) },
            isSelected: _ctx.isSelected(item.key)
          }, null, 8, ["item", "showTarrif", "deleteTicket", "class", "isSelected"]))
        }), 128 /* KEYED_FRAGMENT */)),
        _createVNode(_component_Paginator, {
          first: _ctx.paginatorFirst,
          rows: _ctx.paginationRows,
          totalRecords: _ctx.filteredVariants.length,
          onPage: _cache[4] || (_cache[4] = $event => (_ctx.onPage($event)))
        }, null, 8, ["first", "rows", "totalRecords"]),
        _createVNode(_component_avia_tarif, {
          showModalTarrif: _ctx.showModalTarrif,
          closeModal: _ctx.closeModal,
          keyActive: _ctx.keyActive
        }, null, 8, ["showModalTarrif", "closeModal", "keyActive"]),
        _createVNode(_component_order_create_modal, {
          showOrderModal: _ctx.showOrderModal,
          closeModal: _ctx.closeOrderModal,
          keyActive: _ctx.keyActive,
          passengersCount: _ctx.passengersCount,
          orderTypeId: _ctx.orderType,
          isAvia: ""
        }, null, 8, ["showOrderModal", "closeModal", "keyActive", "passengersCount", "orderTypeId"])
      ])
    ])
  ]))
}