
import { defineComponent, PropType, toRefs } from "vue";
import { transferOffer } from "../../../interfaceAndType/typesServices";
import SeparateLine from "../common/SeparateLine.vue";
import TransferFooter from "./transfer/TransferFooter.vue";
import TransferHeader from "./transfer/TransferHeader.vue";
import TransferRoute from "./transfer/TransferRoute.vue";

export default defineComponent({
  components: { TransferHeader, TransferRoute, SeparateLine, TransferFooter },
  name: "TransferCard",
  props: {
    item: {
      type: Object as PropType<transferOffer>
    },
    isModeOffer: {
      type: Boolean,
      default: false
    },
    isCansel: {
      type: Boolean,
      default: false
    },
    hasCansel: {
      type: Boolean,
      default: false
    },
    hasControl: {
      type: Boolean,
      default: true
    }
  },
  emits: ["select-offer", "unselect-offer"],
  setup(props, { emit }) {
    const { item } = toRefs(props);
    const selectOffer = () => {
      emit("select-offer", item?.value);
    };
    return { selectOffer };
  }
});
