
import {
  defineComponent,
  ref,
  reactive,
  PropType,
  toRefs,
  computed
} from "vue";
import Datepicker from "vuejs3-datepicker";
import SearchAirPort from "./SearchAirPort.vue";
import { AirPortType } from "../../../../../src/store/modules/avia/contract";

import { MigrationListType } from "../../../../interfaceAndType/typesSearh";
import { FLIGHT_HOURS_ARRAY } from "../../../../constant";

export default defineComponent({
  name: "SearchAviaElement",
  components: {
    Datepicker,
    SearchAirPort
  },
  props: {
    item: {
      type: Object as PropType<MigrationListType>,
      required: true
    },
    index: {
      type: Number as PropType<number>,
      required: true
    },
    isFromTo: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    setData: {
      type: Function,
      required: true
    },
    setDataToIn: {
      type: Function,
      required: true
    },
    searchData: {
      type: Function,
      required: true
    },
    wayType: {
      type: String
    },
    addRoute: {
      type: Function
    },
    deleteRoute: {
      type: Function
    },
    startHour: {
      type: String,
      required: true
    },
    endHour: {
      type: String,
      required: true
    },
    disabledSearch: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const { index, setData, startHour, endHour, setDataToIn, item } = toRefs(
      props
    );
    const selectFrom = (val: null | AirPortType) => {
      setData.value("origin", val ? val.entityCode : null, index.value);
    };
    const selectTo = (val: null | AirPortType) => {
      setData.value("destination", val ? val.entityCode : null, index.value);
    };
    const setDataFrom = (val: string) => {
      setData.value("date", val ? val : null, index.value);
    };
    const setDataTo = (val: string) => {
      setDataToIn.value(val ? val : null);
    };
    const hours = ref<Array<string>>(FLIGHT_HOURS_ARRAY);

    const hourFrom = computed({
      get() {
        return startHour.value;
      },
      set(val) {
        setData.value("startHour", val ? val : null, index.value);
      }
    });
    const hourTill = computed({
      get() {
        return endHour.value;
      },
      set(val) {
        setData.value("endHour", val ? val : null, index.value);
      }
    });
    const disabledDatesFrom = {
      to: new Date(),
      preventDisableDateSelection: true
    };
    const disabledDatesTo = computed(() => {
      return {
        to: new Date(item.value.date),
        preventDisableDateSelection: true
      };
    });
    return {
      selectFrom,
      selectTo,
      setDataFrom,
      setDataTo,
      hours,
      hourFrom,
      hourTill,
      disabledDatesFrom,
      disabledDatesTo
    };
  }
});
