
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  computed,
  PropType
} from "vue";
import OrderElement from "./tableElements/OrderElement.vue";
import OrderDetails from "./tableElements/OrderDetails.vue";
import {
  BusinessTripType,
  OfferListType,
  OfferTicketType,
  OrderDTOType,
  TripWithOrders
} from "../../interfaceAndType/typesTrips";
import { useStore } from "../../store/modules";
import { ActionTypes as TripActionTypes } from "../../store/modules/trips/contract";
import { TRIP_SORTING_FIELDS } from "../../constant";
import { ActionTypes as OrderActionTypes } from "../../store/modules/order/contract";
import { MutationTypes } from "../../store/modules/trips/MutationsType";

type order = {
  id: number;
};
type list = order[];
export default defineComponent({
  components: {
    OrderElement,
    OrderDetails
  },
  props: {
    trips: {
      type: Array as PropType<Array<BusinessTripType>>,
      required: true
    },
    sort: {
      type: Function as PropType<() => void>,
      required: true
    },
    orderBy: {
      type: String,
      required: true
    },
    orderDir: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const SORTING_FIELDS = TRIP_SORTING_FIELDS;
    const store = useStore();
    const { trips, sort, orderBy, orderDir } = toRefs(props);
    const tripList = computed(() => {
      return trips.value;
    });
    const listOrders = ref<TripWithOrders>();
    const activeOrder = ref<number>(0);
    const tickets = ref<Array<OfferTicketType | any>>([]);

    const getTickets = async () => {
      const orders: Array<OrderDTOType> = [];
      if (listOrders.value) {
        for (const order of listOrders.value.OrderList!) {
          const orderId = order.orderId;
          const cachOrder = store.getters.getOrderDataFromCach(orderId);
          let res: OrderDTOType | null = null;
          if (cachOrder) {
            res = cachOrder;
          } else {
            res = await store.dispatch(OrderActionTypes.GET_ORDER_OFFERS, {
              orderId: orderId
            });
            if (res) {
              store.commit(MutationTypes.SET_TRIP_ORDER_OFFERS_CACHE, {
                orderId: orderId,
                orderData: res
              });
            }
          }
          if (res) {
            orders.push(res);
          }
        }
      }
      const offers = orders.map(order => {
        return order.offerDTOList;
      });

      const tickets = offers.map(offer => {
        return offer?.map(item => JSON.parse(item.data!));
      });

      return tickets;
    };

    const toggleFun = async (id: number, isOpen) => {
      if (!isOpen) {
        const res = await store.dispatch(TripActionTypes.GET_TRIP_ORDERS, {
          tripId: id
        });
        listOrders.value = res;
        tickets.value = await getTickets();
      }
      activeOrder.value = activeOrder.value === id ? 0 : id;
    };
    const getSortClass = (field: string) => {
      let res = "";
      if (orderBy.value == field) {
        if (!orderDir.value) {
          res = "sorted_asc";
        } else {
          res = "sorted_desc";
        }
      }

      return res;
    };
    return {
      SORTING_FIELDS,
      tripList,
      listOrders,
      activeOrder,
      toggleFun,
      getTickets,
      tickets,
      getSortClass
    };
  }
});
