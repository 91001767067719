
import { defineComponent, PropType } from "vue";
import { NTrainListReference } from "../../../interfaceAndType/typesRail";

export default defineComponent({
  props: {
    openPricingModal: {
      type: Function
    },
    item: {
      type: Object as PropType<NTrainListReference>
    },
    isSelected: {
      type: Boolean
    },
    unselect: {
      type: Function,
    },
  },
  setup() {
    return {};
  }
});
