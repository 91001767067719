
import {
  defineComponent,
  nextTick,
  PropType,
  ref,
  toRef,
  toRefs,
  watch
} from "vue";
import { BaseHotelAccesor } from "../../interfaceAndType/typesHotel";
import AccesorsFilter from "./hotels/AccesorsFilter.vue";
import HotelServices from "./hotels/HotelServices.vue";

export default defineComponent({
  name: "HotelsFilter",
  props: {
    priceRange: {
      type: Array as PropType<Array<number>>,
      default: [1, 100]
    },
    accessors: {
      type: Array as PropType<Array<BaseHotelAccesor>>
    },
    filterByStars: {
      type: Function
    },
    filterByPrice: {
      type: Function
    },
    filterByServices: {
      type: Function
    },
    applyAllFilters: {
      type: Function
    },
    filterByAccesor: {
      type: Function
    },
    accessorSelect: {
      type: Array as PropType<Array<number>>
    }
  },
  components: {
    HotelServices,
    AccesorsFilter
  },
  setup(props) {
    const { priceRange } = toRefs(props);
    const start = ref(priceRange.value[0]);
    const end = ref(priceRange.value[1]);
    const price = ref([start.value, end.value]);
    const tooltipFormatter = "{value} ₽";
    const stars = ref<number>(0);
    const showSlider = ref(true);
    const activeIndex = ref([0]);
    watch(
      () => priceRange,
      () => {
        showSlider.value = false;
        if (priceRange && priceRange.value) {
          start.value = priceRange.value[0];
          end.value = priceRange.value[1];
          price.value = [start.value, end.value];
          nextTick(() => {
            showSlider.value = true;
          });
        }
      },
      { deep: true }
    );

    return {
      price,
      tooltipFormatter,
      stars,
      start,
      end,
      showSlider,
      activeIndex
    };
  }
});
