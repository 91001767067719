
import { defineComponent, ref, nextTick } from "vue";
import _ from "lodash";
import { useStore } from "../../../../store/modules";
import {
  ActionTypes,
  AirPortType
} from "../../../../../src/store/modules/avia/contract";
const MIN_LEN_SEARCH = 2;
export default defineComponent({
  name: "SearchAirPort",
  props: {
    placeholder: String
  },
  setup(props, { emit }) {
    // пока так не могу обойти фильтра селекта
    type extendAirPort = {
      entityId: number;
      languageId: number;
      entityCode: string;
      entityName: string;
      complexName: string;
    };
    type searchCity = Array<extendAirPort>;
    const selectedCountries = ref<AirPortType | null>(null);
    const countries = ref<searchCity>([]);
    const isLoading = ref<boolean>(false);
    const store = useStore();
    const limitText = count => {
      return `and ${count} other countries`;
    };

    const asyncFindFunc = _.debounce(async (query: string) => {
      console.log("search");
      isLoading.value = true;
      const paramsIn = {
        searhAirport: query
      };
      const res = await store.dispatch(ActionTypes.SEARH_AIRPORT, paramsIn);

      if (res) {
        countries.value = res.map(item => {
          return {
            ...item,
            complexName: item.entityCode + " " + item.entityName
          };
        });
      }
      isLoading.value = false;
    }, 500);

    const asyncFind = (event: { query: string }) => {
      if (event && event.query.length > MIN_LEN_SEARCH) {
        asyncFindFunc(event.query);
      }
    };

    const select = event => {
      selectedCountries.value = event.value;
      let convetData: AirPortType | null = null;
      if (event) {
        convetData = {
          entityId: event.value.entityId,
          languageId: event.value.languageId,
          entityCode: event.value.entityCode,
          entityName: event.value.entityName
        };
      }

      emit("select-data", convetData);
    };
    return {
      selectedCountries,
      countries,
      isLoading,
      limitText,
      asyncFind,
      select
    };
  }
});
