import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Dropdown = _resolveComponent("Dropdown")

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.localSelected,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.localSelected = $event)),
    options: _ctx.options,
    optionLabel: _ctx.label,
    placeholder: _ctx.placeholder
  }, null, 8, ["modelValue", "options", "optionLabel", "placeholder"]))
}