import axios from "axios";
import { MAIN_HOST, NAME_TOKEN } from "../constant";
export class Http {
  token = "";
  constructor() {
    this.token = localStorage.getItem(NAME_TOKEN)
      ? (localStorage.getItem(NAME_TOKEN) as string)
      : "";
  }

  HTTP() {
    const initData = {
      baseURL: MAIN_HOST,
      headers: this.token
        ? {
            Authorization: `Bearer ${this.token}`
          }
        : {}
    };
    return axios.create(initData);
  }
}
