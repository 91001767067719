import { MutationTree } from "vuex";
import { LoaderType, State } from "./contract";
import { MutationTypes, Mutations } from "./MutationsType";
// Define mutations
export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.ADD_ERROR](state: State, { errors, warnings }) {
    if (errors || warnings) {
      state.errors = errors;
      state.warnings = warnings;
    }
  },
  /**
   * Показ загрузчика сложный тип
   * @param state
   * @param param1
   */
  [MutationTypes.CONTROL_LOADER](state: State, { loader }) {
    const findIndex = state.showLoader.findIndex(i => i.event === loader.event);
    const copyLoader: LoaderType = JSON.parse(JSON.stringify(loader));

    if (findIndex > -1) {
      state.showLoader[findIndex].show = copyLoader.show;
    } else {
      state.showLoader.push(copyLoader);
    }
  },
  /**
   * Показ загрузчика простой вызов
   * @param state
   * @param param1
   */
  [MutationTypes.LOADER_SET](
    state: State,
    { event, show = true, message = "" }
  ) {
    const copyLoader: LoaderType = {
      event,
      show,
      message
    };
    const findIndex = state.showLoader.findIndex(
      i => i.event === copyLoader.event
    );
    if (findIndex > -1) {
      state.showLoader[findIndex].show = copyLoader.show;
    } else {
      state.showLoader.push(copyLoader);
    }
  },
  [MutationTypes.CLEAR_ERRORS](state: State) {
    state.errors = [];
    state.warnings = [];
  }
};
