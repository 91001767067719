
import { computed, defineComponent, PropType, toRefs } from "vue";
import { MultiItinerary } from "../../../interfaceAndType/typesSearh";
import router from "../../../router";

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<MultiItinerary>,
      required: true,
    },
    btnTarrif: {
      type: Boolean,
      default: true,
    },
    showTarrif: {
      type: Function,
    },
    deleteTicket: {
      type: Function,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    tripId: {
      type: Number,
    },
    isModeOffer: {
      type: Boolean,
      default: false,
    },
    toogleFareInfo: {
      type: Function,
    },
    isCansel: {
      type: Boolean,
      default: false,
    },
    hasCansel: {
      type: Boolean,
      default: false,
    },
    hasControl: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["select-offer", "unselect-offer"],
  setup(props, { emit }) {
    const { item } = toRefs(props);
    const totalPrice = computed(() => {
      const nowData = item.value;
      let sum = 0;
      if (nowData && nowData.itineraries) {
        nowData.itineraries.forEach((it) => {
          sum += it.equivTotal;
        });
      }
      return sum;
    });
    const goTrip = (id) => {
      router.push(`/trip/${id}`);
    };
    const setSelect = () => {
      emit("select-offer", item.value);
    };
    const unselect = () => {
      emit("unselect-offer", null);
    };
    return { totalPrice, goTrip, setSelect, unselect };
  },
});
