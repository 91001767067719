
import { computed, defineComponent, PropType, ref, toRefs } from "vue";
import SearchCardFooter from "../components/search/SearchCardFooter.vue";
import AviaTarifPlan from "../tikets/avia/AviaTarifPlan.vue";

import { useStore } from "../../store/modules";
import { ActionTypes } from "../../store/modules/avia/contract";
import { MutationTypes } from "../../store/modules/avia/MutationsType";
import {
  ItinerariesToSelect,
  Itinerary,
  MultiItinerary
} from "../../interfaceAndType/typesSearh";
export default defineComponent({
  name: "AviaTarif",
  props: {
    showModalTarrif: { type: Boolean as PropType<boolean>, default: false },
    closeModal: { type: Function, required: true },
    keyActive: { type: String as PropType<string>, required: true }
  },
  setup(props) {
    const store = useStore();
    const { showModalTarrif, closeModal, keyActive } = toRefs(props);
    const selected = ref<Array<string | null>>([]);
    const localDisplay = computed({
      get: () => showModalTarrif.value,
      set: val => {
        closeModal.value(val);
      }
    });

    const dataPrice = computed(() => {
      const res = store.getters.getTarifTiket(keyActive.value);
      if (res) {
        return res.routeParts.itinerariesToSelect
          ? res.routeParts.itinerariesToSelect
          : [];
      }
      return [];
    });
    const nowVarinat = computed(() => {
      const nowVariant = store.getters.getVariantByKey(keyActive.value);
      return nowVariant;
    });
    const save = () => {
      if (dataPrice.value) {
        const copyNowVariant: MultiItinerary = JSON.parse(
          JSON.stringify(nowVarinat.value)
        );
        const newItirnaries: Array<Itinerary> = [];

        selected.value.forEach((keySelect, indexGroup) => {
          const groupTarifs: ItinerariesToSelect | null = dataPrice.value[
            indexGroup
          ]
            ? dataPrice.value[indexGroup]
            : null;
          const groupTarif = groupTarifs ? groupTarifs.itinerary : [];
          const findTarrif = groupTarif
            ? groupTarif.find(item => item.key === keySelect)
            : null;
          if (findTarrif) {
            newItirnaries.push(findTarrif);
          }
        });
        copyNowVariant.itineraries = newItirnaries;
        store.commit(MutationTypes.CHANGE_VARIANT, {
          newVariant: copyNowVariant
        });
        if (copyNowVariant.key !== null) {
          store.commit(MutationTypes.SELECT_AVIA_TICKET, {
            ticketKey: copyNowVariant.key
          });
        } else {
          console.log("ключ имеет значение null");
        }
        closeModal.value();
      }
    };

    const isSelect = (keyTarifVarinat: string) => {
      let res = false;
      const findSelected = selected.value.find(
        item => item === keyTarifVarinat
      );
      if (findSelected) {
        res = true;
      }
      return res;
    };

    const setSelectTarif = (keyTarifVarinat: string, groupIndex: number) => {
      const findSelected = selected.value.find(
        item => item === keyTarifVarinat
      );
      if (findSelected) {
        selected.value[groupIndex] = null;
      } else {
        selected.value[groupIndex] = keyTarifVarinat;
      }
    };
    const loadData = async () => {
      selected.value = [];
      try {
        // проверяем есть ли данные в сторе
        if (!(dataPrice.value && dataPrice.value.length > 0)) {
          await store.dispatch(ActionTypes.LOAD_PRICING, {
            keytiket: keyActive.value
          });
        }
        // заполним прайсинг на основе данных которые пришли из прайсинага
        dataPrice.value.forEach(item => {
          selected.value.push(null);
        });
        if (nowVarinat.value) {
          // проставим ключи которые сейчас выбраны сохраняя порядок

          if (nowVarinat.value.itineraries) {
            nowVarinat.value.itineraries.forEach((item, index) => {
              selected.value[index] = item.key;
            });
          }
        }
        console.log(nowVarinat.value);
      } catch (e) {
        console.error(e);
      }
    };
    return {
      localDisplay,
      save,
      loadData,
      dataPrice,
      isSelect,
      setSelectTarif
    };
  },
  components: {
    SearchCardFooter,
    AviaTarifPlan
  }
});
// в билете делать проверку выбран или нет на основе ключа, добавить возможность удаления выбраннгого билета
