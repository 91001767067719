import {
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions
} from "vuex";

import { State as RootState } from "../index";
import { GlobalMutations as Mutations } from "../allMutationsType";

export type UserProfile = {
  userId: number;
  email: string;
  name: string;
  roleName: string;
  roleId: number;
  roleCode: string;
  companyId: number;
  companyName: string;
  isActive: boolean;
};

// Declare state
export type State = {
  isAuthenticated: boolean;
  token: string | null;
  userProfile: null | UserProfile;
};

// Action enums
export enum ActionTypes {
  AUTH_LOGIN = "AUTH_LOGIN",
  AUTH_ME = "AUTH_ME",
  AUTH_LOGOUT = "AUTH_LOGOUT"
}

// Actions contracts
export interface Actions {
  [ActionTypes.AUTH_LOGIN](
    { commit }: AugmentedActionContext,
    payload: { email: string; password: string }
  ): void;
  [ActionTypes.AUTH_ME]({
    commit,
    state: State
  }: AugmentedActionContext): Promise<boolean>;
  [ActionTypes.AUTH_LOGOUT]({ commit }: AugmentedActionContext): void;
}

// getters types
export type Getters = {
  isAuthenticated(state: State): boolean;
  hasProfile(state: State): boolean;
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

// // getters
// export const getters: GetterTree<State, RootState> & Getters = {
//   isAuthenticated: state => {
//     return state.isAuthenticated;
//   }
// };

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  "commit" | "getters" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
