import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "checkboxes-wrapper checkboxes-wrapper--airport" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Checkbox = _resolveComponent("Checkbox")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.allAirports, (arrivalAirport) => {
      return (_openBlock(), _createBlock("div", {
        class: "p-field-checkbox",
        key: arrivalAirport.code
      }, [
        _createVNode(_component_Checkbox, {
          id: `${arrivalAirport.code}arrival`,
          name: "airportTo",
          value: arrivalAirport.code,
          modelValue: _ctx.selectedAirport,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.selectedAirport = $event))
        }, null, 8, ["id", "value", "modelValue"]),
        _createVNode("label", {
          for: `${arrivalAirport.code}arrival`
        }, _toDisplayString(arrivalAirport.rus), 9, ["for"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}