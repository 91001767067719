
import { defineComponent, PropType } from "@vue/runtime-core";
import { transferOffer } from '../../../../interfaceAndType/typesServices';

export default defineComponent({
  name: "TransferHeader",
  component: {},
  props: {
    item: {
      type: Object as PropType<transferOffer>
    },
  },
  setup(props) {
    return {}
  }
});
