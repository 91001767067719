import { Module } from "vuex";
import { State as RootState } from "../index";
import { State } from "./contract";

import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";
import { NAME_TOKEN } from "../../../constant";
// Create initial state
const tokenInit = localStorage.getItem(NAME_TOKEN)
  ? localStorage.getItem(NAME_TOKEN)
  : "";

const state: State = {
  isAuthenticated: false,
  token: tokenInit,
  userProfile: null
};

export const AuthModule: Module<State, RootState> = {
  state,
  mutations,
  actions,
  getters
};
