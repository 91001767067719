
import { computed, defineComponent, PropType, toRefs } from "vue";

import { Flight } from "../../../interfaceAndType/typesSearh";
import moment from "moment";
type infodataType = {
  baggage: string;
  time: string;
  plane: string;
  codePlane: string;
  operatin: string;
};

export default defineComponent({
  props: {
    allFlight: {
      type: Object as PropType<Array<Flight> | null>,
      required: true
    }
  },
  setup(props) {
    const { allFlight } = toRefs(props);
    const convertMin = min => {
      const nowMin = moment.duration(min, "minutes");
      const d = nowMin.days();
      const h = nowMin.hours();
      const m = nowMin.minutes();
      return `${d}д:${h}ч:${m}м`;
    };
    const infoData = computed(() => {
      const flights = allFlight.value;
      if (flights && flights.length > 0 && flights[0]) {
        const fl = flights[0];
        if (fl) {
          return {
            baggage: fl.baggage ? fl.baggage : "",
            time: fl.durationMinutes ? convertMin(fl.durationMinutes) : "0",
            plane: fl.airplane ? fl.airplane.name : "н/д",
            codePlane: fl.airplaneCode ? fl.airplaneCode : "н/д",
            operatin: fl.operatingAirline ? fl.operatingAirline : "н/д",
            number: fl.number
          };
        }
      }
      return null;
    });
    return { infoData };
  }
});
