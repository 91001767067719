
import {
  defineComponent,
  ref,
  PropType,
  toRefs,
  computed,
  reactive,
  onMounted,
  watch
} from "vue";
import _ from "lodash";
import OrderAviaTicket from "../tikets/order/OrderAviaTicket.vue";
import HotelsModalCard from "../tikets/hotel/HotelsModalCard.vue";
import { ActionTypes as OrderActionTypes } from "../../store/modules/order/contract";
import { ActionTypes as TripsActionTypes } from "../../store/modules/trips/contract";
import { useToast } from "primevue/usetoast";
import { useStore } from "../../store/modules";
import { OrderCreateReqType } from "../../interfaceAndType/typesOrder";
import router from "../../router";
import { DropDownEntity } from "../../interfaceAndType/typesShared";
import { EVENT_LOADER } from "../../store/modules/system/contract";
import {
  ExtendedHotelInfo,
  ExtendedRate
} from "../../interfaceAndType/typesHotel";
import RailTiketSmall from "../tikets/rail/RailTiketSmall.vue";
import {
  OfferTicketType,
  OrderDTOType,
  TripWithOrders
} from "../../interfaceAndType/typesTrips";
import { MutationTypes as TripsMutationTypes } from "../../store/modules/trips/MutationsType";
import OrderDetails from "../trips/tableElements/OrderDetails.vue";
import TransferCard from "../tikets/services/TransferCard.vue";
import FreeCard from "../tikets/services/FreeCard.vue";
const ActionTypesConstants = { ...OrderActionTypes };
import EmailList from "./createModal/EmailList.vue";
export default defineComponent({
  name: "OrderCreateModal",
  props: {
    showOrderModal: { type: Boolean, default: false },
    closeModal: { type: Function, required: true },
    keyActive: { type: String as PropType<string> },
    passengersCount: { type: Object, default: null },
    orderTypeId: { type: Number, required: true },
    isFree: { type: Boolean, default: false }, // ключ говорит что это форма свободной услуги
    isAvia: { type: Boolean, default: false }, // ключ говорит что это форма авия
    isService: { type: Boolean, default: false }, // ключ говорит что это форма сервиса
    isHotel: { type: Boolean, default: false }, // ключ говорит о том что это форма отелей
    isRail: { type: Boolean, default: false }, // ключ говорит о том что это форма жд
    dataSend: { type: Object } // данные для отправки если они нужны
  },
  setup(props) {
    const { orderTypeId, passengersCount } = toRefs(props);
    const {
      showOrderModal,
      closeModal,
      isAvia,
      isService,
      isHotel,
      isRail,
      dataSend
    } = toRefs(props);
    const toast = useToast();
    const store = useStore();
    const names = [
      EVENT_LOADER.DEF_LOUDER,
      EVENT_LOADER.LOGIN_USER,
      EVENT_LOADER.LOAD_USER_DATA
    ];
    const newOrder = ref<boolean>(false);
    const aviaOffer = computed(() => {
      return store.getters.getSelectedTickets;
    });
    const hotelsOffer = computed((): ExtendedHotelInfo[] => {
      return store.getters.getSelectedRooms
        ? store.getters.getSelectedRooms
        : [];
    });
    const hotelRooms = computed(() => {
      if (hotelsOffer.value) {
        let rooms: ExtendedRate[] = [];
        hotelsOffer.value.forEach(item => {
          rooms = [...rooms, ...item.rooms!];
        });
        return rooms;
      }
    });
    const railOffer = computed(() => {
      return store.getters.getSelectedTrains;
    });

    const defaultOrder = ref<OrderCreateReqType>({
      businessTripId: null,
      businessTrip: {
        name: null,
        clientId: 0,
        contractId: 0,
        initiatorId: null
      },
      orderTypeId: orderTypeId.value,
      emailList: [],
      isSend: false,
      adultCount: passengersCount.value ? passengersCount.value.adults : null,
      childCount: passengersCount.value ? passengersCount.value.children : null,
      babyCount: passengersCount.value ? passengersCount.value.infants : null,
      budgetCodeValues: null,
      offerList: []
    });

    watch([orderTypeId, passengersCount], () => {
      console.log(passengersCount);
      defaultOrder.value = {
        businessTripId: null,
        businessTrip: {
          name: null,
          clientId: 0,
          contractId: 0,
          initiatorId: null
        },
        orderTypeId: orderTypeId.value,
        emailList: [],
        isSend: false,
        adultCount: passengersCount.value ? passengersCount.value.adults : null,
        childCount: passengersCount.value
          ? passengersCount.value.children
          : null,
        babyCount: passengersCount.value ? passengersCount.value.infants : null,
        budgetCodeValues: null,
        offerList: []
      };
    });
    const localDisplay = computed({
      get: () => showOrderModal.value,
      set: val => {
        closeModal.value(val);
      }
    });

    interface Lists {
      clients: Array<DropDownEntity | null>;
      contracts: Array<DropDownEntity | null>;
      initiators: Array<DropDownEntity | null>;
      trips: Array<DropDownEntity | null>;
    }
    const lists = reactive<Lists>({
      clients: [],
      contracts: [],
      initiators: [],
      trips: []
    });
    const selectedClient = ref<DropDownEntity | null>(null);
    const selectedContract = ref<DropDownEntity | null>(null);
    const selectedInitiator = ref<DropDownEntity | null>(null);
    const selectedTrip = ref<DropDownEntity | null>(null);
    const tripName = ref<string>("");
    const isLoading = ref<boolean>(false);

    type paramsIn = {
      searchParam?: string;
      clientId?: number;
      contractId?: number;
      search?: string;
    };

    const asyncFindFunc = _.debounce(
      async (query: string, list: string, actionType: string) => {
        isLoading.value = true;
        let paramsIn: paramsIn = {
          searchParam: query
        };
        if (actionType === ActionTypesConstants.GET_CONTRACT) {
          paramsIn = {
            ...paramsIn,
            clientId: defaultOrder.value.businessTrip.clientId
          };
        }
        if (actionType === ActionTypesConstants.GET_INITIATOR) {
          paramsIn = {
            ...paramsIn,
            contractId: defaultOrder.value.businessTrip.contractId
          };
        }
        if (actionType === ActionTypesConstants.GET_TRIP) {
          paramsIn = {
            search: query
          };
        }

        lists[list] = await store.dispatch(
          OrderActionTypes[actionType],
          paramsIn
        );

        isLoading.value = false;
      },
      500
    );

    const asyncFind = (
      event,
      variants: Array<DropDownEntity>,
      actionType: string
    ) => {
      asyncFindFunc(event.query, variants, actionType);
    };

    const listOrders = ref<TripWithOrders>();
    const tickets = ref<Array<OfferTicketType | any>>([]);
    const getTickets = async () => {
      const orders: Array<OrderDTOType> = [];
      if (listOrders.value) {
        for (const order of listOrders.value.OrderList!) {
          const orderId = order.orderId;
          const cachOrder = store.getters.getOrderDataFromCach(orderId);
          let res: OrderDTOType | null = null;
          if (cachOrder) {
            res = cachOrder;
          } else {
            res = await store.dispatch(OrderActionTypes.GET_ORDER_OFFERS, {
              orderId: orderId
            });
            if (res) {
              store.commit(TripsMutationTypes.SET_TRIP_ORDER_OFFERS_CACHE, {
                orderId: orderId,
                orderData: res
              });
            }
          }
          if (res) {
            orders.push(res);
          }
        }
      }
      const offers = orders.map(order => {
        return order.offerDTOList;
      });

      const tickets = offers.map(offer => {
        return offer?.map(item => JSON.parse(item.data!));
      });

      return tickets;
    };
    const selectTrip = async () => {
      if (defaultOrder.value && defaultOrder.value.businessTripId) {
        const res = await store.dispatch(TripsActionTypes.GET_TRIP_ORDERS, {
          tripId: defaultOrder.value.businessTripId as number
        });
        if (res) {
          listOrders.value = res;
          tickets.value = await getTickets();
        }
      }
    };

    const setData = (event, key: string) => {
      if (key === "businessTripId") {
        defaultOrder.value.businessTrip = {
          name: event.value.name,
          clientId: event.value.clientId,
          contractId: event.value.contractId,
          initiatorId: event.value.initiatorId
        };
        defaultOrder.value.businessTripId = event.value.businessTripId;
        selectTrip();
      } else {
        defaultOrder.value.businessTrip[key] = event.value.entityId;
      }
    };

    const resetOrderValues = () => {
      tripName.value = "";
      defaultOrder.value.businessTripId = null;
      defaultOrder.value.businessTrip = {
        name: null,
        clientId: 0,
        contractId: 0,
        initiatorId: null
      };
      defaultOrder.value.businessTrip.clientId;
      selectedTrip.value = null;
      selectedInitiator.value = null;
      selectedContract.value = null;
      selectedClient.value = null;
    };

    const createNewOrder = () => {
      newOrder.value = !newOrder.value;
      tickets.value = [];
      resetOrderValues();
    };

    const sendOrder = async () => {
      if (isAvia.value) {
        defaultOrder.value.offerList = aviaOffer.value;
      }
      if (isService.value && dataSend && dataSend.value) {
        defaultOrder.value.offerList = [dataSend.value];
      }
      if (isHotel.value) {
        defaultOrder.value.offerList = hotelsOffer.value;
      }
      if (isRail.value) {
        defaultOrder.value.offerList = railOffer.value;
      }
      const res = await store.dispatch(OrderActionTypes.SEND_ORDER, {
        order: defaultOrder.value
      });
      if (res) {
        toast.add({
          severity: "success",
          summary: "Успешно",
          detail: "Заказ успешно создан",
          life: 3000
        });
        router.push("/");
      }
      closeModal.value(false);
      resetOrderValues();
    };

    const isValid = computed(() => {
      const businessTripValid = Object.values(defaultOrder.value.businessTrip).every(val => !!val);
      if(defaultOrder.value.isSend && defaultOrder.value.emailList) {
        const emailLengthValid = defaultOrder.value.emailList.length > 0;
        if(emailLengthValid) {
          const emailValidation = defaultOrder.value.emailList[0].includes("@" && ".");
          if(businessTripValid && emailLengthValid && emailValidation) {
            return true
          }
        }
        return false;
      }
      
      return businessTripValid;
    });
    const setEmail = (emails: Array<string>) => {
      defaultOrder.value.emailList = emails;
    };
    const addEmail = () => {
      defaultOrder.value.emailList?.push("");
    };
    return {
      newOrder,
      localDisplay,
      names,
      lists,
      selectedClient,
      selectedContract,
      selectedInitiator,
      selectedTrip,
      tripName,
      isLoading,
      asyncFind,
      defaultOrder,
      setData,
      ActionTypesConstants,
      aviaOffer,
      hotelsOffer,
      railOffer,
      hotelRooms,
      createNewOrder,
      sendOrder,
      tickets,
      isValid,
      setEmail,
      addEmail
    };
  },
  components: {
    OrderAviaTicket,
    HotelsModalCard,
    RailTiketSmall,
    OrderDetails,
    TransferCard,
    FreeCard,
    EmailList
  }
});
