
import { defineComponent, computed, PropType, toRefs } from "vue";

import AviaTikets from "../../tikets/avia/AviaTikets.vue";
import { useStore } from "../../../store/modules/index";
import { MutationTypes } from "../../../store/modules/avia/MutationsType";

export default defineComponent({
  name: "OrderAviaTicket",
  props: {},
  setup(props) {
    const store = useStore();
    const selectedTickets = computed(() => {
      return store.getters.getSelectedTickets;
    });
    const deleteTicket = key => {
      store.commit(MutationTypes.DELETE_SELECTED_AVIA_TICKET, {
        ticketKey: key
      });
    };

    return { selectedTickets, deleteTicket };
  },
  components: {
    AviaTikets
  }
});
