
import { defineComponent, ref } from "vue";
import _ from "lodash";
import { useStore } from "vuex";
import { DropDownEntity } from "../../../../../interfaceAndType/typesShared";
import { ActionTypes } from "../../../../../store/modules/rail/contract";

export default defineComponent({
  name: "RailSearchInput",
  props: {
    placeholder: String
  },
  setup(props, { emit }) {
    // пока так не могу обойти фильтра селекта
    type extendDropDownEntity = {
      entityId: number;
      languageId: number;
      entityCode: string;
      entityName: string;
      complexName: string;
    };
    type railStationsType = Array<extendDropDownEntity>;
    const selectedStation = ref<DropDownEntity | null>(null);
    const railStations = ref<railStationsType>([]);
    const isLoading = ref<boolean>(false);
    const store = useStore();
    const limitText = count => {
      return `and ${count} other countries`;
    };

    const asyncFindFunc = _.debounce(async (query: string) => {
      console.log("search");
      isLoading.value = true;
      const paramsIn = {
        station: query
      };
      const res = await store.dispatch(ActionTypes.SEARCH_STATION, paramsIn);

      if (res) {
        railStations.value = res.map(item => {
          return {
            ...item,
            complexName: item.entityCode + " " + item.entityName
          };
        });
      }
      isLoading.value = false;
    }, 500);

    const asyncFind = (event: { query: string }) => {
      asyncFindFunc(event.query);
    };

    const select = event => {
      selectedStation.value = event.value;
      let convetData: DropDownEntity | null = null;
      if (event) {
        convetData = {
          entityId: event.value.entityId,
          languageId: event.value.languageId,
          entityCode: event.value.entityCode,
          entityName: event.value.entityName
        };
      }

      emit("select-data", convetData);
    };
    return {
      selectedStation,
      railStations,
      isLoading,
      limitText,
      asyncFind,
      select
    };
  }
});
