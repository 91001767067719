
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  PropType,
  toRef,
  toRefs
} from "vue";
import HotelsModalCard from "../tikets/hotel/HotelsModalCard.vue";
import {
  ExtendedHotelInfo,
  HotelTreeItem,
  ExtendedRate,
  BaseHotelRateAccessor
} from "../../interfaceAndType/typesHotel";
import { useStore } from "../../store/modules";
import { MutationTypes } from "../../store/modules/hotels/MutationsType";
import { ActionTypes } from "../../store/modules/hotels/contract";
import AccesorRoomFilter from "./hotel/AccesorRoomFilter.vue";

export default defineComponent({
  name: "HotelsModal",
  components: { HotelsModalCard, AccesorRoomFilter },
  props: {
    display: {
      type: Boolean,
      default: false
    },
    toggleDisplay: {
      type: Function,
      default: () => {
        return;
      }
    },
    item: {
      type: Object as PropType<HotelTreeItem | null>,
      required: true
    }
  },
  setup(props) {
    const { item } = toRefs(props);
    const store = useStore();
    const images = ref<Array<object>>([]);
    const selectSistem = ref<number | null>(null);
    const localDisplay = computed({
      get: () => {
        return props.display;
      },
      set: () => {
        props.toggleDisplay();
      }
    });
    const currentCityId = computed(() => {
      if (store.state.hotels.searchReq)
        return store.state.hotels.searchReq.cityId;
    });
    const extendedHotelInfo = ref<ExtendedHotelInfo>();
    const rooms = computed(() => {
      return extendedHotelInfo.value ? extendedHotelInfo.value.rooms : null;
    });
    const getPricing = async () => {
      if (
        item &&
        item.value &&
        item.value.accessKey &&
        !store.state.hotels.pricingCache[item.value.accessKey]
      ) {
        if (item && item.value && currentCityId.value) {
          const data = await store.dispatch(ActionTypes.GET_HOTEL_PRICE, {
            accessors: item.value.accessors,
            cityId: currentCityId.value
          });
          if (data && data.extendedHotelInfo && item && item.value.accessKey) {
            extendedHotelInfo.value = data.extendedHotelInfo;
            store.commit(MutationTypes.SET_HOTEL_PRICE, {
              data: data.extendedHotelInfo,
              key: item.value.accessKey
            });
            if (data.extendedHotelInfo.images) {
              images.value = data.extendedHotelInfo.images.map(image => {
                return {
                  itemImageSrc: image,
                  thumbnailImageSrc: image,
                  alt: "Фотографии номеров"
                };
              });
            }
          }
        }
      } else {
        if (item && item.value && item.value.accessKey) {
          extendedHotelInfo.value =
            store.state.hotels.pricingCache[item.value.accessKey];
          if (extendedHotelInfo.value && extendedHotelInfo.value.images) {
            images.value = extendedHotelInfo.value.images.map(image => {
              return {
                itemImageSrc: image,
                thumbnailImageSrc: image,
                alt: "Фотографии номеров"
              };
            });
          }
        }
      }
    };
    const modifiedHotelInfo = ref<ExtendedHotelInfo | null>();
    const select = room => {
      if (extendedHotelInfo.value) {
        modifiedHotelInfo.value = JSON.parse(
          JSON.stringify(extendedHotelInfo.value)
        );
        if (modifiedHotelInfo.value) {
          modifiedHotelInfo.value.rooms = [room];
        }
        if (
          item &&
          item.value &&
          item.value.accessKey &&
          modifiedHotelInfo.value
        ) {
          store.commit(MutationTypes.SELECT_ROOM, {
            data: modifiedHotelInfo.value,
            key: item.value.accessKey
          });
        }
      }
    };
    const unselect = () => {
      if (item && item.value && item.value.accessKey) {
        store.commit(MutationTypes.UNSELECT_ROOM, {
          key: item.value.accessKey
        });
      }
    };
    const isSelect = (key: string) => {
      return store.getters.isSelectedRoom(key, item.value!.accessKey!);
    };

    const roomAccesor = computed(() => {
      const res: BaseHotelRateAccessor[] = [];
      if (rooms.value && rooms.value?.length > 0) {
        rooms.value?.forEach(room => {
          res.push(room.baseHotelRateAccessor);
        });
        return res;
      }

      return [];
    });

    const setService = (idSystem: number | null) => {
      selectSistem.value = idSystem;
    };
    const showRoom = (room: ExtendedRate) => {
      if (!selectSistem.value) {
        return true;
      }
      if (room.baseHotelRateAccessor.id_System == selectSistem.value) {
        return true;
      }
      return false;
    };
    return {
      images,
      localDisplay,
      getPricing,
      extendedHotelInfo,
      rooms,
      select,
      unselect,
      isSelect,
      roomAccesor,
      setService,
      showRoom
    };
  }
});
