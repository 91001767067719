import {
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions
} from "vuex";

import { State as RootState } from "../index";
import { GlobalMutations as Mutations } from "../allMutationsType";
import {
  BusinessTripType,
  CheckOffer,
  CreateBooking,
  FilterActiveOrder,
  OfferListType,
  OrderDTOType,
  TripFullDTO
} from "@/interfaceAndType/typesTrips";
import { TripWithOrders } from "../../../interfaceAndType/typesTrips";
import { PersonType } from "@/interfaceAndType/typesShared";

// import {Getters as OrderGetters} from "../order/contract"
// Declare state
export type State = {
  trips: Array<BusinessTripType>;
  pageCount: number | null;
  tripTicketsCach: { [key: string]: TripWithOrders };
  orderTicketsCach: { [key: number]: OrderDTOType };
  //-----------------------------------
  activeOrder: Array<OrderDTOType>; // текуший активный ордер!
  activeOrderIsLoad: boolean;
  //-----------------------------------
  activeTypeCode: string | null; // активынй тип
  activeOfferKey: string | number | null; // активное предложение
  //------------------------------------
  acceptTrip: TripFullDTO | null; // активное предложение для подтверждения
};

// Action enums
export enum ActionTypes {
  GET_TRIPS = "GET_TRIPS",
  GET_TRIP_ORDERS = "GET_TRIP_ORDERS",
  //---------------------------
  BOOKING_AVIA = "BOOKING_AVIA",
  TICKETTING_AVIA = "TICKETTING_AVIA",
  CANCEL_AVIA = "CANCEL_AVIA",
  //------------------------------
  BOOKING_HOTEL = "BOOKING_HOTEL",
  TICKETTING_HOTEL = "TICKETTING_HOTEL",
  CANCEL_HOTEL = "CANCEL_HOTEL",
  //----------------------------
  BOOKING_TRANS_FREE = "BOOKING_TRANS_FREE",
  //----------------------------
  BOOKING_RAILWAY = "BOOKING_RAILWAY",
  TICKETTING_RAILWAY = "TICKETTING_RAILWAY",
  CANCEL_RAILWAY = "CANCEL_RAILWAY",
  REJECT_RAILWAY = "REJECT_RAILWAY",
  //----------------------------
  LOAD_TIKET = "LOAD_TIKET",
  SEND_TRIP = "SEND_TRIP",
  //----------------------------
  LOAD_ACCEPT_TRIP = "LOAD_ACCEPT_TRIP",
  ACCEPT_TRIP = "ACCEPT_TRIP"
}

// Actions contracts
export interface Actions {
  [ActionTypes.GET_TRIPS](
    { commit }: AugmentedActionContext,
    payload: {
      search?: string;
      page?: number;
      orderBy?: string;
      orderDir?: boolean;
    }
  ): Promise<Array<BusinessTripType>>;
  [ActionTypes.GET_TRIP_ORDERS](
    { commit }: AugmentedActionContext,
    payload: {
      tripId: number;
    }
  ): Promise<TripWithOrders>;
  //-------------------avia-----------------
  [ActionTypes.BOOKING_AVIA](
    { commit }: AugmentedActionContext,
    payload: {
      PersonList: Array<PersonType>;
      OfferId: string | number;
    }
  ): Promise<boolean>;
  [ActionTypes.TICKETTING_AVIA](
    { commit }: AugmentedActionContext,
    payload: {
      OfferId: string | number;
    }
  ): Promise<boolean>;
  [ActionTypes.CANCEL_AVIA](
    { commit }: AugmentedActionContext,
    payload: {
      OfferId: string | number;
    }
  ): Promise<boolean>;

  //-------------------hotel-----------------

  [ActionTypes.BOOKING_HOTEL](
    { commit }: AugmentedActionContext,
    payload: {
      sendData: CreateBooking;
      OfferId: string | number;
    }
  ): Promise<boolean>;
  [ActionTypes.TICKETTING_HOTEL](
    { commit }: AugmentedActionContext,
    payload: {
      OfferId: string | number;
    }
  ): Promise<boolean>;
  [ActionTypes.CANCEL_HOTEL](
    { commit }: AugmentedActionContext,
    payload: {
      OfferId: string | number;
    }
  ): Promise<boolean>;

  //-----------TRANS_FREE --------------

  [ActionTypes.BOOKING_TRANS_FREE](
    { commit }: AugmentedActionContext,
    payload: {
      PersonList: Array<PersonType>;
      OfferId: string | number;
    }
  ): Promise<boolean>;
  // ---------RAILWAY ---------------
  [ActionTypes.BOOKING_RAILWAY](
    { commit }: AugmentedActionContext,
    payload: {
      PersonList: Array<PersonType>;
      OfferId: string | number;
    }
  ): Promise<boolean>;
  [ActionTypes.TICKETTING_RAILWAY](
    { commit }: AugmentedActionContext,
    payload: {
      OfferId: string | number;
    }
  ): Promise<boolean>;
  [ActionTypes.CANCEL_RAILWAY](
    { commit }: AugmentedActionContext,
    payload: {
      OfferId: string | number;
    }
  ): Promise<boolean>;

  [ActionTypes.REJECT_RAILWAY](
    { commit }: AugmentedActionContext,
    payload: {
      OfferId: string | number;
    }
  ): Promise<boolean>;
  //--------------------
  [ActionTypes.LOAD_TIKET](
    { commit }: AugmentedActionContext,
    payload: {
      TicketId: string | number;
    }
  ): Promise<boolean | { blobPart: BlobPart; type: string }>;

  [ActionTypes.SEND_TRIP](
    { commit }: AugmentedActionContext,
    payload: {
      email: string;
      OrderId: number;
    }
  ): Promise<boolean>;
  //--------------------
  [ActionTypes.LOAD_ACCEPT_TRIP](
    { commit }: AugmentedActionContext,
    payload: {
      code: string;
    }
  ): Promise<boolean>;

  [ActionTypes.ACCEPT_TRIP](
    { commit }: AugmentedActionContext,
    payload: {
      CheckOffer: CheckOffer;
    }
  ): Promise<boolean>;
}

// getters types
export type Getters = {
  getTrips(state: State): Array<BusinessTripType>;
  getOrderDataFromCach(state: State): (orderId: number) => OrderDTOType | null;
  getFilterDataFromActiveOrder(
    state: State,
    rootGetters: any
  ): FilterActiveOrder;
  getSelectOffer(
    state: State,
    getters: any
  ): (type: string | null) => Array<OrderDTOType> | null;
  getFilterDataFromAccept(state: State, rootGetters: any): FilterActiveOrder;
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  "commit" | "getters" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
