<template>
  <section class="section" :class="dopClass">
    <img
      v-if="logo"
      src="../../../../src/assets/images/section-logo.svg"
      alt="logo"
      class="section__logo"
    />
    <h1 :class="[!logo ? 'section__title--bigPadding' : '', 'section__title']">
      {{ title }}
    </h1>
    <span class="section__descr">{{ descr }}</span>
    <slot name="content"></slot>
  </section>
</template>
<script>
export default {
  name: "appSection",
  props: {
    title: {
      type: String,
      default: "NO_TITLE"
    },
    descr: {
      type: String,
      default: "NO_DESCR"
    },
    logo: {
      type: Boolean,
      default: true
    },
    dopClass: {
      type: String
    }
  }
};
</script>
