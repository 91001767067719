import { GetterTree } from "vuex";

import { State as RootState } from "../index";
import { State, Getters } from "./contract";
import store from "../index";
import {
  ActiveFilterElement,
  FilterActiveOrder,
  OrderDTOType
} from "@/interfaceAndType/typesTrips";
import { ORDER_TYPES } from "@/constant";

const convertDataFromFilter = (
  dataIn: OrderDTOType,
  nowEl: ActiveFilterElement
) => {
  const commonData = nowEl.data;
  commonData?.push(dataIn);
  const countNow = 1;
  return {
    count: nowEl.count + countNow,
    data: commonData
  } as ActiveFilterElement;
};

// getters
export const getters: GetterTree<State, RootState> & Getters = {
  getTrips: state => {
    return state.trips;
  },
  getOrderDataFromCach: state => orderId => {
    const orderCach = state.orderTicketsCach[orderId];
    if (orderCach) {
      return orderCach;
    }
    return null;
  },
  /**
   * получние данных разбитых по типу
   * @param state
   * @param rootGetters
   */
  getFilterDataFromActiveOrder: (state, rootGetters) => {
    const res: FilterActiveOrder = {
      avia: {
        count: 0,
        data: []
      },
      hotel: {
        count: 0,
        data: []
      },
      rail: {
        count: 0,
        data: []
      },
      transfer: {
        count: 0,
        data: []
      },
      freeService: {
        count: 0,
        data: []
      }
    };
    try {
      const aviaOrderTypeId = rootGetters.getOrderTypeId(ORDER_TYPES.AVIA);
      const hotelOrderTypeId = rootGetters.getOrderTypeId(ORDER_TYPES.HOTEL);
      const railOrderTypeId = rootGetters.getOrderTypeId(ORDER_TYPES.RAILWAY);
      const transferOrderTypeId = rootGetters.getOrderTypeId(
        ORDER_TYPES.TRANSFER
      );
      const freeServiceOrderTypeId = rootGetters.getOrderTypeId(
        ORDER_TYPES.FREE
      );

      if (state.activeOrderIsLoad) {
        state.activeOrder.forEach(item => {
          switch (item.orderTypeId) {
            case aviaOrderTypeId: {
              res.avia = convertDataFromFilter(item, res.avia);
              break;
            }
            case hotelOrderTypeId: {
              res.hotel = convertDataFromFilter(item, res.hotel);
              break;
            }
            case railOrderTypeId: {
              res.rail = convertDataFromFilter(item, res.rail);
              break;
            }
            case transferOrderTypeId: {
              res.transfer = convertDataFromFilter(item, res.transfer);
              break;
            }
            case freeServiceOrderTypeId: {
              res.freeService = convertDataFromFilter(item, res.freeService);
              break;
            }
            default: {
              console.warn("Внимание неизвестный тип !!!! ");
            }
          }
        });
      }
    } catch (e) {
      console.error("произошла неизвестная ошибка в распределителе данных");
    }
    return res;
  },
  /**
   * Показывает выбранный оффер
   * @param state
   * @param getters
   */
  getSelectOffer: (state, getters) => type => {
    let showData = "avia";
    if (type) {
      switch (type) {
        case ORDER_TYPES.AVIA: {
          showData = "avia";
          break;
        }
        case ORDER_TYPES.RAILWAY: {
          showData = "rail";
          break;
        }
        case ORDER_TYPES.HOTEL: {
          showData = "hotel";
          break;
        }
        case ORDER_TYPES.FREE: {
          showData = "freeService";
          break;
        }
        case ORDER_TYPES.TRANSFER: {
          showData = "transfer";
          break;
        }
      }
    }
    return getters.getFilterDataFromActiveOrder[showData].data;
  },

  /**
   * получние данных разбитых по типу для принятие пользовтаелем
   * @param state
   * @param rootGetters
   */
  getFilterDataFromAccept: (state, rootGetters) => {
    const res: FilterActiveOrder = {
      avia: {
        count: 0,
        data: []
      },
      hotel: {
        count: 0,
        data: []
      },
      rail: {
        count: 0,
        data: []
      },
      transfer: {
        count: 0,
        data: []
      },
      freeService: {
        count: 0,
        data: []
      }
    };
    try {
      const aviaOrderTypeId = rootGetters.getOrderTypeId(ORDER_TYPES.AVIA);
      const hotelOrderTypeId = rootGetters.getOrderTypeId(ORDER_TYPES.HOTEL);
      const railOrderTypeId = rootGetters.getOrderTypeId(ORDER_TYPES.RAILWAY);
      const transferOrderTypeId = rootGetters.getOrderTypeId(
        ORDER_TYPES.TRANSFER
      );
      const freeServiceOrderTypeId = rootGetters.getOrderTypeId(
        ORDER_TYPES.FREE
      );

      if (state.acceptTrip) {
        state.acceptTrip.orderList.forEach(item => {
          switch (item.orderTypeId) {
            case aviaOrderTypeId: {
              res.avia = convertDataFromFilter(item, res.avia);
              break;
            }
            case hotelOrderTypeId: {
              res.hotel = convertDataFromFilter(item, res.hotel);
              break;
            }
            case railOrderTypeId: {
              res.rail = convertDataFromFilter(item, res.rail);
              break;
            }
            case transferOrderTypeId: {
              res.transfer = convertDataFromFilter(item, res.transfer);
              break;
            }
            case freeServiceOrderTypeId: {
              res.freeService = convertDataFromFilter(item, res.freeService);
              break;
            }
            default: {
              console.warn("Внимание неизвестный тип !!!! ");
            }
          }
        });
      }
    } catch (e) {
      console.error("произошла неизвестная ошибка в распределителе данных");
    }
    return res;
  }
};
