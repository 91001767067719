import { GetterTree } from "vuex";

import { State as RootState } from "../index";
import { State, Getters } from "./contract";

// getters
export const getters: GetterTree<State, RootState> & Getters = {
  getHotels: state => {
    return state.hotels;
  },
  isSelectedRoom: state => (uniqueRoomKey, accessKey) => {
    const find =
      accessKey &&
      state.selectedRooms &&
      state.selectedRooms[accessKey] &&
      state.selectedRooms[accessKey].rooms
        ? state!.selectedRooms![accessKey]!.rooms!.find(
            item => item.uniqueAccessCode == uniqueRoomKey
          )
        : false;
    return find ? true : false;
  },
  getSelectedRooms: state => {
    if (state.selectedRooms) {
      return Object.values(state.selectedRooms);
    }
    return [];
  }
};
