
import SubHeader from "../components/shared/SubHeader.vue";
import NowTripsList from "../components/trips/NowTripsList.vue";
import TableTrip from "../components/trips/TableTrip.vue";
import CommonFooter from "../components/shared/CommonFooter.vue";
import { useStore } from "../store/modules";
import { computed, defineComponent, onMounted, ref } from "vue";
import { getters } from "../store/modules/trips/getters";
import { ActionTypes, State } from "../store/modules/trips/contract";
import { BusinessTripType } from "../interfaceAndType/typesTrips";
import { DEFAULT_PERPAGE, ORDER_TYPES } from "../constant";
import moment from "moment";
import { MutationTypes } from "../store/modules/trips/MutationsType";
import TripOrderModal from "../components/modals/TripOrderModal.vue";
export default defineComponent({
  name: "AllTrips",
  components: {
    SubHeader,
    NowTripsList,
    TableTrip,
    CommonFooter,
    TripOrderModal,
  },
  setup(props) {
    const store = useStore();
    const perPage = DEFAULT_PERPAGE;
    const orderDir = ref<boolean>(false);
    const orderBy = ref("");

    const tripList = computed<Array<BusinessTripType>>(() => {
      const trips = store.getters.getTrips;
      trips.forEach(item => (item.dateCreated = moment().format("L")));
      return trips;
    });
    const tripCount = computed<number | null>(() => {
      return (
        (store.state.trips.pageCount ? store.state.trips.pageCount : 0) *
        perPage
      );
    });
    const onPage = async (event: any) => {
      await store.dispatch(ActionTypes.GET_TRIPS, {
        page: event.page + 1,
        orderBy: orderBy.value,
        orderDir: orderDir.value
      });
    };

    const sort = async (field: string) => {
      orderDir.value = !orderDir.value;
      orderBy.value = field;
      await store.dispatch(ActionTypes.GET_TRIPS, {
        page: 1,
        orderBy: orderBy.value,
        orderDir: orderDir.value
      });
    };

    const showOrderModal = ref<boolean>(false);

    const openOrderModal = () => {
      showOrderModal.value = true;
    };

    const closeOrderModal = (val = false) => {
      showOrderModal.value = val;
    };

    const orderTypeId = computed(() => {
      return store.getters.getOrderTypeId(ORDER_TYPES.ALL);
    });

    onMounted(async () => {
      await store.dispatch(ActionTypes.GET_TRIPS, {});
      store.commit(MutationTypes.CLEAR_CACHE);
    });
    return {
      tripList,
      onPage,
      perPage,
      sort,
      tripCount,
      orderBy,
      orderDir,
      showOrderModal,
      openOrderModal,
      closeOrderModal,
      orderTypeId
    };
  }
});
