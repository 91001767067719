import {
  ActionContext,
  GetterTree,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions
} from "vuex";

import { State as RootState } from "../index";
import { GlobalMutations as Mutations } from "../allMutationsType";
import { DropDownEntity } from "../../../interfaceAndType/typesShared";
import {
  BaseHotelAccesor,
  ExtendedHotelInfo,
  HotelDetailResponce,
  HotelSearchRequest
} from "@/interfaceAndType/typesHotel";
import {
  HotelTreeWithItem,
  HotelTreeItem
} from "../../../interfaceAndType/typesHotel";

// Declare state
export type State = {
  searchReq: HotelSearchRequest | null;
  hotels: Array<HotelTreeItem> | null;
  hotelsCache: Array<HotelTreeItem> | null;
  pricingCache: { [key: string]: ExtendedHotelInfo } | {};
  selectedRooms: { [key: string]: ExtendedHotelInfo };
};

// Action enums
export enum ActionTypes {
  SEARCH_CITY = "SEARCH_CITY",
  SEARCH_HOTELS = "SEARCH_HOTELS",
  GET_HOTEL_PRICE = "GET_HOTEL_PRICE"
}

// Actions contracts
export interface Actions {
  [ActionTypes.SEARCH_CITY](
    { commit }: AugmentedActionContext,
    payload: { city: string }
  ): Promise<Array<DropDownEntity>>;
  [ActionTypes.SEARCH_HOTELS](
    { commit }: AugmentedActionContext,
    payload: { searchData: HotelSearchRequest }
  ): Promise<boolean>;
  [ActionTypes.GET_HOTEL_PRICE](
    { commit }: AugmentedActionContext,
    payload: { accessors: BaseHotelAccesor[] | null; cityId: number | null }
  ): Promise<HotelDetailResponce | null>;
}

// getters types
export type Getters = {
  getHotels(state: State): Array<HotelTreeItem> | null;
  isSelectedRoom(
    state: State
  ): (uniqueRoomKey: string, accessKey: string) => boolean;
  getSelectedRooms(state: State): Array<ExtendedHotelInfo> | undefined;
};

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

//setup store type
export type Store<S = State> = Omit<
  VuexStore<S>,
  "commit" | "getters" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};
