
import { computed, defineComponent, reactive, ref } from "vue";
import { DropDownEntity } from "../../interfaceAndType/typesShared";
import {
  ServicesListElement,
  transferOffer
} from "../../interfaceAndType/typesServices";
import { useStore } from "../../store/modules";
import {
  SERVICES_TYPES,
  TRANSFER_CODE,
  FREE_CODE,
  TYPE_DROPLIST,
  ORDER_TYPES
} from "../../constant";
import FreeForm from "./FreeForm.vue";
import TransferForm from "./TransferForm.vue";
import CommonSelector from "../shared/Selectors/CommonSelector.vue";
import OrderCreateModal from "../modals/OrderCreateModal.vue";
export default defineComponent({
  name: "ServiceForm",
  components: {
    TransferForm,
    FreeForm,
    CommonSelector,
    OrderCreateModal
  },
  setup(props) {
    const store = useStore();

    const dataSend = ref(null);

    const showOrderModal = ref<boolean>(false);

    const closeOrderModal = (val = false, dataSendIn = null) => {
      showOrderModal.value = val;
      if (val) {
        dataSend.value = dataSendIn ? dataSendIn : null;
      }
    };

    const typeService = ref<ServicesListElement | null>(null);
    const services: Array<ServicesListElement> = SERVICES_TYPES;
    const selectedClient = ref<DropDownEntity | null>(null);
    const clientId = computed(() => {
      return selectedClient.value ? selectedClient.value.entityId : -1;
    });
    const setClient = val => {
      selectedClient.value = val ? val : null;
    };
    const selectContract = ref<DropDownEntity | null>(null);
    const setContract = val => {
      selectContract.value = val ? val : null;
    };
    const contractId = computed(() => {
      return selectContract.value ? selectContract.value.entityId : -1;
    });

    const showTransferForm = computed(() => {
      if (typeService.value && typeService.value.code == TRANSFER_CODE) {
        return true;
      }
      return false;
    });
    const showFreeForm = computed(() => {
      if (typeService.value && typeService.value.code == FREE_CODE) {
        return true;
      }
      return false;
    });
    const passengersCount = computed(() => {
      const res = {
        adults: 1,
        children: 0,
        infants: 0
      };
      if (showTransferForm.value && dataSend && dataSend.value) {
        const conversData = (dataSend.value as any) as transferOffer;
        res.adults =
          conversData && conversData.NumberOfPersons
            ? conversData.NumberOfPersons
            : 1;
      }
      return res;
    });
    const orderTypeId = computed(() => {
      if (showTransferForm.value) {
        return store.getters.getOrderTypeId(ORDER_TYPES.TRANSFER);
      }
      if (showFreeForm.value) {
        return store.getters.getOrderTypeId(ORDER_TYPES.FREE);
      }
      return "-1";
    });
    const clientType = TYPE_DROPLIST.CLIENT;
    const contractType = TYPE_DROPLIST.CONTRACT;
    return {
      selectedClient,
      services,
      setClient,
      setContract,
      contractId,
      typeService,
      clientId,
      showTransferForm,
      showFreeForm,
      clientType,
      contractType,
      orderTypeId,
      showOrderModal,
      closeOrderModal,
      dataSend,
      passengersCount
    };
  }
});
