<template>
  <ul class="controls-menu">
    <li>
      <lang-list />
    </li>
    <li class="profile-link" v-if="userProfile">
      <router-link to="/profile">{{ userProfile.email }}</router-link>
    </li>
     <li class="profile-link" v-else>
      <router-link to="/login">Авторизация</router-link>
    </li>
  </ul>
</template>
<script>
import { AUTH_LOGOUT } from "../../../store/types";
export default {
  name: "controlsMenu",
  computed: {
    userProfile() {
      return this.$store.state.auth.userProfile;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch(AUTH_LOGOUT, { self: this });
      this.$router.push({ path: "/auth/login" });
    }
  }
};
</script>
