import { State } from "./contract";
import {
  NTrainListReference,
  RailwaySearchRequest
} from "../../../interfaceAndType/typesRail";

// mutations enums
export enum MutationTypes {
  SET_TRAIN_DATA = "SET_TRAIN_DATA",
  SAVE_RAIL_SEARCHREQUEST = "SAVE_RAIL_SEARCHREQUEST",
  SET_TRAIN_PRICE = "SET_TRAIN_PRICE",
  SELECT_TRAIN_WITH_SEATS = "SELECT_TRAIN_WITH_SEATS",
  UNSELECT_TRAIN = "UNSELECT_TRAIN",
  CLEAR_CACHE = "CLEAR_CACHE",
  INCREASE_COUNT = "INCREASE_COUNT",
  DECREASE_COUNT = "DECREASE_COUNT",
}
// Mutation contracts
export type Mutations<S = State> = {
  [MutationTypes.SET_TRAIN_DATA](
    state: S,
    payload: { data: NTrainListReference[] }
  ): void;
  [MutationTypes.SAVE_RAIL_SEARCHREQUEST](
    state: S,
    payload: { data: RailwaySearchRequest }
  ): void;
  [MutationTypes.SET_TRAIN_PRICE](
    state: S,
    payload: { data: NTrainListReference; key: string }
  ): void;
  [MutationTypes.SELECT_TRAIN_WITH_SEATS](
    state: S,
    payload: { data: NTrainListReference, key: string }
  ): void;
  [MutationTypes.UNSELECT_TRAIN](state: S, payload: { key: string }): void;
  [MutationTypes.CLEAR_CACHE](state: S, payload: {}): void;
  [MutationTypes.INCREASE_COUNT](state: S, payload: {}): void;
  [MutationTypes.DECREASE_COUNT](state: S, payload: {}): void;
};
