<template>
  <div class="search-footer">
    <partners-list />
    <socials-list />
  </div>
</template>
<script>
import PartnersList from "../../shared/PartnersList";
import SocialsList from "../../shared/Socials";
export default {
  name: "SearchFooter",
  components: {
    PartnersList,
    SocialsList
  }
};
</script>
