
import HotelsCardComplex from "../../components/tikets/hotel/HotelsCardComplex.vue";

// import ServicesTicket from "../../components/tikets/services/ServicesTicket.vue";
import AviaTiket from "../../components/tikets/avia/AviaTikets.vue";
import BokingForm from "../../components/booking/BookingForm.vue";

import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { ORDER_TYPES, STATUS_ORDER } from "../../constant";
import useFilterMultiTiket from "../../hocks/useFilterMultiTiket";
import { OfferListType, OrderDTOType } from "../../interfaceAndType/typesTrips";
import { useStore } from "../../store/modules";
// import { MutationTypes } from "../../store/modules/trips/MutationsType";
import { MultiItinerary } from "../../interfaceAndType/typesSearh";
import RailTiketSmall from "@/components/tikets/rail/RailTiketSmall.vue";
import { NTrainListReference } from "@/interfaceAndType/typesRail";
import { ExtendedHotelInfo } from "@/interfaceAndType/typesHotel";
import TransferCard from "../../components/tikets/services/TransferCard.vue";
import FreeCard from "../../components/tikets/services/FreeCard.vue";
export default defineComponent({
  name: "OpenOffer",
  components: {
    AviaTiket,
    BokingForm,
    RailTiketSmall,
    HotelsCardComplex,
    TransferCard,
    FreeCard
  },
  props: {
    listOrders: {},
    initData: {
      type: Function
    }
  },
  setup() {
    const route = useRoute();
    const type = computed(() => route.params.type);
    const store = useStore();
    const selectTikets = ref<any>(null);
    const selectOfferData = ref<number | string | null>(null);
    const offers = computed<Array<OrderDTOType> | null>(() => {
      return store.getters.getSelectOffer(type.value as string);
    });
    const activeOffer = computed(() => {
      const activeOfferKey = store.state.trips.activeOfferKey;
      if (offers.value && activeOfferKey) {
        const find = offers.value.find(item => item.orderId == activeOfferKey);
        return find ? find : null;
      }
      return null;
    });

    // // временная функиция для конвертации
    // const convert = (data: object) => {
    //   const temp = Object.entries(data);
    //   const temp2: [string, any][] = temp.map(item => {
    //     const key = item[0]
    //       .split(" ")
    //       .map(w => w.charAt(0).toLowerCase() + w.slice(1))
    //       .join(" ");
    //     let value = item[1];
    //     if (value && typeof value == "object" && !Array.isArray(value)) {
    //       value = convert(value);
    //     }
    //     if (value && Array.isArray(value)) {
    //       value = value.map(item => {
    //         return convert(item);
    //       });
    //     }
    //     return [key, value];
    //   });
    //   const convet = {};
    //   temp2.forEach(item => {
    //     convet[item[0]] = item[1];
    //   });
    //   return convet;
    // };

    const tikets = computed<
      Array<{ offerData: OfferListType; tiket: any; offerId: number }>
    >(() => {
      if (activeOffer.value && activeOffer.value.offerDTOList) {
        const res: any[] = [];
        activeOffer.value.offerDTOList.forEach(item => {
          if (item.data) {
            const converData = JSON.parse(item.data);
            // converData = convert(converData);
            res.push({
              offerData: item,
              tiket: converData,
              offerId: item.offerId
            });
          }
        });
        return res;
      }
      return [];
    });

    const {
      isAvia,
      isHotel,
      isService,
      isRail,
      isFree
    } = useFilterMultiTiket();

    const selectOffer = (dataIn, offerData) => {
      selectTikets.value = dataIn;
      selectOfferData.value = offerData.offerId;
    };
    const unselectOffer = data => {
      selectTikets.value = data;
      selectOfferData.value = data;
    };
    const isSelect = (data: any) => {
      let res = true;
      if (selectTikets.value) {
        if (type.value == ORDER_TYPES.AVIA) {
          const variant: MultiItinerary = data;
          if (selectTikets.value.key && variant.key == selectTikets.value.key) {
            res = true;
          } else {
            res = false;
          }
        }
        if (type.value == ORDER_TYPES.HOTEL) {
          const variant: ExtendedHotelInfo = data;
          if (JSON.stringify(variant) == JSON.stringify(selectTikets.value)) {
            res = true;
          } else {
            res = false;
          }
        }
        if (type.value == ORDER_TYPES.RAILWAY) {
          const variant: NTrainListReference = data;
          if (variant.numberTrain == selectTikets.value.numberTrain) {
            res = true;
          } else {
            res = false;
          }
        }
        if (type.value == ORDER_TYPES.TRANSFER) {
          const variant = data;
          if (JSON.stringify(variant) == JSON.stringify(selectTikets.value)) {
            res = true;
          } else {
            res = false;
          }
        }

        if (type.value == ORDER_TYPES.FREE) {
          const variant = data;
          if (JSON.stringify(variant) == JSON.stringify(selectTikets.value)) {
            res = true;
          } else {
            res = false;
          }
        }
      }
      return res;
    };

    const initCheck = () => {
      let tiket = null;
      let offer: OfferListType | null = null;
      if (tikets.value) {
        tikets.value.forEach(item => {
          if (
            STATUS_ORDER.Booking == item.offerData.statusCode ||
            STATUS_ORDER.Canceled == item.offerData.statusCode ||
            STATUS_ORDER.TicketReady == item.offerData.statusCode ||
            STATUS_ORDER.Applied == item.offerData.statusCode
          ) {
            offer = item.offerData ? item.offerData : null;
            tiket = item.tiket;
          }
        });

        if (tikets.value.length == 1) {
          const item = tikets.value[0];
          offer = item.offerData ? item.offerData : null;
          if (isService(item.tiket) || isFree(item.tiket)) {
            tiket = item.tiket;
          }
        }
      }
      if (tiket && offer) {
        selectOffer(tiket, offer);
      }
    };
    initCheck();

    const isPersonSelect = (offerData: OfferListType) => {
      if (offerData.statusCode == STATUS_ORDER.Applied) {
        return true;
      }
      return false;
    };

    return {
      type,
      offers,
      activeOffer,
      tikets,
      isAvia,
      isHotel,
      isService,
      selectOffer,
      selectTikets,
      isSelect,
      isRail,
      isFree,
      selectOfferData,
      unselectOffer,
      isPersonSelect
    };
  }
});
