import { ActionTree } from "vuex";
import { State as RootState } from "../index";
import { State, Actions } from "./contract";
import { ApiService } from "../../../utils/ApiService";
import { MutationTypes as AviaMutationTypes } from "./MutationsType";
import { MutationTypes as SystemMutationTypes } from "../system/MutationsType";
import {
  AviaPricingType,
  AviaSearchReqType,
  Leg
} from "../../../interfaceAndType/typesSearh";
import { EVENT_LOADER } from "../system/contract";
import { hasDataInResultSearchAvia } from "../../../helpers/avia/HasDataInresultSearch";

// Define actions
export const actions: ActionTree<State, RootState> & Actions = {};
