import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "avia-tarif-modal-plan" }
const _hoisted_2 = { class: "avia-tarif-modal-plan__title" }
const _hoisted_3 = { class: "avia-tarif-modal-plan-services" }
const _hoisted_4 = { class: "avia-tarif-modal-plan__summ" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createVNode("ul", _hoisted_3, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.description, (option, index) => {
        return (_openBlock(), _createBlock("li", {
          key: index,
          class: ["avia-tarif-modal-plan-services__item", {
          'avia-tarif-modal-plan-services__item--included':
            option.isIncluded == 1 ? true : false
        }]
        }, [
          _createTextVNode(_toDisplayString(option.name) + " ", 1 /* TEXT */),
          _createVNode("span", null, _toDisplayString(option.description), 1)
        ], 2))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createVNode("p", _hoisted_4, "Итого: " + _toDisplayString(_ctx.equivTotal) + " ₽", 1)
  ]))
}