
import { computed, defineComponent, PropType, toRefs, ref } from "vue";
import {
  MultiItinerary,
  Flight,
  Itinerary
} from "../../../interfaceAndType/typesSearh";
import router from "../../../router";
import { useStore } from "../../../store/modules";
const NO_DATA = "н/д";
export default defineComponent({
  props: {
    item: {
      type: Object as PropType<MultiItinerary>,
      required: true
    }
  },
  setup(props) {
    const { item } = toRefs(props);
    const store = useStore();
    const getNameAirport = store.getters.getNameAirport;

    const dataHeader = computed(() => {
      const airplaneCodes: Array<string> = [];
      let routes: Array<string> = [];
      const transfers: Array<number> = [];
      let routeList = "";
      const tempItem = item.value;
      if (tempItem && tempItem.itineraries) {
        tempItem.itineraries.forEach(itinerari => {
          if (itinerari.legs) {
            let countFlight = 0;
            itinerari.legs.forEach(leg => {
              if (leg.flights) {
                countFlight += leg.flights.length;
                leg.flights.forEach(flight => {
                  const airplane = flight.airplane;
                  airplaneCodes.push(
                    airplane && airplane.name ? airplane.name : NO_DATA
                  );
                  routes.push(
                    flight.origin ? getNameAirport(flight.origin) : NO_DATA
                  );
                  routes.push(
                    flight.destination
                      ? getNameAirport(flight.destination)
                      : NO_DATA
                  );
                });
              }
            });
            transfers.push(countFlight - 1);
          }
        });
      }
      routes = routes.filter((route, index) => {
        return routes.indexOf(route) === index;
      });
      routeList = routes.join("-");

      return {
        routeList,
        airplaneCodes,
        transfers
      };
    });

    return {
      dataHeader
    };
  }
});
