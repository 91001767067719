import { MutationTree } from "vuex";
import { Mutations, MutationTypes } from "./MutationsType";
import { State } from "./contract";
// Define mutations
export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_SEARH_DATA](state: State, { data, result }) {
    localStorage.setItem("tempdata", JSON.stringify(data));
    state.searhcData = data;
    state.hasSearch = true;
    state.resultSearch = result;
  },
  [MutationTypes.SET_LOAD_PRICING](state: State, { data, result, keytiket }) {
    console.log(data, keytiket);
    state.pricingCach[keytiket] = data.alternativesContainer;
  },
  [MutationTypes.SAVE_SEARH_DATA](state: State, { data }) {
    localStorage.setItem("tempdatasearh", JSON.stringify(data));
    state.serachReq = data;
  },
  [MutationTypes.CHANGE_VARIANT](state: State, { newVariant }) {
    if (
      state.searhcData &&
      state.searhcData.variants &&
      state.searhcData.variants.length > 0
    ) {
      const findIndex = state.searhcData.variants.findIndex(
        item => item.key === newVariant.key
      );
      if (
        findIndex !== -1 &&
        state.searhcData &&
        state.searhcData.variants &&
        state.searhcData.variants[findIndex]
      ) {
        state.searhcData.variants[findIndex] = newVariant;
      } else {
        console.error("Не могу присвоить новый тариф нет ключа !!");
      }
    }
  },
  /**
   * выбор билета для создания заказа принимает в себя ticketKey
   *
   *
   */
  [MutationTypes.SELECT_AVIA_TICKET](state: State, { ticketKey }) {
    state.selectedAviaTicket.push(ticketKey);
  },
  /**
   * удаление билета из списка выбранных в создании заказа (принимает ticketKey)
   */
  [MutationTypes.DELETE_SELECTED_AVIA_TICKET](state: State, { ticketKey }) {
    const keyIndex = state.selectedAviaTicket.findIndex(
      item => item === ticketKey
    );
    if (keyIndex != -1) {
      state.selectedAviaTicket.splice(keyIndex, 1);
    } else {
      console.log("индекс элемента не найден");
    }
  },
  /**
   * очистка данных
   */
  [MutationTypes.CLEAR_STORE](state) {
    state.searhcData = null;
    state.pricingCach = {};
    state.serachReq = null;
    state.resultSearch = false;
    state.hasSearch = false;
  }
};
