
import { defineComponent, ref, toRefs, computed, reactive } from "vue";

export default defineComponent({
  name: "aviaSearchParams",
  props: {
    isOnlyDirect: {
      type: Boolean
    },
    setDirectFlight: {
      type: Function,
      required: true
    },
    increaseCount: {
      type: Function,
      required: true
    },
    decreaseCount: {
      type: Function,
      required: true
    },
    adultsCount: {
      type: Number
    },
    childrenCount: {
      type: Number
    },
    infantsCount: {
      type: Number
    },
    fare: {
      type: Number,
      required: true
    },
    changeFare: {
      type: Function,
      required: true
    },
    faresList: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const {
      isOnlyDirect,
      setDirectFlight,
      fare,
      changeFare,
      faresList
    } = toRefs(props);

    const selectedFare = computed({
      get() {
        return fare.value;
      },
      set(val) {
        changeFare.value(val);
      }
    });

    const onlyDirectComp = computed({
      get() {
        return isOnlyDirect.value;
      },
      set(val) {
        setDirectFlight.value(val);
      }
    });

    return {
      selectedFare,
      onlyDirectComp
    };
  }
});
