
import { computed, defineComponent, PropType, ref, toRefs } from "vue";
import AviaHead from "./AviaHead.vue";
import AviaSlider from "./AviaSlider.vue";
import AviaRoute from "./AviaRoute.vue";
import AviaBottom from "./AviaBottom.vue";
import { MultiItinerary, Leg } from "../../../interfaceAndType/typesSearh";
import AviaFareInfo from "./AviaFareInfo.vue";

export default defineComponent({
  components: { AviaHead, AviaSlider, AviaRoute, AviaBottom, AviaFareInfo },
  props: {
    item: {
      type: Object as PropType<MultiItinerary>,
      required: true
    },
    showTarrif: {
      type: Function
    },
    btnTarrif: {
      type: Boolean,
      default: true
    },
    deleteTicket: {
      type: Function,
      default: () => 0
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false,
      required: true
    },
    tripId: {
      type: Number
    },
    isModeOffer: {
      type: Boolean,
      default: false
    },

    isCansel: {
      type: Boolean,
      default: false
    },
    hasCansel: {
      type: Boolean,
      default: false
    },
    hasControl: {
      type: Boolean,
      default: true
    }
  },
  emits: ["select-offer", "unselect-offer"],
  setup(props) {
    const showInfo = ref<boolean>(false);
    const showFareInfo = ref<boolean>(false);
    const toogleSlider = () => {
      showInfo.value = !showInfo.value;
    };
    const toogleFareInfo = () => {
      showFareInfo.value = !showFareInfo.value;
    };
    const { item } = toRefs(props);
    const allLegs = computed(() => {
      if (item.value && item.value.itineraries) {
        const itineraries = item.value.itineraries;
        let tempLgs: Array<Leg> = [];

        itineraries.forEach(it => {
          tempLgs = [...tempLgs, ...(it.legs ? it.legs : [])];
        });

        return tempLgs;
      }
      return [];
    });

    return {
      showInfo,
      showFareInfo,
      toogleSlider,
      toogleFareInfo,
      allLegs
    };
  }
});
