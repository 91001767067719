
import { computed, defineComponent, PropType, toRefs } from "vue";
import {
  Fare,
  FareFamily,
  Flight,
  MultiItinerary
} from "../../../interfaceAndType/typesSearh";
import moment from "moment";
import { useStore } from "../../../store/modules/index";
type ExtFl = Flight & {
  fare: Fare;
  fareFamily: FareFamily;
  familyCode: string | null;
};

export default defineComponent({
  props: {
    showInfo: { type: Boolean, default: false },
    item: {
      type: Object as PropType<MultiItinerary | null>,
      required: true
    }
  },
  setup(props) {
    const { item } = toRefs(props);
    const store = useStore();
    const getNameAirport = store.getters.getNameAirport;
    const ND = "н/д";
    const convertMin = min => {
      const nowMin = moment.duration(min, "minutes");
      const d = nowMin.days();
      const h = nowMin.hours();
      const m = nowMin.minutes();
      return `${d}д:${h}ч:${m}м`;
    };

    const converData = (data: Array<ExtFl>) => {
      if (data) {
        const res = data.map(fl => {
          return {
            key: fl.key,
            from: fl.origin ? fl.origin : ND,
            to: fl.destination ? fl.destination : ND,

            fromName: fl.origin ? getNameAirport(fl.origin) : ND,
            toName: fl.destination ? getNameAirport(fl.destination) : ND,

            time: fl.durationMinutes ? convertMin(fl.durationMinutes) : ND,
            airplane: fl.airplane ? fl.airplane.name : ND,
            airplaneCode: fl.airplaneCode ? fl.airplaneCode : ND,
            marketingAirline: fl.marketingAirline
              ? fl.marketingAirline.name
              : ND,
            codeA: fl.marketingAirline ? fl.marketingAirline.code : ND,
            fareName: fl.fare ? fl.fare.fareName : ND,
            airline: fl.airline ? fl.airline : ND,
            number: fl.number
          };
        });
        return res;
      }
      return [];
    };

    const allFly = computed(() => {
      const nowItem = item.value;
      if (nowItem) {
        const res: Array<ExtFl> = [];
        if (nowItem.itineraries) {
          nowItem.itineraries.forEach(it => {
            if (it && it.legs) {
              const fareInfo = {
                fare: it.fare,
                fareFamily: it.fareFamily,
                familyCode: it.familyCode
              };
              it.legs.forEach(leg => {
                if (leg && leg.flights) {
                  leg.flights.forEach(fl => {
                    res.push({ ...fl, ...fareInfo });
                  });
                }
              });
            }
          });
          return converData(res);
        }
      }
      return [];
    });

    return { allFly };
  }
});
