
import { computed, defineComponent, reactive, watch } from "vue";
import { TYPE_DROPLIST } from "../../constant";
import getElement from "../../helpers/getElement";
import useCreatePassenger from "../../hocks/useCreatePassenger";
import { FreeOffer } from "../../interfaceAndType/typesServices";
import { DropDownEntity } from "../../interfaceAndType/typesShared";
import SelectorPerson from "../shared/personEdit/SelectorPerson.vue";
import CommonSelector from "../shared/Selectors/CommonSelector.vue";
import Datepicker from "vuejs3-datepicker";
import BaseInput from "../components/base/BaseInput.vue";
import clearReactive from "../../helpers/clearReactive";
import moment from "moment";
export default defineComponent({
  name: "FreeForm",
  components: {
    SelectorPerson,
    CommonSelector,
    Datepicker,
    BaseInput,
  },
  emits: ["open-order-modal"],
  props: {
    contractId: {
      type: Number,
      required: true,
    },
    OrderTypeId: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const freeoffer = reactive<FreeOffer>({
      isGroup: false, //- bool - Признак группы. По умолчанию false. Если указан true, то должен быть заполнен 1 пассажир.
      NumberOfPersons: 1, // - int - Количество человек в группе. Необязательный.
      PersonList: [], // - Список пассажиров. Обязательный.
      SupplierId: 0, // - int - Поставщик услуги. Обязательный. (api/RefEntity/DropDownList/supplier). Обязательное.
      SupplierContractId: 0, // - int - Контракт с поставщиком. Обязательный. (api/RefEntity/DropDownList/contract?subListId={supplierId}, где supplierId - id поставщика). Обязательное.
      Comment: "", // string - Комментарий. Необязательное.
      PatternText: "", // - string - Шаблонный текст. Необязательное.
      CancelConditionChange: "", // - string - Условия аннуляции / изменения, штраф. Обязательное.
      PaymentType: "", // - string - Тип оплаты. (api/RefEntity/DropDownList/paymenttype, передаём EntityName). Обязательное.
      Price: 0, //- double - Ориентировочная стоимость. Обязательное.
      CurrencyId: 0, // - Валюта(api/RefEntity/DropDownList/currency). Обязательное.

      Name: "", //  - Название услуги. Обязательное.
      DateFrom: new Date(), // - DateTime - Дата начала мероприятия. Обязательное.
      TimeFrom: "", //- string - Время начала мероприятия. Обязательное.
      DateTo: new Date(), //- DateTime - Дата окончания мероприятия. Обязательное.
      TimeTo: "", // - string - Время окончания мероприятия. Обязательное.
    });

    // persone
    const { deletePersone, addPerson, setPerson } = useCreatePassenger(
      freeoffer
    );

    const setData = (
      val: null | DropDownEntity,
      field: string,
      fieldname = "entityId"
    ) => {
      freeoffer[field] = getElement(val, fieldname);
    };
    const simpleSetData = (val, field: string) => {
      freeoffer[field] = val;
    };
    const supplierType = TYPE_DROPLIST.SUPPLIER;
    const supplierContactType = TYPE_DROPLIST.CONTRACT;
    const curencyType = TYPE_DROPLIST.CURRENCY;
    const paymentTypeType = TYPE_DROPLIST.PAYMENT_TYPE;

    const createTransfer = () => {
      const cnvtMainData = clearReactive(freeoffer);
      cnvtMainData.TimeFrom = moment(cnvtMainData.TimeFrom).format("HH:mm");
      cnvtMainData.TimeTo = moment(cnvtMainData.TimeTo).format("HH:mm");
      emit("open-order-modal", cnvtMainData);
    };

    const isValid = computed(() => {
      return (
        !!freeoffer.PersonList &&
        !!freeoffer.SupplierId &&
        !!freeoffer.SupplierContractId &&
        !!freeoffer.CancelConditionChange &&
        !!freeoffer.PaymentType &&
        !!freeoffer.Price &&
        !!freeoffer.CurrencyId &&
        !!freeoffer.Name &&
        !!freeoffer.DateFrom &&
        !!freeoffer.TimeFrom &&
        !!freeoffer.DateTo &&
        !!freeoffer.TimeTo
      );
    });

    watch(() => freeoffer, () => {
      if(!freeoffer.isGroup) {
        freeoffer.NumberOfPersons = freeoffer.PersonList.length;
      }
      if(freeoffer.isGroup) {
        freeoffer.PersonList.splice(1);
      }
    }, {deep: true });

    return {
      deletePersone,
      addPerson,
      setPerson,
      freeoffer,
      supplierType,
      supplierContactType,
      paymentTypeType,
      createTransfer,
      setData,
      curencyType,
      simpleSetData,
      isValid
    };
  },
});
