
import { computed, defineComponent, PropType, ref, toRefs } from "vue";
import AviaTiket from "../../tikets/avia/AviaTikets.vue";
import HotelsTicketSmall from "../../tikets/hotel/HotelTiketSmall.vue";

import useFilterMultiTiket from "../../../hocks/useFilterMultiTiket";
import RailTiketSmall from "../../tikets/rail/RailTiketSmall.vue";
import TransferCard from "../../tikets/services/TransferCard.vue";
import FreeCard from "../../tikets/services/FreeCard.vue";

export default defineComponent({
  components: {
    AviaTiket,
    HotelsTicketSmall,
    RailTiketSmall,
    TransferCard,
    FreeCard
  },
  props: {
    tickets: {
      type: Array as PropType<Array<Array<any>>>,
      required: true
    },
    tripId: {
      type: Number
    },
    hasNew: {
      type: Boolean,
      default: false
    },
    onlyNew: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { tickets, hasNew } = toRefs(props);
    const parsed = computed(() => {
      const result: Array<any> = [];
      try {
        tickets.value.forEach(item => {
          item.forEach(_item => {
            if (
              (_item.itineraries && _item.itineraries.length > 0) ||
              !("itineraries" in _item)
            ) {
              result.push(_item);
            }
          });
        });
      } catch (e) {
        console.log(tickets.value);
      }
      return result;
    });
    const selectedType = ref(hasNew.value ? "new" : null);
    const isShow = (type: string) => {
      if (type === selectedType.value || !selectedType.value) return true;
      return false;
    };
    const setSelectedType = val => {
      selectedType.value = val;
    };
    const {
      isAvia,
      isHotel,
      isService,
      isRail,
      isFree
    } = useFilterMultiTiket();
    return {
      parsed,
      isAvia,
      isHotel,
      isService,
      isRail,
      isShow,
      isFree,
      setSelectedType,
      selectedType
    };
  }
});
