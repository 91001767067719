import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "checkboxes-wrapper checkboxes-wrapper--airport" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Checkbox = _resolveComponent("Checkbox")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.allTrains, (train) => {
      return (_openBlock(), _createBlock("div", {
        class: "p-field-checkbox",
        key: train.numberTrain
      }, [
        _createVNode(_component_Checkbox, {
          id: train.brandTrain,
          name: "brandTrain",
          value: train.brandTrain,
          modelValue: _ctx.selectedTrainBrand,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.selectedTrainBrand = $event)),
          onChange: _cache[2] || (_cache[2] = $event => (_ctx.filterByTrainBrands(_ctx.selectedTrainBrand)))
        }, null, 8, ["id", "value", "modelValue"]),
        _createVNode("label", {
          for: train.brandTrain
        }, _toDisplayString(train.brandTrain.length > 10 ? train.brandTrain.slice(0, 9)+'...' : train.brandTrain), 9, ["for"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}