import { Module } from "vuex";
import { State as RootState } from "../index";
import { State } from "./contract";

import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";

const loadData = localStorage.getItem("tempdata");
const initDemoData = loadData ? JSON.parse(loadData) : null;

const loadData2 = localStorage.getItem("tempdatasearh");
const initDemoData2 = loadData2 ? JSON.parse(loadData2) : null;

// Create initial state
const state: State = {
  searhcData: initDemoData,
  hasSearch: initDemoData ? true : false,
  resultSearch: initDemoData ? true : false,
  pricingData: [],
  serachReq: initDemoData2,
  pricingCach: {},
  selectedAviaTicket: []
};

export const AviaModule: Module<State, RootState> = {
  state,
  mutations,
  actions,
  getters
};
