import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form-wrapper form-wrapper--rail" }
const _hoisted_2 = { class: "all-params__wrapper formServices" }
const _hoisted_3 = { class: "elementForm" }
const _hoisted_4 = { class: "elementForm" }
const _hoisted_5 = { class: "elementForm" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_common_selector = _resolveComponent("common-selector")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_free_form = _resolveComponent("free-form")
  const _component_transfer_form = _resolveComponent("transfer-form")
  const _component_order_create_modal = _resolveComponent("order-create-modal")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("form", {
      class: "search-form search-form--hotel",
      onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_common_selector, {
              type: _ctx.clientType,
              placeholder: "Клиент",
              onItemSet: _cache[1] || (_cache[1] = $event => (_ctx.setClient($event)))
            }, null, 8, ["type"])
          ]),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_common_selector, {
              type: _ctx.contractType,
              placeholder: "Контракт",
              onItemSet: _cache[2] || (_cache[2] = $event => (_ctx.setContract($event))),
              subListId: _ctx.clientId
            }, null, 8, ["type", "subListId"])
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.typeService,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (_ctx.typeService = $event)),
              options: _ctx.services,
              optionLabel: "name",
              placeholder: "Выберите услугу"
            }, null, 8, ["modelValue", "options"])
          ]),
          (_ctx.showFreeForm)
            ? (_openBlock(), _createBlock(_component_free_form, {
                key: 0,
                contractId: _ctx.contractId,
                onOpenOrderModal: _cache[4] || (_cache[4] = $event => (_ctx.closeOrderModal(true, $event))),
                OrderTypeId: _ctx.orderTypeId
              }, null, 8, ["contractId", "OrderTypeId"]))
            : _createCommentVNode("", true),
          (_ctx.showTransferForm)
            ? (_openBlock(), _createBlock(_component_transfer_form, {
                key: 1,
                contractId: _ctx.contractId,
                onOpenOrderModal: _cache[5] || (_cache[5] = $event => (_ctx.closeOrderModal(true, $event))),
                OrderTypeId: _ctx.orderTypeId
              }, null, 8, ["contractId", "OrderTypeId"]))
            : _createCommentVNode("", true),
          _createVNode(_component_order_create_modal, {
            showOrderModal: _ctx.showOrderModal,
            closeModal: _ctx.closeOrderModal,
            passengersCount: _ctx.passengersCount,
            orderTypeId: _ctx.orderTypeId,
            isService: "",
            isFree: _ctx.showFreeForm,
            dataSend: _ctx.dataSend
          }, null, 8, ["showOrderModal", "closeModal", "passengersCount", "orderTypeId", "isFree", "dataSend"])
        ])
      ])
    ], 32)
  ]))
}