import { ActionTree } from "vuex";
import { State as RootState } from "../index";
import { State, Actions, ActionTypes, AirPortType } from "./contract";
import { ApiService } from "../../../utils/ApiService";
import { MutationTypes as AviaMutationTypes } from "./MutationsType";
import { MutationTypes as SystemMutationTypes } from "../system/MutationsType";
import {
  AviaPricingType,
  AviaSearchReqType,
  Leg
} from "../../../interfaceAndType/typesSearh";
import { EVENT_LOADER } from "../system/contract";
import { hasDataInResultSearchAvia } from "../../../helpers/avia/HasDataInresultSearch";

// Define actions
export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SEARH_AIRPORT](
    { commit },
    payload: { searhAirport: string }
  ) {
    const { searhAirport } = payload;
    const apiService = new ApiService();
    const res = await apiService.getAirport(searhAirport);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      return [];
    } else {
      return res.object as Array<AirPortType>;
    }
  },
  async [ActionTypes.SEARH_AVIA](
    { commit },
    payload: { searhData: AviaSearchReqType }
  ) {
    const { searhData } = payload;

    commit(AviaMutationTypes.SAVE_SEARH_DATA, { data: searhData });
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.searchAvia(searhData);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return false;
    } else {
      commit(AviaMutationTypes.SET_SEARH_DATA, {
        data: res.object,
        result: true
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      if (!hasDataInResultSearchAvia(res.object)) {
        commit(SystemMutationTypes.ADD_ERROR, {
          errors: ["Поиск выдал пустой ответ!"],
          warnings: []
        });
        return false;
      }
      return true;
    }
  },

  async [ActionTypes.GET_FARES]() {
    const apiService = new ApiService();
    const res = await apiService.getFares();

    if (res.errors && (res.errors as Array<string>).length > 0) {
      console.log(res.errors);
      return false;
    } else return res;
  },

  async [ActionTypes.LOAD_PRICING]({ commit, state }, payload: { keytiket }) {
    const { keytiket } = payload;
    const apiService = new ApiService();

    const serachReq = state.serachReq;
    const findVariant = state.searhcData?.variants?.find(
      item => item.key === keytiket
    );

    if (serachReq && findVariant) {
      const legs: Array<Leg> = [];
      findVariant.itineraries?.forEach(it => {
        if (it.legs) {
          it.legs.forEach(leg => {
            legs.push(leg);
          });
        }
      });

      const AviaPricing: AviaPricingType = {
        legs,
        fare: serachReq?.fare,
        routeType: serachReq.isOnlyDirect ? 1 : 0,
        adults: serachReq?.adults,
        children: serachReq?.children,
        infants: serachReq?.infants,
        languageCode: null,
        clientId: null,
        contractId: null,
        passengers: []
      };

      commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });

      const res = await apiService.getPricing(AviaPricing);

      if (res.errors && (res.errors as Array<string>).length > 0) {
        commit(SystemMutationTypes.ADD_ERROR, {
          errors: res.errors,
          warnings: res.warnings
        });
        commit(SystemMutationTypes.LOADER_SET, {
          event: EVENT_LOADER.DEF_LOUDER,
          show: false
        });
        return false;
      } else {
        commit(AviaMutationTypes.SET_LOAD_PRICING, {
          data: res.object,
          result: true,
          keytiket: keytiket
        });
        commit(SystemMutationTypes.LOADER_SET, {
          event: EVENT_LOADER.DEF_LOUDER,
          show: false
        });
        return true;
      }
    }
    return false;
  }
};
