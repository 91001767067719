import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vue_slider = _resolveComponent("vue-slider")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_vue_slider, {
      modelValue: _ctx.time,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (_ctx.time = $event)),
      tooltip: 'always',
      "tooltip-placement": 'bottom',
      min: 0,
      max: 23,
      lazy: true,
      onDragEnd: _cache[2] || (_cache[2] = $event => (_ctx.filterByDeparture(_ctx.time[0], _ctx.time[1]))),
      "tooltip-formatter": _ctx.tooltipFormatter
    }, null, 8, ["modelValue", "tooltip-formatter"])
  ]))
}