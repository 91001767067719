import { State, LoaderType, EVENT_LOADER } from "./contract";
// mutations enums
export enum MutationTypes {
  ADD_ERROR = "ADD_ERROR",
  CONTROL_LOADER = "CONTROL_LOADER",
  LOADER_SET = "LOADER_SET",
  CLEAR_ERRORS = "CLEAR_ERRORS"
}

// Mutation contracts
export type Mutations<S = State> = {
  [MutationTypes.ADD_ERROR](
    state: S,
    payload: { errors: Array<string>; warnings: Array<string> }
  ): void;
  [MutationTypes.CONTROL_LOADER](
    state: S,
    payload: { loader: LoaderType }
  ): void;
  [MutationTypes.LOADER_SET](
    state: S,
    payload: { event: EVENT_LOADER; show?: boolean; message?: string }
  ): void;
  [MutationTypes.CLEAR_ERRORS](
    state: S,
  ): void;
};
