import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "search-result" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "results" }
const _hoisted_4 = { class: "results__header" }
const _hoisted_5 = { class: "sorting" }
const _hoisted_6 = { class: "results__title" }
const _hoisted_7 = { class: "sorting__item" }
const _hoisted_8 = { class: "sorting__item" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_sub_header = _resolveComponent("sub-header")
  const _component_rail_filters = _resolveComponent("rail-filters")
  const _component_rail_ticket = _resolveComponent("rail-ticket")
  const _component_Paginator = _resolveComponent("Paginator")
  const _component_rail_seats_modal = _resolveComponent("rail-seats-modal")
  const _component_order_create_modal = _resolveComponent("order-create-modal")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_sub_header, {
      canClose: true,
      canCreate: false,
      title: 'Результаты поиска'
    }),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_rail_filters, {
        filterByTrainTypes: _ctx.filterByTrainTypes,
        applyAllFilters: _ctx.applyAllFilters,
        filterByTrainBrands: _ctx.filterByTrainBrands,
        filterByDeparture: _ctx.filterByDeparture
      }, null, 8, ["filterByTrainTypes", "applyAllFilters", "filterByTrainBrands", "filterByDeparture"]),
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("ul", _hoisted_5, [
            _createVNode("h3", _hoisted_6, "Найдено: " + _toDisplayString(_ctx.trains.length), 1),
            _createVNode("li", _hoisted_7, [
              _createVNode("a", {
                href: "#",
                class: "sorting__link",
                onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => (_ctx.sortByDuration(...args)), ["prevent"]))
              }, "Время в пути ↑↓")
            ]),
            _createVNode("li", _hoisted_8, [
              _createVNode("button", {
                class: "order-create-btn",
                onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => (_ctx.openOrderModal(...args)), ["prevent"]))
              }, " СОЗДАТЬ ")
            ])
          ])
        ]),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.paginatedVariants, (train) => {
          return (_openBlock(), _createBlock(_component_rail_ticket, {
            key: train.numberTrain,
            item: train,
            openPricingModal: _ctx.openPricingModal,
            isSelected: _ctx.isSelected(train.numberTrain),
            class: { 'rail-ticket-selected': _ctx.isSelected(train.numberTrain) }
          }, null, 8, ["item", "openPricingModal", "isSelected", "class"]))
        }), 128 /* KEYED_FRAGMENT */)),
        _createVNode(_component_Paginator, {
          first: _ctx.paginatorFirst,
          rows: _ctx.perPage,
          totalRecords: _ctx.filteredVariants.length,
          onPage: _cache[3] || (_cache[3] = $event => (_ctx.onPage($event)))
        }, null, 8, ["first", "rows", "totalRecords"])
      ])
    ]),
    _createVNode(_component_rail_seats_modal, {
      toggleDisplay: _ctx.toggleDisplay,
      display: _ctx.display,
      item: _ctx.trainItem
    }, null, 8, ["toggleDisplay", "display", "item"]),
    (_ctx.passengersCount)
      ? (_openBlock(), _createBlock(_component_order_create_modal, {
          key: 0,
          showOrderModal: _ctx.showOrderModal,
          closeModal: _ctx.closeOrderModal,
          orderTypeId: _ctx.orderTypeId,
          isRail: "",
          passengersCount: _ctx.passengersCount
        }, null, 8, ["showOrderModal", "closeModal", "orderTypeId", "passengersCount"]))
      : _createCommentVNode("", true)
  ]))
}