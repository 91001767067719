
import { computed, defineComponent, PropType, toRefs } from "vue";
import getElement from "../../helpers/getElement";
import {
  OrderDTOType,
  TripWithOrders
} from "../../interfaceAndType/typesTrips";
import moment from "moment";
export default defineComponent({
  name: "InfoOrder",
  props: {
    order: {
      type: Object as PropType<OrderDTOType>,
      required: true
    },
    listOrders: { type: Object as PropType<TripWithOrders>, required: true }
  },
  setup(props) {
    const { order, listOrders } = toRefs(props);
    const agentName = computed(() => getElement(listOrders.value, "agentName"));
    const initiatorName = computed(() =>
      getElement(listOrders.value, "initiatorName")
    );
    const clientName = computed(() =>
      getElement(listOrders.value, "clientName")
    );
    const dataStart = computed(() =>
      moment(getElement(order.value, "dateStart")).format("DD.MM.YYYY HH:mm")
    );
    const dataEnd = computed(() =>
      moment(getElement(order.value, "dateEnd")).format("DD.MM.YYYY HH:mm")
    );

    return { agentName, initiatorName, clientName, dataStart, dataEnd };
  }
});
