import { ActionTree } from "vuex";
import { State as RootState } from "../index";
import { State, Actions, ActionTypes } from "./contract";
import { ApiService } from "../../../utils/ApiService";
import { MutationTypes as SystemMutationTypes } from "../system/MutationsType";
import { MutationTypes } from "./MutationsType";
import { BusinessTripType, TripFullDTO } from "@/interfaceAndType/typesTrips";
import { TripWithOrders } from "../../../interfaceAndType/typesTrips";
import { EVENT_LOADER } from "../system/contract";
import { DropDownEntity, PersonType } from "@/interfaceAndType/typesShared";
import { MutationTypes as OrderMutaions } from "../order/MutationsType";
// Define actions
export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GET_TRIPS](
    { commit },
    payload: {
      search?: string;
      page?: number;
      orderBy?: string;
      orderDir?: boolean;
    }
  ) {
    const { search, page, orderBy, orderDir } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.getTrips(search, page, orderBy, orderDir);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(MutationTypes.SET_TRIPS, {
        trips: res.object,
        pageCount: res.countPage
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object as Array<BusinessTripType>;
    }
  },
  async [ActionTypes.GET_TRIP_ORDERS](
    { commit, state },
    payload: {
      tripId: number;
    }
  ) {
    const { tripId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    if (!(state.tripTicketsCach && state.tripTicketsCach[tripId])) {
      const apiService = new ApiService();
      const res = await apiService.getTripOrders(tripId);

      if (res.errors && (res.errors as Array<string>).length > 0) {
        commit(SystemMutationTypes.ADD_ERROR, {
          errors: res.errors,
          warnings: res.warnings
        });
        commit(SystemMutationTypes.LOADER_SET, {
          event: EVENT_LOADER.DEF_LOUDER,
          show: false
        });
        return [];
      } else {
        commit(SystemMutationTypes.LOADER_SET, {
          event: EVENT_LOADER.DEF_LOUDER,
          show: false
        });
        commit(MutationTypes.SET_TRIP_TICKET_CACHE, {
          tripId,
          tickets: res.object
        });
        return res.object;
      }
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return state.tripTicketsCach[tripId];
    }
  },
  //--------------avia ------------------
  async [ActionTypes.BOOKING_AVIA]({ commit, state }, payload) {
    const { PersonList, OfferId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.bookingAvia(PersonList, OfferId);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });

      return res.object;
    }
  },
  async [ActionTypes.TICKETTING_AVIA]({ commit, state }, payload) {
    const { OfferId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.ticketingAvia(OfferId);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });

      return res.object;
    }
  },
  async [ActionTypes.CANCEL_AVIA]({ commit, state }, payload) {
    const { OfferId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.cancelAvia(OfferId);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object;
    }
  },

  //--------------hotel ------------------
  async [ActionTypes.BOOKING_HOTEL]({ commit, state }, payload) {
    const { sendData, OfferId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.hotelApi.booking(sendData, OfferId);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });

      return res.object;
    }
  },
  async [ActionTypes.TICKETTING_HOTEL]({ commit, state }, payload) {
    const { OfferId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.hotelApi.ticketing(OfferId);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });

      return res.object;
    }
  },
  async [ActionTypes.CANCEL_HOTEL]({ commit, state }, payload) {
    const { OfferId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.hotelApi.cancel(OfferId);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object;
    }
  },

  //--------------TRANSF_FREE ------------------
  async [ActionTypes.BOOKING_TRANS_FREE]({ commit, state }, payload) {
    const { PersonList, OfferId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.servicesApi.booking(PersonList, OfferId);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });

      return res.object;
    }
  },
  //---------- RAILWAY -----------
  async [ActionTypes.BOOKING_RAILWAY]({ commit, state }, payload) {
    const { PersonList, OfferId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.railway.booking(PersonList, OfferId);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });

      return res.object;
    }
  },
  async [ActionTypes.TICKETTING_RAILWAY]({ commit, state }, payload) {
    const { OfferId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.railway.ticketing(OfferId);

    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object;
    }
  },
  async [ActionTypes.CANCEL_RAILWAY]({ commit, state }, payload) {
    const { OfferId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.railway.cancel(OfferId);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object;
    }
  },
  async [ActionTypes.REJECT_RAILWAY]({ commit, state }, payload) {
    const { OfferId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.railway.refund(OfferId);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return [];
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object;
    }
  },
  //-------------
  async [ActionTypes.LOAD_TIKET]({ commit, state }, payload) {
    const { TicketId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.loadTiket(TicketId);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return false;
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return res.object;
    }
  },

  async [ActionTypes.SEND_TRIP]({ commit, state }, payload) {
    const { email, OrderId } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.SendTrip(OrderId, email);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return false;
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return true;
    }
  },
  //---------------------------------------------

  async [ActionTypes.LOAD_ACCEPT_TRIP]({ commit }, payload) {
    const { code } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.tripApi.getAcceptVariant(code);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return false;
    } else {
      console.log(res);

      const nowData = res.object as TripFullDTO;
      let types: DropDownEntity[] = [];
      if (nowData.orderList) {
        nowData.orderList.forEach(item => {
          const {
            orderTypeCode: entityCode,
            orderTypeId: entityId,
            orderTypeName: entityName
          } = item;
          types.push({ entityId, languageId: 1, entityCode, entityName });
        });
        types = types.filter((el, index) => {
          const findInd = types.findIndex(
            item => item.entityCode == el.entityCode
          );
          return findInd == index;
        });

        commit(OrderMutaions.SET_ORDER_TYPES, { types });
      }
      commit(MutationTypes.LOAD_ACCEPT_TRIP, {
        data: nowData
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return true;
    }
  },

  async [ActionTypes.ACCEPT_TRIP]({ commit }, payload) {
    const { CheckOffer } = payload;
    commit(SystemMutationTypes.LOADER_SET, { event: EVENT_LOADER.DEF_LOUDER });
    const apiService = new ApiService();
    const res = await apiService.tripApi.AcceptVariant(CheckOffer);
    if (res.errors && (res.errors as Array<string>).length > 0) {
      commit(SystemMutationTypes.ADD_ERROR, {
        errors: res.errors,
        warnings: res.warnings
      });
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return false;
    } else {
      commit(SystemMutationTypes.LOADER_SET, {
        event: EVENT_LOADER.DEF_LOUDER,
        show: false
      });
      return true;
    }
  }
};
